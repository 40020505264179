import "./index.scss";
import "rsuite/dist/rsuite.min.css";
import "@mantine/core/styles.css";

import React from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { createTheme, MantineProvider } from "@mantine/core";

import { Provider } from "react-redux";
import store from "./state/store";

import Layout from "./components/Layout/Layout";
import Enquiry from "./pages/Enquiry/Enquiry";
import Scholarship from "./pages/Scholarship/Scholarship";
import About from "./pages/About/About";
import Login from "./pages/Login/Login";
import Home from "./pages/Home/Home";
import FilesDownload from "./pages/FilesDownload/FilesDownload";
import Contact from "./pages/Contact/Contact";
import EnquiryList from "./pages/EnquiryList/EnquiryList";
import AdminPage from "./pages/AdminPage/AdminPage";
import Entrance from "./pages/Entrance/Entrance";
import StudentPage from "./pages/StudentPage/StudentPage";
import UserProfile from "./pages/UserProfile/UserProfile";
import Results from "./pages/Results/Results";
import IdCardPhotoUpload from "./pages/IdCardPhotoUpload/IdCardPhotoUpload";
import PaymentResult from "./pages/PaymentResult/PaymentResult";
import PrivacyPolicy from "./pages/PrivacyPolicy/PrivacyPolicy";
import TermsConditions from "./pages/TermsConditions/TermsConditions";
import PaymentFailure from "./pages/PaymentFailure/PaymentFailure";
import ExpenseForm from "./pages/ExpenseForm/ExpenseForm";

const root = ReactDOM.createRoot(document.getElementById("root"));

const theme = createTheme({
  fontSize: "36px",
  fontFamily: "Poppins, sans-serif",
  /** Put your mantine theme override here */
});

root.render(
  <Provider store={store}>
    {/* <React.StrictMode> */}
    <BrowserRouter>
      <MantineProvider theme={theme}>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route path="/" index element={<Home />} />
            <Route path="/enquiry" element={<Enquiry />} />
            <Route path="/about" element={<About />} />
            <Route path="/login" element={<Login />} />
            <Route path="/contact" index element={<Contact />} />
            <Route path="/results" index element={<Results />} />
            <Route path="/download" index element={<FilesDownload />} />
            <Route path="/admin/*" element={<AdminPage />} />
            <Route path="/student/*" element={<StudentPage />} />
            <Route path="/profile" element={<UserProfile />} />
            <Route path="/photos" element={<IdCardPhotoUpload />} />
            <Route path="/payment-callback" element={<PaymentResult />} />
            <Route path="/payment-success" element={<PaymentResult />} />
            <Route path="/payment-failure" element={<PaymentFailure />} />
            <Route path="/privacypolicy" element={<PrivacyPolicy />} />
            <Route path="/termsconditions" element={<TermsConditions />} />
          </Route>
          <Route path="/scholarship" element={<Scholarship />} />
          <Route path="/entrance" element={<Entrance />} />
          <Route path="/expenseForm" element={<ExpenseForm />} />
        </Routes>
      </MantineProvider>
    </BrowserRouter>
    {/* <RouterProvider router={router} /> */}
    {/* </React.StrictMode> */}
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
