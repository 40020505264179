import React, { useState, useEffect } from "react";
import axios from "axios";
import "./StaffDashboard.scss";
import { useNavigate } from "react-router-dom";
import user from "../../assets/images/user.jpg";
import logo from "../../assets/images/logo.png";

import AnnouncementsDisplay from "../../components/AnnouncementsDisplay/AnnouncementsDisplay";
import AssignedInventory from "../../components/AssignedInventory/AssignedInventory";

// const BE_URL = "http://localhost:8000";
const BE_URL = "https://server.classerp.in";

const StaffDashboard = () => {
  const [staffDetails, setStaffDetails] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [activeTab, setActiveTab] = useState("overview");
  const navigate = useNavigate();

  const refreshToken = async () => {
    try {
      const tokens = JSON.parse(localStorage.getItem("ERPTokens"));
      const response = await axios.post(`${BE_URL}/refreshtoken`, {
        token: tokens.refreshToken,
      });

      if (response.data) {
        localStorage.setItem("ERPTokens", JSON.stringify(response.data));
        return response.data.accessToken;
      }
    } catch (error) {
      console.error("Error refreshing token:", error);
      alert("Session expired. Please login again.");
      navigate("/login");
    }
  };

  useEffect(() => {
    const fetchStaffDetails = async () => {
      try {
        const tokens = JSON.parse(localStorage.getItem("ERPTokens"));
        const response = await axios.get(`${BE_URL}/api/general/staff`, {
          headers: {
            Authorization: `Bearer ${tokens.accessToken}`,
          },
        });

        if (response.data) {
          setStaffDetails(response.data);
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          const newTokens = await refreshToken();
          if (newTokens) {
            fetchStaffDetails();
          } else {
            alert("User logged out.");
            navigate("/login");
          }
        } else {
          console.error("Error fetching staff details:", error);
        }
      } finally {
        setIsLoading(false);
      }
    };

    fetchStaffDetails();
  }, [navigate]);

  if (isLoading) {
    return (
      <div className="loading-container">
        <div className="loading-spinner"></div>
        <p>Loading dashboard...</p>
      </div>
    );
  }

  // Format date to readable format
  const formatDate = (dateString) => {
    if (!dateString) return "N/A";
    const date = new Date(dateString);
    return date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  };

  // Get greeting based on time of day
  const getGreeting = () => {
    const hour = new Date().getHours();
    if (hour < 12) return "Good Morning";
    if (hour < 18) return "Good Afternoon";
    return "Good Evening";
  };

  return (
    <div className="stfdash">
      {/* Top header with welcome message */}
      <div className="stfdash-header">
        <div className="stfdash-header-welcome">
          <h2>
            {getGreeting()}, {staffDetails.name?.split(" ")[0] || "Teacher"}
          </h2>
          <p className="current-date">
            {new Date().toLocaleDateString("en-US", {
              weekday: "long",
              year: "numeric",
              month: "long",
              day: "numeric",
            })}
          </p>
        </div>
        <div className="stfdash-header-quick-actions">
          <button
            onClick={() => navigate("/profile")}
            className="action-button"
          >
            <span className="action-icon">👤</span>
            Profile
          </button>
          <button
            onClick={() => navigate("/admin/extras/resources")}
            className="action-button"
          >
            <span className="action-icon">📚</span>
            Resources
          </button>
        </div>
      </div>

      <div className="stfdash-tabs">
        <button
          className={`tab-button ${activeTab === "overview" ? "active" : ""}`}
          onClick={() => setActiveTab("overview")}
        >
          Overview
        </button>
        <button
          className={`tab-button ${activeTab === "inventory" ? "active" : ""}`}
          onClick={() => setActiveTab("inventory")}
        >
          Inventory
        </button>
        <button
          className={`tab-button ${
            activeTab === "announcements" ? "active" : ""
          }`}
          onClick={() => setActiveTab("announcements")}
        >
          Announcements
        </button>
      </div>

      <div className="stfdash-contents">
        {activeTab === "overview" && (
          <>
            <div className="stfdash-contents-info">
              <div className="stfdash-contents-info-header">
                <div className="stfdash-contents-info-brand">
                  <img src={logo} alt="Lancehawks Schools Logo" />
                  <div>Chaitanya's Academy</div>
                </div>

                <div className="stfdash-contents-info-name">
                  <h3>{staffDetails.name}</h3>
                  <span className="staff-designation">
                    {staffDetails.designation || "Teacher"}
                  </span>
                </div>
              </div>

              <div className="stfdash-contents-info-container">
                <div className="stfdash-contents-info-profile">
                  <img
                    src={staffDetails.image_url || user}
                    alt={staffDetails.name || "Staff"}
                    className="profile-photo"
                  />

                  <div className="profile-info">
                    <div className="info-item">
                      <div className="info-label">Employee ID</div>
                      <div className="info-value">
                        {staffDetails.employee_id || "N/A"}
                      </div>
                    </div>

                    <div className="info-item">
                      <div className="info-label">Department</div>
                      <div className="info-value">
                        {staffDetails.department || "N/A"}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="stfdash-contents-info-details">
                  <div className="info-row">
                    <div className="info-item">
                      <div className="info-label">Phone</div>
                      <div className="info-value">
                        {staffDetails.phone_number || "N/A"}
                      </div>
                    </div>

                    <div className="info-item">
                      <div className="info-label">Email</div>
                      <div className="info-value">
                        {staffDetails.email || "N/A"}
                      </div>
                    </div>
                  </div>

                  <div className="info-row">
                    <div className="info-item">
                      <div className="info-label">Manager</div>
                      <div className="info-value">
                        {staffDetails.manager || "N/A"}
                      </div>
                    </div>

                    <div className="info-item">
                      <div className="info-label">Joined</div>
                      <div className="info-value">
                        {formatDate(staffDetails.joining_date) || "N/A"}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="stfdash-contents-grid">
              <div className="stfdash-contents-ancmnts">
                <div className="stfdash-contents-ancmnts-title">
                  <h3>Recent Announcements</h3>
                  <button
                    className="view-all-button"
                    onClick={() => setActiveTab("announcements")}
                  >
                    View All
                  </button>
                </div>
                <div className="stfdash-contents-ancmnts-content">
                  <AnnouncementsDisplay limit={3} />
                </div>
              </div>

              <div className="stfdash-contents-exam">
                <div className="stfdash-contents-exam-title">
                  <h3>Assigned Inventory</h3>
                  <button
                    className="view-all-button"
                    onClick={() => setActiveTab("inventory")}
                  >
                    View All
                  </button>
                </div>
                <div className="stfdash-contents-exam-content">
                  <AssignedInventory type={"user"} limit={5} />
                </div>
              </div>

              {/* <div className="stfdash-contents-schedule">
                <div className="stfdash-contents-schedule-title">
                  <h3>Today's Schedule</h3>
                </div>
                <div className="stfdash-contents-schedule-content">
                  {staffDetails.todays_schedule &&
                  staffDetails.todays_schedule.length > 0 ? (
                    <div className="schedule-list">
                      {staffDetails.todays_schedule.map((schedule, index) => (
                        <div key={index} className="schedule-item">
                          <div className="schedule-time">{schedule.time}</div>
                          <div className="schedule-details">
                            <div className="schedule-class">
                              {schedule.class}
                            </div>
                            <div className="schedule-subject">
                              {schedule.subject}
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <div className="no-schedule">
                      <p>No classes scheduled for today</p>
                    </div>
                  )}
                </div>
              </div> */}
            </div>
          </>
        )}

        {activeTab === "inventory" && (
          <div className="stfdash-tab-content">
            <h2>Assigned Inventory</h2>
            <AssignedInventory type={"user"} />
          </div>
        )}

        {activeTab === "announcements" && (
          <div className="stfdash-tab-content">
            <h2>Announcements</h2>
            <AnnouncementsDisplay />
          </div>
        )}
      </div>
    </div>
  );
};

export default StaffDashboard;
