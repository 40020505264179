import "./Scholarship.scss";

import Logo from "../../assets/images/logo.png";
import loginbg from "../../assets/images/loginbg.png";

import data from "../../assets/data/districtData";

import React, { useEffect, useState } from "react";
import TextField from "../../components/TextField/TextField";
import DropDown from "../../components/DropDown/DropDown";
import DatePicker from "../../components/DatePicker/DatePicker";
import Button from "../../components/Button/Button";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Loader from "../../components/Loader/Loader";
import CustomDropDown from "../../components/CustomDropDown/CustomDropDown";
import VerificationComponent from "../../components/VerificationComponent/VerificationComponent";

/////////////////////////////////////////
//Submitted Message after submitting enquiry.
const SubmitMsg = ({ rollnumber }) => {
  const navigate = useNavigate();

  const submitNavigate = () => {
    navigate("/about");
  };
  return (
    <div className="submitted">
      <div className="submitted-title">Submitted Successfully.</div>
      <div className="submitted-text">
        Thank you for submitting the Scholarship form. Your Roll No. is{" "}
        <span className="submitted-text-rollno">{rollnumber}</span>. We will
        soon get in touch with you with all details. In the meantime, to know
        more about us, visit the link below.
      </div>
      <div className="submitted-cta">
        <Button text={`About Us`} onClick={submitNavigate} />
      </div>
    </div>
  );
};

const Scholarship = () => {
  // Hardcoded districts for Bihar and Jharkhand
  const biharJharkhandDistricts = [
    // Bihar Districts
    "Araria",
    "Arwal",
    "Aurangabad",
    "Banka",
    "Begusarai",
    "Bhagalpur",
    "Bhojpur",
    "Buxar",
    "Darbhanga",
    "East Champaran",
    "Gaya",
    "Gopalganj",
    "Jamui",
    "Jehanabad",
    "Kaimur",
    "Katihar",
    "Khagaria",
    "Kishanganj",
    "Lakhisarai",
    "Madhepura",
    "Madhubani",
    "Munger",
    "Muzaffarpur",
    "Nalanda",
    "Nawada",
    "Patna",
    "Purnia",
    "Rohtas",
    "Saharsa",
    "Samastipur",
    "Saran",
    "Sheikhpura",
    "Sheohar",
    "Sitamarhi",
    "Siwan",
    "Supaul",
    "Vaishali",
    "West Champaran",

    // Jharkhand Districts
    "Bokaro",
    "Chatra",
    "Deoghar",
    "Dhanbad",
    "Dumka",
    "East Singhbhum",
    "Garhwa",
    "Giridih",
    "Godda",
    "Gumla",
    "Hazaribagh",
    "Jamtara",
    "Khunti",
    "Koderma",
    "Latehar",
    "Lohardaga",
    "Pakur",
    "Palamu",
    "Ramgarh",
    "Ranchi",
    "Sahebganj",
    "Seraikela-Kharsawan",
    "Simdega",
    "West Singhbhum",
  ];

  const [districts, setdistricts] = useState([]);
  const [talukas, settalukas] = useState([]);

  useEffect(() => {
    // Initialize with Baramati districts by default
    let cities = [];
    data.map((city) => cities.push(city.name));
    setdistricts(cities);
  }, []);

  //Phase 2
  const [isEmailVerified, setIsEmailVerified] = useState(true);
  const [isWhatsappVerified, setIsWhatsappVerified] = useState(true);
  //Phase 2 ends
  const [isLoading, setIsLoading] = useState(false);

  const [submitted, setSubmitted] = useState(false);
  const [rollnumber, setrollnumber] = useState(null);

  const [branch, setBranch] = useState(null);
  const [session, setSession] = useState("2025-2026");
  const [firstname, setFirstname] = useState(null);
  const [middlename, setmiddlename] = useState(null);
  const [lastname, setlastname] = useState(null);
  const [dob, setdob] = useState(null);
  const [fathername, setfathername] = useState(null);
  const [mothername, setmothername] = useState(null);
  const [email, setemail] = useState(null);
  const [whatsapp, setwhatsapp] = useState(null);
  const [alternateNo, setAlternateNo] = useState(null);
  const [aadhar, setaadhar] = useState(null);
  const [gender, setgender] = useState(null);
  const [stream, setstream] = useState(null);
  const [bloodgroup, setbloodgroup] = useState(null);
  const [parentOccupation, setparentOccupation] = useState(null);
  const [reference, setReference] = useState(null);

  const [admclass, setadmclass] = useState(null);
  const [hostel, sethostel] = useState(false);

  const [school, setschool] = useState(null);
  const [board, setboard] = useState(null);
  const [medium, setmedium] = useState(null);
  const [prevmaths, setprevmaths] = useState(null);
  const [prevscience, setprevscience] = useState(null);

  const [error, setError] = useState(null);
  const [errors, setErrors] = useState({});

  //Other Exams
  const [otherExams, setOtherExams] = useState(null);
  const [exam1, setexam1] = useState(null);
  const [exam2, setexam2] = useState(null);
  const [exam3, setexam3] = useState(null);
  //   const [exam4, setexam4] = useState("Select Exam");
  const [exam1Marks, setexam1Marks] = useState(null);
  const [exam2Marks, setexam2Marks] = useState(null);
  const [exam3Marks, setexam3Marks] = useState(null);
  //   const [exam4Marks, setexam4Marks] = useState(null);

  const [address, setaddress] = useState(null);
  const [district, setdistrict] = useState(null);
  const [taluka, settaluka] = useState(null);
  const [pin, setpin] = useState(null);
  const [photo, setPhoto] = useState(null);
  const [caste, setcaste] = useState(null);

  const casteOptions = ["General/Open", "OBC", "SC", "ST", "EWS", "Others"];
  const refOptions = ["Facebook", "Newspaper", "Friend", "Website", "Others"];

  const bloodgroups = ["A+", "A-", "B+", "B-", "AB+", "AB-", "O+", "O-"];
  const genders = ["Male", "Female"];
  const branches = ["Baramati", "Pune", "Nagar", "Ranchi"];
  const streams = ["Engineering", "Medical"];

  const boards = ["ICSE", "CBSE", "SSC"];
  const mediums = ["English", "Marathi", "Semi-English"];
  const examOptions = [
    "MHCET",
    "NTSE",
    "8th Std Scholarship exam",
    "5th Std Scholarship exam",
    "Olympiad",
    "Other",
  ];

  const bool = ["Yes", "No"];

  const classOptions = [
    "Class 11th",
    "Class 12th",
    "Neet Repeater",
    "JEE Repeater",
  ];
  const sessions = ["2025-2026", "2026-2027"];

  // Handle branch change
  const handleBranchChange = (selectedBranch) => {
    setBranch(selectedBranch);

    // Reset district and taluka when branch changes
    setdistrict(null);
    settaluka(null);

    if (selectedBranch === "Ranchi") {
      // Use Bihar and Jharkhand districts for Ranchi branch
      setdistricts(biharJharkhandDistricts);
    } else {
      // Use existing district data from the data import
      let cities = [];
      data.map((city) => cities.push(city.name));
      setdistricts(cities);
    }
  };

  // Modified validateForm function to handle conditional taluka validation
  const validateForm = () => {
    const newErrors = {};

    // Define required fields based on branch selection
    let requiredFields = [
      "branch",
      "session",
      "firstname",
      "middlename",
      "lastname",
      "fathername",
      "dob",
      "mothername",
      "email",
      "whatsapp",
      "alternateNo",
      "aadhar",
      "gender",
      "stream",
      "caste",
      "bloodgroup",
      "parentOccupation",
      "admclass",
      "hostel",
      "school",
      "board",
      "medium",
      "prevmaths",
      "prevscience",
      "address",
      "district",
      "pin",
      "reference",
    ];

    // Only include taluka validation for Baramati branch
    if (branch !== "Ranchi") {
      requiredFields.push("taluka");
    }

    for (let field of requiredFields) {
      if (!eval(field)) {
        newErrors[field] = "This field is required";
      }
    }

    // New validations
    if (whatsapp && whatsapp.length !== 10) {
      newErrors.whatsapp = "WhatsApp number must be 10 digits";
    }

    if (alternateNo && alternateNo.length !== 10) {
      newErrors.alternateNo = "Alternate number must be 10 digits";
    }

    if (aadhar && aadhar.length !== 12) {
      newErrors.aadhar = "Aadhar number must be 12 digits";
    }

    if (pin && pin.length !== 6) {
      newErrors.pin = "Pin must be 6 digits";
    }

    setErrors(newErrors);
    if (Object.keys(newErrors).length === 0) {
      setError(null);
      return true;
    } else {
      setError(
        "Please recheck the fields marked in red. लाल रंग असलेल्या जागी माहिती भरा"
      );
      setIsLoading(false);
      return false;
    }
  };

  // Modified selectDistrict to handle branch-specific behavior
  const selectDistrict = (val) => {
    setdistrict(val);

    // Only set talukas for Baramati branch
    if (branch !== "Ranchi") {
      const obj = data.find((city) => city.name === val);
      if (obj && obj.tahasil) {
        settalukas(obj.tahasil);
      } else {
        settalukas([]);
      }
    }
  };

  // Modified handleSubmit to handle branch-specific form data
  const handleSubmit = async () => {
    setIsLoading(true);
    if (!photo) {
      alert("Please upload a photo!");
      setIsLoading(false);
      return;
    }
    if (validateForm()) {
      setErrors({});

      const formdata = new FormData();
      formdata.append("reference", reference);
      formdata.append("branch", branch);
      formdata.append("session", session);
      formdata.append("firstname", firstname);
      formdata.append("middlename", middlename);
      formdata.append("lastname", lastname);
      formdata.append("dob", dob);
      formdata.append("fathername", fathername);
      formdata.append("mothername", mothername);
      formdata.append("email", email);
      formdata.append("whatsapp", whatsapp);
      formdata.append("alternateNo", alternateNo);
      formdata.append("aadhar", aadhar);
      formdata.append("gender", gender);
      formdata.append("stream", stream);
      formdata.append("caste", caste);
      formdata.append("bloodgroup", bloodgroup);
      formdata.append("parentOccupation", parentOccupation);
      formdata.append("admclass", admclass);
      formdata.append("hostel", hostel);
      formdata.append("school", school);
      formdata.append("board", board);
      formdata.append("medium", medium);
      formdata.append("prevmaths", prevmaths);
      formdata.append("prevscience", prevscience);
      formdata.append("otherExams", otherExams);
      formdata.append("exam1", exam1);
      formdata.append("exam2", exam2);
      formdata.append("exam3", exam3);
      formdata.append("exam1Marks", exam1Marks);
      formdata.append("exam2Marks", exam2Marks);
      formdata.append("exam3Marks", exam3Marks);
      formdata.append("address", address);
      formdata.append("district", district);
      formdata.append("pin", pin);
      formdata.append("photo", photo);

      // Only append taluka if it's relevant (Baramati branch)
      if (branch !== "Ranchi") {
        formdata.append("taluka", taluka);
      } else {
        formdata.append("taluka", district);
      }

      try {
        // Send data to the server
        const response = await axios.post(
          "https://server.classerp.in/submit-scholarship",
          formdata
        );
        // const response = await axios.post(
        //   "http://localhost:8000/submit-scholarship",
        //   formdata
        // );
        if (response.data.rollNumber) {
          console.log("Success:", response.data);
          setSubmitted(true);
          setrollnumber(response.data.rollNumber);
        } else {
          alert("Failed to submit. Please try again.");
        }

        // Set submitted to true on success
      } catch (error) {
        if (error.response && error.response.status === 409) {
          alert("Aadhar already exists! Please check the aadhar number");
        } else {
          alert("Failed to submit. Please try again.");
        }
        console.error("Error:", error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <>
      {submitted || !isEmailVerified || !isWhatsappVerified ? (
        <>
          {submitted && <SubmitMsg rollnumber={rollnumber} />}
          {!isEmailVerified && (
            <div
              className="formver"
              style={{ backgroundImage: `url(${loginbg})` }}
            >
              <div className="formver-exam">
                The exam date is 23rd March, 2025.
              </div>
              <VerificationComponent
                type={`email`}
                setVerified={setIsEmailVerified}
                setValue={setemail}
              />
            </div>
          )}
          {isEmailVerified && !isWhatsappVerified && (
            <div
              className="formver"
              style={{ backgroundImage: `url(${loginbg})` }}
            >
              <VerificationComponent
                type={`whatsapp`}
                setVerified={setIsWhatsappVerified}
                setValue={setwhatsapp}
              />
            </div>
          )}
        </>
      ) : (
        <div className="scholarship">
          {isLoading && <Loader />}

          <div className="scholarship-header">
            <div className="scholarship-header-logo">
              <img src={Logo} alt="Chaitainya's Academy Logo" />
            </div>
            <div className="scholarship-header-title">Admission Test Form</div>
            <div className="scholarship-header-ams">AMS</div>
          </div>
          <div className="scholarship-contents">
            {/* <div className="scholarship-contents-info">
              Change in Exam Date - 17th Dec, 2023.
            </div> */}
            <div className="scholarship-contents-section">
              <div className="scholarship-contents-section-title">
                Basic Information
              </div>
              <div className="scholarship-contents-section-fields">
                <div className="scholarship-contents-section-fields-field">
                  <DropDown
                    invalid={errors.branch}
                    options={branches}
                    label={`Branch`}
                    value={branch}
                    onChange={handleBranchChange}
                  />
                </div>
                <div className="scholarship-contents-section-fields-field">
                  <DropDown
                    invalid={errors.stream}
                    options={streams}
                    label={`Stream`}
                    value={stream}
                    onChange={setstream}
                  />
                </div>
                <div className="scholarship-contents-section-fields-field">
                  <DropDown
                    invalid={errors.session}
                    options={sessions}
                    label={`Session`}
                    value={session}
                    onChange={setSession}
                  />
                </div>
                <div className="scholarship-contents-section-fields-field">
                  <DropDown
                    invalid={errors.reference}
                    options={refOptions}
                    label={`Reference`}
                    value={reference}
                    onChange={setReference}
                  />
                </div>
                <div
                  className={`scholarship-contents-section-fields-field ${
                    errors.firstname ? "invalid" : ""
                  }`}
                >
                  <TextField
                    placeholder={`First Name`}
                    value={firstname}
                    onChange={setFirstname}
                  />
                </div>
                <div
                  className={`scholarship-contents-section-fields-field ${
                    errors.middlename ? "invalid" : ""
                  }`}
                >
                  <TextField
                    placeholder={`Middle Name`}
                    value={middlename}
                    onChange={setmiddlename}
                  />
                </div>
                <div
                  className={`scholarship-contents-section-fields-field ${
                    errors.lastname ? "invalid" : ""
                  }`}
                >
                  <TextField
                    className={errors.lastname ? "input-error" : ""}
                    placeholder={`Last Name`}
                    value={lastname}
                    onChange={setlastname}
                  />
                </div>
                <div className="scholarship-contents-section-fields-field">
                  <DatePicker
                    invalid={errors.dob}
                    label={`Date of Birth`}
                    value={dob}
                    onChange={setdob}
                  />
                </div>
                <div
                  className={`scholarship-contents-section-fields-field ${
                    errors.fathername ? "invalid" : ""
                  }`}
                >
                  <TextField
                    placeholder={`Father's Name`}
                    value={fathername}
                    onChange={setfathername}
                  />
                </div>
                <div
                  className={`scholarship-contents-section-fields-field ${
                    errors.mothername ? "invalid" : ""
                  }`}
                >
                  <TextField
                    placeholder={`Mother's Name`}
                    value={mothername}
                    onChange={setmothername}
                  />
                </div>
                <div
                  className={`scholarship-contents-section-fields-field ${
                    errors.email ? "invalid" : ""
                  }`}
                >
                  <TextField
                    placeholder={`Email`}
                    value={email}
                    // disabled={true}
                    onChange={(email) => setemail(email)}
                  />
                </div>
                <div
                  className={`scholarship-contents-section-fields-field ${
                    errors.whatsapp ? "invalid" : ""
                  }`}
                >
                  <TextField
                    placeholder={`WhatsApp No.`}
                    value={whatsapp}
                    // disabled={true}
                    onChange={setwhatsapp}
                  />
                </div>
                <div
                  className={`scholarship-contents-section-fields-field ${
                    errors.alternateNo ? "invalid" : ""
                  }`}
                >
                  <TextField
                    placeholder={`Alternate No.`}
                    value={alternateNo}
                    onChange={setAlternateNo}
                  />
                </div>
                <div
                  className={`scholarship-contents-section-fields-field ${
                    errors.aadhar ? "invalid" : ""
                  }`}
                >
                  <TextField
                    placeholder={`Aadhar No.`}
                    value={aadhar}
                    onChange={setaadhar}
                  />
                </div>
                <div className="scholarship-contents-section-fields-field">
                  <DropDown
                    invalid={errors.gender}
                    options={genders}
                    label={`Gender`}
                    value={gender}
                    onChange={setgender}
                  />
                </div>
                <div className="scholarship-contents-section-fields-field">
                  <DropDown
                    invalid={errors.caste}
                    options={casteOptions}
                    label={`Caste`}
                    value={caste}
                    onChange={setcaste}
                  />
                </div>
                <div className="scholarship-contents-section-fields-field">
                  <DropDown
                    invalid={errors.bloodgroup}
                    options={bloodgroups}
                    label={`Blood Group`}
                    value={bloodgroup}
                    onChange={setbloodgroup}
                  />
                </div>
                <div
                  className={`scholarship-contents-section-fields-field ${
                    errors.parentOccupation ? "invalid" : ""
                  }`}
                >
                  <TextField
                    placeholder={`Parent Occupation`}
                    value={parentOccupation}
                    onChange={setparentOccupation}
                  />
                </div>
                <div className="scholarship-contents-section-fields-field"></div>
              </div>
            </div>

            <hr className="scholarship-contents-seperator"></hr>

            {/* Admission Related Information */}
            <div className="scholarship-contents-section">
              <div className="scholarship-contents-section-title">
                Admission Related Information
              </div>
              <div className="scholarship-contents-section-fields">
                <div className="scholarship-contents-section-fields-field">
                  <DropDown
                    invalid={errors.admclass}
                    options={classOptions}
                    label={`Want Admission for Class`}
                    value={admclass}
                    onChange={setadmclass}
                  />
                </div>
                <div className="scholarship-contents-section-fields-field">
                  <DropDown
                    invalid={errors.hostel}
                    options={bool}
                    label={`Opting for Hostel`}
                    value={hostel}
                    onChange={sethostel}
                  />
                </div>
                <div className="scholarship-contents-section-fields-field"></div>
              </div>
            </div>

            <hr className="scholarship-contents-seperator"></hr>

            {/* Previous Academic Information */}
            <div className="scholarship-contents-section">
              <div className="scholarship-contents-section-title">
                Previous Academic Information
              </div>
              <div className="scholarship-contents-section-fields">
                <div
                  className={`scholarship-contents-section-fields-field ${
                    errors.school ? "invalid" : ""
                  }`}
                >
                  <TextField
                    placeholder={`Name of School`}
                    value={school}
                    onChange={setschool}
                  />
                </div>
                <div className="scholarship-contents-section-fields-field">
                  <DropDown
                    invalid={errors.board}
                    options={boards}
                    label={`Board`}
                    value={board}
                    onChange={setboard}
                  />
                </div>
                <div className="scholarship-contents-section-fields-field">
                  <DropDown
                    invalid={errors.medium}
                    options={mediums}
                    label={`Medium`}
                    value={medium}
                    onChange={setmedium}
                  />
                </div>
                <div
                  className={`scholarship-contents-section-fields-field ${
                    errors.prevmaths ? "invalid" : ""
                  }`}
                >
                  <TextField
                    placeholder={`Previous Year Maths Score`}
                    value={prevmaths}
                    onChange={setprevmaths}
                  />
                </div>
                <div
                  className={`scholarship-contents-section-fields-field ${
                    errors.prevscience ? "invalid" : ""
                  }`}
                >
                  <TextField
                    placeholder={`Previous Year Science Score`}
                    value={prevscience}
                    onChange={setprevscience}
                  />
                </div>
                <div className="scholarship-contents-section-fields-field">
                  <DropDown
                    options={bool}
                    value={otherExams}
                    label={`Other Competitive Exams`}
                    onChange={setOtherExams}
                  />
                </div>
                {otherExams === "Yes" ? (
                  <>
                    <div className="scholarship-contents-section-fields-field-other">
                      <DropDown
                        options={examOptions}
                        label={`Select Exam`}
                        value={exam1}
                        onChange={setexam1}
                      />
                    </div>
                    <div className="scholarship-contents-section-fields-field-other">
                      <TextField
                        placeholder={`Exam Marks`}
                        value={exam1Marks}
                        onChange={setexam1Marks}
                      />
                    </div>

                    <div className="scholarship-contents-section-fields-field-other">
                      <DropDown
                        options={examOptions}
                        label={`Select Exam`}
                        value={exam2}
                        onChange={setexam2}
                      />
                    </div>
                    <div className="scholarship-contents-section-fields-field-other">
                      <TextField
                        placeholder={`Exam Marks`}
                        value={exam2Marks}
                        onChange={setexam2Marks}
                      />
                    </div>

                    <div className="scholarship-contents-section-fields-field-other">
                      <DropDown
                        options={examOptions}
                        label={`Select Exam`}
                        value={exam3}
                        onChange={setexam3}
                      />
                    </div>
                    <div className="scholarship-contents-section-fields-field-other">
                      <TextField
                        placeholder={`Exam Marks`}
                        value={exam3Marks}
                        onChange={setexam3Marks}
                      />
                    </div>
                  </>
                ) : (
                  ""
                )}
              </div>
            </div>

            <hr className="scholarship-contents-seperator"></hr>

            {/* Address */}
            <div className="scholarship-contents-section">
              <div className="scholarship-contents-section-title">Address</div>
              <div className="scholarship-contents-section-fields">
                <div
                  className={`scholarship-contents-section-fields-field ${
                    errors.address ? "invalid" : ""
                  }`}
                >
                  <TextField
                    placeholder={`Address`}
                    value={address}
                    onChange={setaddress}
                  />
                </div>

                <div className="scholarship-contents-section-fields-field">
                  <CustomDropDown
                    data={districts.map((city) => ({
                      id: city,
                      name: city,
                    }))}
                    searchable={true}
                    label="Select District"
                    placeholder="Select District"
                    onChange={selectDistrict}
                    value={district}
                  />
                </div>

                {/* Conditionally render taluka dropdown only for Baramati branch */}
                {branch !== "Ranchi" ? (
                  <div className="scholarship-contents-section-fields-field">
                    <DropDown
                      invalid={errors.taluka}
                      options={talukas}
                      label={`Taluka`}
                      value={taluka}
                      onChange={settaluka}
                    />
                  </div>
                ) : null}

                <div
                  className={`scholarship-contents-section-fields-field ${
                    errors.pin ? "invalid" : ""
                  }`}
                >
                  <TextField
                    placeholder={`Pin`}
                    value={pin}
                    onChange={setpin}
                  />
                </div>
                <div className="scholarship-contents-section-fields-field"></div>
              </div>
            </div>

            <hr className="scholarship-contents-seperator"></hr>

            <div className="scholarship-contents-section">
              <div className="scholarship-contents-section-title">
                Upload Photo
              </div>
              <div className="scholarship-contents-section-fields">
                <div className="scholarship-contents-section-fields-field">
                  <p className="scholarship-text">
                    Please take current live selfie only (don't upload old
                    passport size photos) <br />
                    कृपया फक्त औपचारिक वर्तमान थेट सेल्फी घ्या (जुने पासपोर्ट
                    आकाराचे फोटो अपलोड करू नका)
                  </p>
                  <input
                    label="Select File"
                    placeholder="Select File"
                    type="file"
                    onChange={(e) => setPhoto(e.target.files[0])}
                  />
                </div>
                <div className="scholarship-contents-section-fields-field">
                  {/* <input
                    label="Select File"
                    placeholder="Select File"
                    type="file"
                    onChange={(e) => setFile(e.target.files[0])}
                  /> */}
                </div>

                <div className="scholarship-contents-section-fields-field"></div>
              </div>
            </div>

            {error && <div className="scholarship-error">{error}</div>}
            <div className="scholarship-button">
              <Button text={`Submit`} onClick={handleSubmit} />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Scholarship;

// import "./Scholarship.scss";

// import Logo from "../../assets/images/logo.png";
// import loginbg from "../../assets/images/loginbg.png";

// import data from "../../assets/data/districtData";

// import React, { useEffect, useState } from "react";
// import TextField from "../../components/TextField/TextField";
// import DropDown from "../../components/DropDown/DropDown";
// import DatePicker from "../../components/DatePicker/DatePicker";
// import Button from "../../components/Button/Button";
// import { useNavigate } from "react-router-dom";
// import axios from "axios";
// import Loader from "../../components/Loader/Loader";
// import CustomDropDown from "../../components/CustomDropDown/CustomDropDown";
// import VerificationComponent from "../../components/VerificationComponent/VerificationComponent";

// /////////////////////////////////////////
// //Submitted Message after submitting enquiry.
// const SubmitMsg = ({ rollnumber }) => {
//   const navigate = useNavigate();

//   const submitNavigate = () => {
//     navigate("/about");
//   };
//   return (
//     <div className="submitted">
//       <div className="submitted-title">Submitted Successfully.</div>
//       <div className="submitted-text">
//         Thank you for submitting the Scholarship form. Your Roll No. is{" "}
//         <span className="submitted-text-rollno">{rollnumber}</span>. We will
//         soon get in touch with you with all details. In the meantime, to know
//         more about us, visit the link below.
//       </div>
//       <div className="submitted-cta">
//         <Button text={`About Us`} onClick={submitNavigate} />
//       </div>
//     </div>
//   );
// };

// const Scholarship = () => {
//   const [districts, setdistricts] = useState([]);
//   const [talukas, settalukas] = useState([]);

//   useEffect(() => {
//     let cities = [];
//     data.map((city) => cities.push(city.name));
//     setdistricts(cities);
//   }, []);

//   //Phase 2
//   const [isEmailVerified, setIsEmailVerified] = useState(false);
//   const [isWhatsappVerified, setIsWhatsappVerified] = useState(true);
//   //Phase 2 ends
//   const [isLoading, setIsLoading] = useState(false);

//   const [submitted, setSubmitted] = useState(false);
//   const [rollnumber, setrollnumber] = useState(null);

//   const [branch, setBranch] = useState(null);
//   const [session, setSession] = useState("2025-2026");
//   const [firstname, setFirstname] = useState(null);
//   const [middlename, setmiddlename] = useState(null);
//   const [lastname, setlastname] = useState(null);
//   const [dob, setdob] = useState(null);
//   const [fathername, setfathername] = useState(null);
//   const [mothername, setmothername] = useState(null);
//   const [email, setemail] = useState(null);
//   const [whatsapp, setwhatsapp] = useState(null);
//   const [alternateNo, setAlternateNo] = useState(null);
//   const [aadhar, setaadhar] = useState(null);
//   const [gender, setgender] = useState(null);
//   const [stream, setstream] = useState(null);
//   const [bloodgroup, setbloodgroup] = useState(null);
//   const [parentOccupation, setparentOccupation] = useState(null);
//   const [reference, setReference] = useState(null);

//   const [admclass, setadmclass] = useState(null);
//   const [hostel, sethostel] = useState(false);

//   const [school, setschool] = useState(null);
//   const [board, setboard] = useState(null);
//   const [medium, setmedium] = useState(null);
//   const [prevmaths, setprevmaths] = useState(null);
//   const [prevscience, setprevscience] = useState(null);

//   const [error, setError] = useState(null);
//   const [errors, setErrors] = useState({});

//   //Other Exams
//   const [otherExams, setOtherExams] = useState(null);
//   const [exam1, setexam1] = useState(null);
//   const [exam2, setexam2] = useState(null);
//   const [exam3, setexam3] = useState(null);
//   //   const [exam4, setexam4] = useState("Select Exam");
//   const [exam1Marks, setexam1Marks] = useState(null);
//   const [exam2Marks, setexam2Marks] = useState(null);
//   const [exam3Marks, setexam3Marks] = useState(null);
//   //   const [exam4Marks, setexam4Marks] = useState(null);

//   const [address, setaddress] = useState(null);
//   const [district, setdistrict] = useState(null);
//   const [taluka, settaluka] = useState(null);
//   const [pin, setpin] = useState(null);
//   const [photo, setPhoto] = useState(null);
//   const [caste, setcaste] = useState(null);

//   const casteOptions = ["General/Open", "OBC", "SC", "ST", "EWS", "Others"];
//   const refOptions = ["Facebook", "Newspaper", "Friend", "Website", "Others"];

//   const bloodgroups = ["A+", "A-", "B+", "B-", "AB+", "AB-", "O+", "O-"];
//   const genders = ["Male", "Female"];
//   const branches = ["Baramati", "Pune", "Nagar", "Ranchi"];
//   const streams = ["Engineering", "Medical"];

//   const boards = ["ICSE", "CBSE", "SSC"];
//   const mediums = ["English", "Marathi", "Semi-English"];
//   const examOptions = [
//     "MHCET",
//     "NTSE",
//     "8th Std Scholarship exam",
//     "5th Std Scholarship exam",
//     "Olympiad",
//     "Other",
//   ];

//   const bool = ["Yes", "No"];

//   const classOptions = [
//     "Class 11th",
//     "Class 12th",
//     "Neet Repeater",
//     "JEE Repeater",
//   ];
//   const sessions = ["2025-2026", "2026-2027"];

//   const validateForm = () => {
//     const newErrors = {};

//     const requiredFields = [
//       "branch",
//       "session",
//       "firstname",
//       "middlename",
//       "lastname",
//       "fathername",
//       "dob",
//       "mothername",
//       "email",
//       "whatsapp",
//       "alternateNo",
//       "aadhar",
//       "gender",
//       "stream",
//       "caste",
//       "bloodgroup",
//       "parentOccupation",
//       "admclass",
//       "hostel",
//       "school",
//       "board",
//       "medium",
//       "prevmaths",
//       "prevscience",
//       "address",
//       "district",
//       "taluka",
//       "pin",
//       "reference",
//     ];

//     for (let field of requiredFields) {
//       if (!eval(field)) {
//         newErrors[field] = "This field is required";
//       }
//     }

//     // New validations
//     if (whatsapp && whatsapp.length !== 10) {
//       newErrors.whatsapp = "WhatsApp number must be 10 digits";
//     }

//     if (alternateNo && alternateNo.length !== 10) {
//       newErrors.alternateNo = "Alternate number must be 10 digits";
//     }

//     if (aadhar && aadhar.length !== 12) {
//       newErrors.aadhar = "Aadhar number must be 12 digits";
//     }

//     if (pin && pin.length !== 6) {
//       newErrors.pin = "Pin must be 6 digits";
//     }

//     setErrors(newErrors);
//     if (Object.keys(newErrors).length === 0) {
//       setError(null);
//       return true;
//     } else {
//       setError(
//         "Please recheck the fields marked in red. लाल रंग असलेल्या जागी माहिती भरा"
//       );
//       setIsLoading(false);
//       return false;
//     }
//   };

//   const handleSubmit = async () => {
//     setIsLoading(true);
//     if (!photo) {
//       alert("Please upload a photo!");
//       setIsLoading(false);
//       return;
//     }
//     if (validateForm()) {
//       setErrors({});

//       const formdata = new FormData();
//       formdata.append("reference", reference);
//       formdata.append("branch", branch);
//       formdata.append("session", session);
//       formdata.append("firstname", firstname);
//       formdata.append("middlename", middlename);
//       formdata.append("lastname", lastname);
//       formdata.append("dob", dob);
//       formdata.append("fathername", fathername);
//       formdata.append("mothername", mothername);
//       formdata.append("email", email);
//       formdata.append("whatsapp", whatsapp);
//       formdata.append("alternateNo", alternateNo);
//       formdata.append("aadhar", aadhar);
//       formdata.append("gender", gender);
//       formdata.append("stream", stream);
//       formdata.append("caste", caste);
//       formdata.append("bloodgroup", bloodgroup);
//       formdata.append("parentOccupation", parentOccupation);
//       formdata.append("admclass", admclass);
//       formdata.append("hostel", hostel);
//       formdata.append("school", school);
//       formdata.append("board", board);
//       formdata.append("medium", medium);
//       formdata.append("prevmaths", prevmaths);
//       formdata.append("prevscience", prevscience);
//       formdata.append("otherExams", otherExams);
//       formdata.append("exam1", exam1);
//       formdata.append("exam2", exam2);
//       formdata.append("exam3", exam3);
//       formdata.append("exam1Marks", exam1Marks);
//       formdata.append("exam2Marks", exam2Marks);
//       formdata.append("exam3Marks", exam3Marks);
//       formdata.append("address", address);
//       formdata.append("district", district);
//       formdata.append("taluka", taluka);
//       formdata.append("pin", pin);
//       formdata.append("photo", photo);

//       try {
//         // Send data to the server
//         const response = await axios.post(
//           "https://server.classerp.in/submit-scholarship",
//           formdata
//         );
//         // const response = await axios.post(
//         //   "http://localhost:8000/submit-scholarship",
//         //   formdata
//         // );
//         if (response.data.rollNumber) {
//           console.log("Success:", response.data);
//           setSubmitted(true);
//           setrollnumber(response.data.rollNumber);
//         } else {
//           alert("Failed to submit. Please try again.");
//         }

//         // Set submitted to true on success
//       } catch (error) {
//         if (error.response.status === 409) {
//           alert("Aadhar alredy exists! Please check the aadhar number");
//         } else {
//           alert("Failed to submit. Please try again.");
//         }
//         console.error("Error:", error);
//       } finally {
//         setIsLoading(false);
//       }
//     }
//   };

//   const selectDistrict = (val) => {
//     setdistrict(val);
//     const obj = data.find((city) => city.name === val);
//     settalukas(obj.tahasil);
//   };

//   return (
//     <>
//       {submitted || !isEmailVerified || !isWhatsappVerified ? (
//         <>
//           {submitted && <SubmitMsg rollnumber={rollnumber} />}
//           {!isEmailVerified && (
//             <div
//               className="formver"
//               style={{ backgroundImage: `url(${loginbg})` }}
//             >
//               <div className="formver-exam">
//                 The exam date is 23rd March, 2025.
//               </div>
//               <VerificationComponent
//                 type={`email`}
//                 setVerified={setIsEmailVerified}
//                 setValue={setemail}
//               />
//             </div>
//           )}
//           {isEmailVerified && !isWhatsappVerified && (
//             <div
//               className="formver"
//               style={{ backgroundImage: `url(${loginbg})` }}
//             >
//               <VerificationComponent
//                 type={`whatsapp`}
//                 setVerified={setIsWhatsappVerified}
//                 setValue={setwhatsapp}
//               />
//             </div>
//           )}
//         </>
//       ) : (
//         <div className="scholarship">
//           {isLoading && <Loader />}

//           <div className="scholarship-header">
//             <div className="scholarship-header-logo">
//               <img src={Logo} alt="Chaitainya's Academy Logo" />
//             </div>
//             <div className="scholarship-header-title">Admission Test Form</div>
//             <div className="scholarship-header-ams">AMS</div>
//           </div>
//           <div className="scholarship-contents">
//             {/* <div className="scholarship-contents-info">
//               Change in Exam Date - 17th Dec, 2023.
//             </div> */}
//             <div className="scholarship-contents-section">
//               <div className="scholarship-contents-section-title">
//                 Basic Information
//               </div>
//               <div className="scholarship-contents-section-fields">
//                 <div className="scholarship-contents-section-fields-field">
//                   <DropDown
//                     invalid={errors.branch}
//                     options={branches}
//                     label={`Branch`}
//                     value={branch}
//                     onChange={setBranch}
//                   />
//                 </div>
//                 <div className="scholarship-contents-section-fields-field">
//                   <DropDown
//                     invalid={errors.stream}
//                     options={streams}
//                     label={`Stream`}
//                     value={stream}
//                     onChange={setstream}
//                   />
//                 </div>
//                 <div className="scholarship-contents-section-fields-field">
//                   <DropDown
//                     invalid={errors.session}
//                     options={sessions}
//                     label={`Session`}
//                     value={session}
//                     onChange={setSession}
//                   />
//                 </div>
//                 <div className="scholarship-contents-section-fields-field">
//                   <DropDown
//                     invalid={errors.reference}
//                     options={refOptions}
//                     label={`Reference`}
//                     value={reference}
//                     onChange={setReference}
//                   />
//                 </div>
//                 <div
//                   className={`scholarship-contents-section-fields-field ${
//                     errors.firstname ? "invalid" : ""
//                   }`}
//                 >
//                   <TextField
//                     placeholder={`First Name`}
//                     value={firstname}
//                     onChange={setFirstname}
//                   />
//                 </div>
//                 <div
//                   className={`scholarship-contents-section-fields-field ${
//                     errors.middlename ? "invalid" : ""
//                   }`}
//                 >
//                   <TextField
//                     placeholder={`Middle Name`}
//                     value={middlename}
//                     onChange={setmiddlename}
//                   />
//                 </div>
//                 <div
//                   className={`scholarship-contents-section-fields-field ${
//                     errors.lastname ? "invalid" : ""
//                   }`}
//                 >
//                   <TextField
//                     className={errors.lastname ? "input-error" : ""}
//                     placeholder={`Last Name`}
//                     value={lastname}
//                     onChange={setlastname}
//                   />
//                 </div>
//                 <div className="scholarship-contents-section-fields-field">
//                   <DatePicker
//                     invalid={errors.dob}
//                     label={`Date of Birth`}
//                     value={dob}
//                     onChange={setdob}
//                   />
//                 </div>
//                 <div
//                   className={`scholarship-contents-section-fields-field ${
//                     errors.fathername ? "invalid" : ""
//                   }`}
//                 >
//                   <TextField
//                     placeholder={`Father's Name`}
//                     value={fathername}
//                     onChange={setfathername}
//                   />
//                 </div>
//                 <div
//                   className={`scholarship-contents-section-fields-field ${
//                     errors.mothername ? "invalid" : ""
//                   }`}
//                 >
//                   <TextField
//                     placeholder={`Mother's Name`}
//                     value={mothername}
//                     onChange={setmothername}
//                   />
//                 </div>
//                 <div
//                   className={`scholarship-contents-section-fields-field ${
//                     errors.email ? "invalid" : ""
//                   }`}
//                 >
//                   <TextField
//                     placeholder={`Email`}
//                     value={email}
//                     // disabled={true}
//                     onChange={(email) => setemail(email)}
//                   />
//                 </div>
//                 <div
//                   className={`scholarship-contents-section-fields-field ${
//                     errors.whatsapp ? "invalid" : ""
//                   }`}
//                 >
//                   <TextField
//                     placeholder={`WhatsApp No.`}
//                     value={whatsapp}
//                     // disabled={true}
//                     onChange={setwhatsapp}
//                   />
//                 </div>
//                 <div
//                   className={`scholarship-contents-section-fields-field ${
//                     errors.alternateNo ? "invalid" : ""
//                   }`}
//                 >
//                   <TextField
//                     placeholder={`Alternate No.`}
//                     value={alternateNo}
//                     onChange={setAlternateNo}
//                   />
//                 </div>
//                 <div
//                   className={`scholarship-contents-section-fields-field ${
//                     errors.aadhar ? "invalid" : ""
//                   }`}
//                 >
//                   <TextField
//                     placeholder={`Aadhar No.`}
//                     value={aadhar}
//                     onChange={setaadhar}
//                   />
//                 </div>
//                 <div className="scholarship-contents-section-fields-field">
//                   <DropDown
//                     invalid={errors.gender}
//                     options={genders}
//                     label={`Gender`}
//                     value={gender}
//                     onChange={setgender}
//                   />
//                 </div>
//                 <div className="scholarship-contents-section-fields-field">
//                   <DropDown
//                     invalid={errors.caste}
//                     options={casteOptions}
//                     label={`Caste`}
//                     value={caste}
//                     onChange={setcaste}
//                   />
//                 </div>
//                 <div className="scholarship-contents-section-fields-field">
//                   <DropDown
//                     invalid={errors.bloodgroup}
//                     options={bloodgroups}
//                     label={`Blood Group`}
//                     value={bloodgroup}
//                     onChange={setbloodgroup}
//                   />
//                 </div>
//                 <div
//                   className={`scholarship-contents-section-fields-field ${
//                     errors.parentOccupation ? "invalid" : ""
//                   }`}
//                 >
//                   <TextField
//                     placeholder={`Parent Occupation`}
//                     value={parentOccupation}
//                     onChange={setparentOccupation}
//                   />
//                 </div>
//                 <div className="scholarship-contents-section-fields-field"></div>
//               </div>
//             </div>

//             <hr className="scholarship-contents-seperator"></hr>

//             {/* Admission Related Information */}
//             <div className="scholarship-contents-section">
//               <div className="scholarship-contents-section-title">
//                 Admission Related Information
//               </div>
//               <div className="scholarship-contents-section-fields">
//                 <div className="scholarship-contents-section-fields-field">
//                   <DropDown
//                     invalid={errors.admclass}
//                     options={classOptions}
//                     label={`Want Admission for Class`}
//                     value={admclass}
//                     onChange={setadmclass}
//                   />
//                 </div>
//                 <div className="scholarship-contents-section-fields-field">
//                   <DropDown
//                     invalid={errors.hostel}
//                     options={bool}
//                     label={`Opting for Hostel`}
//                     value={hostel}
//                     onChange={sethostel}
//                   />
//                 </div>
//                 <div className="scholarship-contents-section-fields-field"></div>
//               </div>
//             </div>

//             <hr className="scholarship-contents-seperator"></hr>

//             {/* Previous Academic Information */}
//             <div className="scholarship-contents-section">
//               <div className="scholarship-contents-section-title">
//                 Previous Academic Information
//               </div>
//               <div className="scholarship-contents-section-fields">
//                 <div
//                   className={`scholarship-contents-section-fields-field ${
//                     errors.school ? "invalid" : ""
//                   }`}
//                 >
//                   <TextField
//                     placeholder={`Name of School`}
//                     value={school}
//                     onChange={setschool}
//                   />
//                 </div>
//                 <div className="scholarship-contents-section-fields-field">
//                   <DropDown
//                     invalid={errors.board}
//                     options={boards}
//                     label={`Board`}
//                     value={board}
//                     onChange={setboard}
//                   />
//                 </div>
//                 <div className="scholarship-contents-section-fields-field">
//                   <DropDown
//                     invalid={errors.medium}
//                     options={mediums}
//                     label={`Medium`}
//                     value={medium}
//                     onChange={setmedium}
//                   />
//                 </div>
//                 <div
//                   className={`scholarship-contents-section-fields-field ${
//                     errors.prevmaths ? "invalid" : ""
//                   }`}
//                 >
//                   <TextField
//                     placeholder={`Previous Year Maths Score`}
//                     value={prevmaths}
//                     onChange={setprevmaths}
//                   />
//                 </div>
//                 <div
//                   className={`scholarship-contents-section-fields-field ${
//                     errors.prevscience ? "invalid" : ""
//                   }`}
//                 >
//                   <TextField
//                     placeholder={`Previous Year Science Score`}
//                     value={prevscience}
//                     onChange={setprevscience}
//                   />
//                 </div>
//                 <div className="scholarship-contents-section-fields-field">
//                   <DropDown
//                     options={bool}
//                     value={otherExams}
//                     label={`Other Competitive Exams`}
//                     onChange={setOtherExams}
//                   />
//                 </div>
//                 {otherExams === "Yes" ? (
//                   <>
//                     <div className="scholarship-contents-section-fields-field-other">
//                       <DropDown
//                         options={examOptions}
//                         label={`Select Exam`}
//                         value={exam1}
//                         onChange={setexam1}
//                       />
//                     </div>
//                     <div className="scholarship-contents-section-fields-field-other">
//                       <TextField
//                         placeholder={`Exam Marks`}
//                         value={exam1Marks}
//                         onChange={setexam1Marks}
//                       />
//                     </div>

//                     <div className="scholarship-contents-section-fields-field-other">
//                       <DropDown
//                         options={examOptions}
//                         label={`Select Exam`}
//                         value={exam2}
//                         onChange={setexam2}
//                       />
//                     </div>
//                     <div className="scholarship-contents-section-fields-field-other">
//                       <TextField
//                         placeholder={`Exam Marks`}
//                         value={exam2Marks}
//                         onChange={setexam2Marks}
//                       />
//                     </div>

//                     <div className="scholarship-contents-section-fields-field-other">
//                       <DropDown
//                         options={examOptions}
//                         label={`Select Exam`}
//                         value={exam3}
//                         onChange={setexam3}
//                       />
//                     </div>
//                     <div className="scholarship-contents-section-fields-field-other">
//                       <TextField
//                         placeholder={`Exam Marks`}
//                         value={exam3Marks}
//                         onChange={setexam3Marks}
//                       />
//                     </div>
//                   </>
//                 ) : (
//                   ""
//                 )}
//               </div>
//             </div>

//             <hr className="scholarship-contents-seperator"></hr>

//             {/* Address */}
//             <div className="scholarship-contents-section">
//               <div className="scholarship-contents-section-title">Address</div>
//               <div className="scholarship-contents-section-fields">
//                 <div
//                   className={`scholarship-contents-section-fields-field ${
//                     errors.address ? "invalid" : ""
//                   }`}
//                 >
//                   <TextField
//                     placeholder={`Address`}
//                     value={address}
//                     onChange={setaddress}
//                   />
//                 </div>

//                 <div className="scholarship-contents-section-fields-field">
//                   <DropDown
//                     invalid={errors.district}
//                     options={districts}
//                     label={`District`}
//                     value={district}
//                     onChange={selectDistrict}
//                   />
//                 </div>

//                 <div className="scholarship-contents-section-fields-field">
//                   <DropDown
//                     invalid={errors.taluka}
//                     options={talukas}
//                     label={`Taluka`}
//                     value={taluka}
//                     onChange={settaluka}
//                   />
//                 </div>
//                 <div
//                   className={`scholarship-contents-section-fields-field ${
//                     errors.pin ? "invalid" : ""
//                   }`}
//                 >
//                   <TextField
//                     placeholder={`Pin`}
//                     value={pin}
//                     onChange={setpin}
//                   />
//                 </div>
//                 <div className="scholarship-contents-section-fields-field"></div>
//               </div>
//             </div>

//             <hr className="scholarship-contents-seperator"></hr>

//             <div className="scholarship-contents-section">
//               <div className="scholarship-contents-section-title">
//                 Upload Photo
//               </div>
//               <div className="scholarship-contents-section-fields">
//                 <div className="scholarship-contents-section-fields-field">
//                   <p className="scholarship-text">
//                     Please take current live selfie only (don't upload old
//                     passport size photos) <br />
//                     कृपया फक्त औपचारिक वर्तमान थेट सेल्फी घ्या (जुने पासपोर्ट
//                     आकाराचे फोटो अपलोड करू नका)
//                   </p>
//                   <input
//                     label="Select File"
//                     placeholder="Select File"
//                     type="file"
//                     onChange={(e) => setPhoto(e.target.files[0])}
//                   />
//                 </div>
//                 <div className="scholarship-contents-section-fields-field">
//                   {/* <input
//                     label="Select File"
//                     placeholder="Select File"
//                     type="file"
//                     onChange={(e) => setFile(e.target.files[0])}
//                   /> */}
//                 </div>

//                 <div className="scholarship-contents-section-fields-field"></div>
//               </div>
//             </div>

//             {error && <div className="scholarship-error">{error}</div>}
//             <div className="scholarship-button">
//               <Button text={`Submit`} onClick={handleSubmit} />
//             </div>
//           </div>
//         </div>
//       )}
//     </>
//   );
// };

// export default Scholarship;
