import React, { useState } from "react";
import {
  FaReceipt,
  FaCalendarAlt,
  FaFileUpload,
  FaTrash,
  FaPlus,
  FaSave,
  FaTimes,
  FaIdCard,
  FaPrint,
  FaBuilding,
} from "react-icons/fa";
import "./ExpenseForm.scss";
// Import your existing Modal component - adjust the path as needed
import Modal from "../../components/Modal/Modal";
import logoCIS from "../../assets/images/logoCIS.jpg";

const ExpenseForm = () => {
  // State for form data
  const [formData, setFormData] = useState({
    companyName: "KRISHNA CHAITANYA EDUCARE PVT. LTD.",
    gstin: "27AAICK1499A1ZJ",
    invoiceNumber: "INV-001",
    title: "",
    date: new Date().toISOString().split("T")[0],
    dueDate: "",
    vendorId: "",
    vendorName: "",
    vendorAddress: "",
    isGstBill: false,
    gstPercentage: "",
    category: "",
    purchaseType: "",
    paymentMethod: "",
    placeOfSupply: "",
    notes: "",
    items: [{ description: "", quantity: 1, unitPrice: "", amount: "" }],
    totalAmount: 0,
    files: [],
  });

  // State for modal
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newVendor, setNewVendor] = useState({
    name: "",
    address: "",
    email: "",
    phone: "",
    gstin: "",
    pan: "",
  });

  // Predefined options
  const categories = [
    "Electricity",
    "Plumbing",
    "Furniture",
    "Office Supplies",
    "IT Equipment",
    "Software",
    "Travel",
    "Meals",
    "Marketing",
    "Maintenance",
    "Rent",
    "Utilities",
    "Others",
  ];

  const purchaseTypes = ["Maintenance", "New Purchase"];

  const paymentMethods = [
    "Cash",
    "Bank Transfer",
    "Credit Card",
    "Debit Card",
    "Cheque",
  ];

  const gstRates = [5, 12, 18, 28];

  const vendors = [
    { id: 1, name: "Vendor 1", address: "123 Vendor Street, City" },
    { id: 2, name: "Vendor 2", address: "456 Supplier Avenue, Town" },
    { id: 3, name: "Vendor 3", address: "789 Business Road, State" },
  ];

  const placeOfSupplyOptions = ["Baramati", "Ranchi", "Nagar", "Pune"];

  // Handle form field changes
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  // Handle vendor change
  const handleVendorChange = (e) => {
    const { value } = e.target;
    if (value === "new") {
      setIsModalOpen(true);
    } else {
      const selectedVendor = vendors.find(
        (vendor) => vendor.id === parseInt(value)
      );
      setFormData((prevData) => ({
        ...prevData,
        vendorId: value,
        vendorName: selectedVendor ? selectedVendor.name : "",
        vendorAddress: selectedVendor ? selectedVendor.address : "",
      }));
    }
  };

  // Handle new vendor form changes
  const handleNewVendorChange = (e) => {
    const { name, value } = e.target;
    setNewVendor((prevVendor) => ({
      ...prevVendor,
      [name]: value,
    }));
  };

  // Save new vendor
  const saveNewVendor = () => {
    // Here you would typically save the vendor to your database
    console.log("New vendor:", newVendor);
    // Close modal
    setIsModalOpen(false);
    // Set the new vendor as the selected vendor
    setFormData((prevData) => ({
      ...prevData,
      vendorName: newVendor.name,
      vendorAddress: newVendor.address,
    }));
  };

  // Handle item changes
  const handleItemChange = (index, e) => {
    const { name, value } = e.target;
    const newItems = [...formData.items];
    newItems[index] = { ...newItems[index], [name]: value };

    // Calculate amount if both quantity and unitPrice are entered
    if (name === "quantity" || name === "unitPrice") {
      const quantity =
        name === "quantity"
          ? parseFloat(value) || 0
          : parseFloat(newItems[index].quantity) || 0;
      const unitPrice =
        name === "unitPrice"
          ? parseFloat(value) || 0
          : parseFloat(newItems[index].unitPrice) || 0;
      newItems[index].amount = (quantity * unitPrice).toFixed(2);
    }

    // Calculate total amount
    const totalAmount = newItems.reduce(
      (sum, item) => sum + (parseFloat(item.amount) || 0),
      0
    );

    setFormData((prevData) => ({
      ...prevData,
      items: newItems,
      totalAmount,
    }));
  };

  // Add new item row
  const addItem = () => {
    setFormData((prevData) => ({
      ...prevData,
      items: [
        ...prevData.items,
        { description: "", quantity: 1, unitPrice: "", amount: "" },
      ],
    }));
  };

  // Remove item row
  const removeItem = (index) => {
    const newItems = [...formData.items];
    newItems.splice(index, 1);

    // Calculate new total amount
    const totalAmount = newItems.reduce(
      (sum, item) => sum + (parseFloat(item.amount) || 0),
      0
    );

    setFormData((prevData) => ({
      ...prevData,
      items: newItems,
      totalAmount,
    }));
  };

  // Handle file uploads
  const handleFileChange = (e) => {
    const newFiles = Array.from(e.target.files);
    setFormData((prevData) => ({
      ...prevData,
      files: [...prevData.files, ...newFiles],
    }));
  };

  // Remove uploaded file
  const removeFile = (index) => {
    const newFiles = [...formData.files];
    newFiles.splice(index, 1);
    setFormData((prevData) => ({
      ...prevData,
      files: newFiles,
    }));
  };

  // Form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    // Here you would typically send the data to your backend
    console.log("Form submitted:", formData);
    // Reset form or redirect as needed
  };

  // Calculate GST amount
  const gstAmount =
    formData.isGstBill && formData.gstPercentage
      ? (
          (formData.totalAmount * parseInt(formData.gstPercentage)) /
          100
        ).toFixed(2)
      : 0;

  // Calculate final total with GST
  const finalTotal = (
    parseFloat(formData.totalAmount) + parseFloat(gstAmount)
  ).toFixed(2);

  return (
    <div className="expense-form-container">
      {/* <div className="action-bar">
        <button type="button" className="action-button">
          <FaPrint /> Print Invoice
        </button>
      </div> */}

      <form onSubmit={handleSubmit} className="expense-form">
        <div className="form-header">
          <div className="company-branding">
            <div className="logo-area">
              {/* <FaBuilding className="company-logo" /> */}
              <img className="company-logo" src={logoCIS} alt="" />
            </div>
            <div className="company-info">
              <h1>{formData.companyName}</h1>
              <p className="gstin">GSTIN: {formData.gstin}</p>
            </div>
          </div>
          <div className="invoice-title">
            <div className="invoice-label">EXPENSE INVOICE</div>
          </div>
        </div>

        <div className="form-content">
          <div className="invoice-details-section">
            <div className="form-row two-columns">
              <div className="form-field">
                <label htmlFor="invoiceNumber">Invoice Number</label>
                <input
                  type="text"
                  id="invoiceNumber"
                  name="invoiceNumber"
                  value={formData.invoiceNumber}
                  onChange={handleChange}
                  className="underlined-input"
                />
              </div>

              <div className="form-field">
                <label htmlFor="date">Issue Date</label>
                <div className="date-input-wrapper">
                  <input
                    type="date"
                    id="date"
                    name="date"
                    value={formData.date}
                    onChange={handleChange}
                  />
                  <FaCalendarAlt className="date-icon" />
                </div>
              </div>
            </div>

            <div className="form-row two-columns">
              <div className="form-field">
                <label htmlFor="title">Title/Description</label>
                <input
                  type="text"
                  id="title"
                  name="title"
                  value={formData.title}
                  onChange={handleChange}
                  placeholder="Brief expense description"
                  className="underlined-input"
                />
              </div>

              <div className="form-field">
                <label htmlFor="dueDate">Due Date</label>
                <div className="date-input-wrapper">
                  <input
                    type="date"
                    id="dueDate"
                    name="dueDate"
                    value={formData.dueDate}
                    onChange={handleChange}
                  />
                  <FaCalendarAlt className="date-icon" />
                </div>
              </div>
            </div>
          </div>

          <div className="invoice-sections">
            <div className="main-fields-section">
              <div className="section-group">
                <h3 className="section-title">Required Information</h3>

                <div className="form-row three-columns">
                  <div className="form-field">
                    <div className="checkbox-wrapper">
                      <input
                        type="checkbox"
                        id="isGstBill"
                        name="isGstBill"
                        checked={formData.isGstBill}
                        onChange={handleChange}
                      />
                      <label htmlFor="isGstBill">GST Bill</label>
                    </div>

                    {formData.isGstBill && (
                      <div className="gst-rate-wrapper">
                        <select
                          id="gstPercentage"
                          name="gstPercentage"
                          value={formData.gstPercentage}
                          onChange={handleChange}
                          className="bordered-select"
                        >
                          <option value="">Select GST %</option>
                          {gstRates.map((rate) => (
                            <option key={rate} value={rate}>
                              {rate}%
                            </option>
                          ))}
                        </select>
                      </div>
                    )}
                  </div>

                  <div className="form-field">
                    <label htmlFor="category">Category</label>
                    <select
                      id="category"
                      name="category"
                      value={formData.category}
                      onChange={handleChange}
                      className="bordered-select"
                    >
                      <option value="">Select Category</option>
                      {categories.map((category) => (
                        <option key={category} value={category}>
                          {category}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className="form-field">
                    <label htmlFor="purchaseType">Purchase Type</label>
                    <select
                      id="purchaseType"
                      name="purchaseType"
                      value={formData.purchaseType}
                      onChange={handleChange}
                      className="bordered-select"
                    >
                      <option value="">Select Type</option>
                      {purchaseTypes.map((type) => (
                        <option key={type} value={type}>
                          {type}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>

              <div className="section-group">
                <div className="section-split">
                  <div className="section-column">
                    <h3 className="section-title">Vendor Details</h3>
                    <div className="form-field">
                      <label htmlFor="vendorId">Select Vendor</label>
                      <select
                        id="vendorId"
                        name="vendorId"
                        value={formData.vendorId}
                        onChange={handleVendorChange}
                        className="bordered-select"
                      >
                        <option value="">Choose a vendor</option>
                        {vendors.map((vendor) => (
                          <option key={vendor.id} value={vendor.id}>
                            {vendor.name}
                          </option>
                        ))}
                        <option value="new">+ Add New Vendor</option>
                      </select>
                    </div>

                    {formData.vendorName && (
                      <div className="selected-vendor">
                        <div className="vendor-name">{formData.vendorName}</div>
                        <div className="vendor-address">
                          {formData.vendorAddress}
                        </div>
                      </div>
                    )}

                    <button
                      type="button"
                      className="btn-new-vendor"
                      onClick={() => setIsModalOpen(true)}
                    >
                      <FaPlus /> New Vendor
                    </button>
                  </div>

                  <div className="section-column">
                    <h3 className="section-title">Additional Details</h3>
                    <div className="form-field">
                      <label htmlFor="placeOfSupply">Place of Supply</label>
                      <select
                        id="placeOfSupply"
                        name="placeOfSupply"
                        value={formData.placeOfSupply}
                        onChange={handleChange}
                        className="bordered-select"
                      >
                        <option value="">Select Location</option>
                        {placeOfSupplyOptions.map((place) => (
                          <option key={place} value={place}>
                            {place}
                          </option>
                        ))}
                      </select>
                    </div>

                    <div className="form-field">
                      <label htmlFor="paymentMethod">Payment Method</label>
                      <select
                        id="paymentMethod"
                        name="paymentMethod"
                        value={formData.paymentMethod}
                        onChange={handleChange}
                        className="bordered-select"
                      >
                        <option value="">Select Method</option>
                        {paymentMethods.map((method) => (
                          <option key={method} value={method}>
                            {method}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="items-section">
              <h3 className="section-title">Line Items</h3>
              <div className="items-table">
                <div className="items-header">
                  <div className="col description">Description</div>
                  <div className="col quantity">Quantity</div>
                  <div className="col price">Price (₹)</div>
                  <div className="col amount">Amount (₹)</div>
                  <div className="col actions"></div>
                </div>

                <div className="items-body">
                  {formData.items.map((item, index) => (
                    <div className="item-row" key={index}>
                      <div className="col description">
                        <input
                          type="text"
                          name="description"
                          value={item.description}
                          onChange={(e) => handleItemChange(index, e)}
                          placeholder="Enter item description"
                        />
                      </div>
                      <div className="col quantity">
                        <input
                          type="number"
                          name="quantity"
                          value={item.quantity}
                          onChange={(e) => handleItemChange(index, e)}
                          min="1"
                        />
                      </div>
                      <div className="col price">
                        <input
                          type="number"
                          name="unitPrice"
                          value={item.unitPrice}
                          onChange={(e) => handleItemChange(index, e)}
                          step="0.01"
                          min="0"
                          placeholder="0.00"
                        />
                      </div>
                      <div className="col amount">
                        <span>₹ {item.amount || "0.00"}</span>
                      </div>
                      <div className="col actions">
                        <button
                          type="button"
                          className="btn-remove-item"
                          onClick={() => removeItem(index)}
                          disabled={formData.items.length <= 1}
                        >
                          <FaTimes />
                        </button>
                      </div>
                    </div>
                  ))}
                </div>

                <div className="add-item-row">
                  <button
                    type="button"
                    className="btn-add-item"
                    onClick={addItem}
                  >
                    <FaPlus /> Add Item
                  </button>
                </div>
              </div>

              <div className="invoice-totals">
                <div className="summary-row">
                  <span>Subtotal:</span>
                  <span>₹ {formData.totalAmount.toFixed(2)}</span>
                </div>

                {formData.isGstBill && formData.gstPercentage && (
                  <div className="summary-row">
                    <span>GST ({formData.gstPercentage}%):</span>
                    <span>₹ {gstAmount}</span>
                  </div>
                )}

                <div className="summary-row total">
                  <span>Total:</span>
                  <span>₹ {finalTotal}</span>
                </div>
              </div>
            </div>

            <div className="additional-details-section">
              <div className="section-split">
                <div className="section-column">
                  <h3 className="section-title">Notes</h3>
                  <div className="form-field">
                    <textarea
                      id="notes"
                      name="notes"
                      value={formData.notes}
                      onChange={handleChange}
                      placeholder="Add any additional notes about this expense"
                      rows="3"
                    ></textarea>
                  </div>
                </div>

                <div className="section-column">
                  <h3 className="section-title">Attachments</h3>
                  <div className="file-upload-area">
                    <label htmlFor="fileUpload" className="file-upload-label">
                      <FaFileUpload />
                      <span>Upload Documents</span>
                    </label>
                    <input
                      type="file"
                      id="fileUpload"
                      multiple
                      onChange={handleFileChange}
                      style={{ display: "none" }}
                    />
                  </div>

                  {formData.files.length > 0 && (
                    <div className="uploaded-files">
                      <ul className="file-list">
                        {formData.files.map((file, index) => (
                          <li key={index} className="file-item">
                            <span className="file-name">{file.name}</span>
                            <button
                              type="button"
                              className="btn-remove-file"
                              onClick={() => removeFile(index)}
                            >
                              <FaTrash />
                            </button>
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="form-actions">
            <button type="submit" className="btn-submit">
              <FaSave /> Save Expense
            </button>
          </div>
        </div>

        <div className="form-footer">
          <div className="footer-content">
            <div className="company-name">{formData.companyName}</div>
            <div className="company-details">
              123 Education Lane, Baramati, Maharashtra |
              info@chaitanyasacademy.in | +91 9423874888
            </div>
            <div className="footer-note">Thank you for your business!</div>
          </div>
        </div>
      </form>

      {/* New Vendor Modal */}
      <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <div className="vendor-modal-content">
          <h2>Add New Vendor</h2>

          <div className="vendor-form">
            <label htmlFor="vendorName">Vendor Name*</label>
            <input
              type="text"
              id="vendorName"
              name="name"
              value={newVendor.name}
              onChange={handleNewVendorChange}
              placeholder="Enter vendor name"
              required
            />

            <label htmlFor="vendorGstin">GSTIN</label>
            <input
              type="text"
              id="vendorGstin"
              name="gstin"
              value={newVendor.gstin}
              onChange={handleNewVendorChange}
              placeholder="Enter GST number"
            />

            <label htmlFor="vendorPan">PAN Number</label>
            <input
              type="text"
              id="vendorPan"
              name="pan"
              value={newVendor.pan}
              onChange={handleNewVendorChange}
              placeholder="Enter PAN number"
            />

            <label htmlFor="vendorAddress">Address*</label>
            <textarea
              id="vendorAddress"
              name="address"
              value={newVendor.address}
              onChange={handleNewVendorChange}
              placeholder="Enter vendor address"
              rows="3"
              required
            ></textarea>

            <label htmlFor="vendorEmail">Email</label>
            <input
              type="email"
              id="vendorEmail"
              name="email"
              value={newVendor.email}
              onChange={handleNewVendorChange}
              placeholder="Enter email address"
            />

            <label htmlFor="vendorPhone">Phone</label>
            <input
              type="text"
              id="vendorPhone"
              name="phone"
              value={newVendor.phone}
              onChange={handleNewVendorChange}
              placeholder="Enter phone number"
            />

            <div className="modal-actions">
              <button
                type="button"
                className="btn-cancel"
                onClick={() => setIsModalOpen(false)}
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn-save"
                onClick={saveNewVendor}
              >
                Save Vendor
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default ExpenseForm;
