import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import axios from "axios";
import { Uploader, Avatar, Loader } from "rsuite";

import Button from "../../components/Button/Button";
import Modal from "../../components/Modal/Modal";
import ChangePassword from "../../components/ChangePassword/ChangePassword";
import { clearUser } from "../../state/auth/authSlice";
import user from "../../assets/images/user.jpg";

import "./UserProfile.scss";

const BE_URL = "https://server.classerp.in";

function UserProfile() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // State
  const [userData, setUserData] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLogoutOpen, setIsLogoutOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [uploading, setUploading] = useState(false);

  useEffect(() => {
    fetchUserData();
  }, []);

  const fetchUserData = async () => {
    setIsLoading(true);
    try {
      const tokens = JSON.parse(localStorage.getItem("ERPTokens"));
      const response = await axios.get(`${BE_URL}/profile`, {
        headers: { Authorization: `Bearer ${tokens.accessToken}` },
      });
      setUserData(response.data);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        const refreshed = await refreshToken();
        if (refreshed) {
          fetchUserData();
        } else {
          handleLogout();
        }
      } else {
        console.error("Failed to fetch user data:", error);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const refreshToken = async () => {
    try {
      const tokens = JSON.parse(localStorage.getItem("ERPTokens"));
      const response = await axios.post(`${BE_URL}/refreshToken`, {
        refreshToken: tokens.refreshToken,
      });
      if (response.data.accessToken) {
        localStorage.setItem("ERPTokens", JSON.stringify(response.data));
        return true;
      } else {
        throw new Error("Failed to refresh token.");
      }
    } catch (error) {
      console.error("Error refreshing token:", error);
      localStorage.removeItem("ERPTokens");
      dispatch(clearUser());
      navigate("/login");
      return false;
    }
  };

  const handleFileChange = (fileInfo) => {
    if (fileInfo.length > 0) {
      const lastFile = fileInfo[fileInfo.length - 1];
      setSelectedFile(lastFile.blobFile);
    }
  };

  const handleProfilePicUpload = async () => {
    if (!selectedFile) return;

    setUploading(true);
    const formData = new FormData();
    formData.append("profilePic", selectedFile);

    try {
      const tokens = JSON.parse(localStorage.getItem("ERPTokens"));
      await axios.post(`${BE_URL}/upload-profile-pic`, formData, {
        headers: {
          Authorization: `Bearer ${tokens.accessToken}`,
          "Content-Type": "multipart/form-data",
        },
      });
      fetchUserData();
      setSelectedFile(null);
    } catch (error) {
      console.error("Error uploading profile picture:", error);
    } finally {
      setUploading(false);
    }
  };

  const handleLogout = () => {
    localStorage.removeItem("ERPTokens");
    dispatch(clearUser());
    navigate("/login");
  };

  if (isLoading) {
    return (
      <div className="profile-loading">
        <Loader size="md" />
      </div>
    );
  }

  return (
    <div className="profile-wrapper">
      {/* Modal for changing password */}
      <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <ChangePassword onDone={() => setIsModalOpen(false)} />
      </Modal>

      {/* Modal for confirming logout */}
      <Modal isOpen={isLogoutOpen} onClose={() => setIsLogoutOpen(false)}>
        <ConfirmationModal
          onConfirm={handleLogout}
          onCancel={() => setIsLogoutOpen(false)}
          ques="Are you sure you want to logout?"
        />
      </Modal>

      <div className="profile-container">
        <div className="profile-header">
          <h1>User Profile</h1>
        </div>

        <div className="profile-grid">
          <div className="profile-image-box">
            <div className="avatar-container">
              {userData?.image_url ? (
                <img
                  src={userData.image_url}
                  alt="Profile"
                  className="profile-pic"
                />
              ) : (
                <Avatar
                  circle
                  src={user}
                  size="lg"
                  className="default-avatar"
                />
              )}
              {uploading && <div className="uploading-indicator"></div>}
            </div>

            <div className="upload-actions">
              <Uploader
                autoUpload={false}
                onChange={handleFileChange}
                accept="image/*"
                disabled={uploading}
              >
                <Button text="Select Image" type="mini" />
              </Uploader>

              <Button
                text={uploading ? "Uploading..." : "Upload"}
                onClick={handleProfilePicUpload}
                disabled={!selectedFile || uploading}
              />
            </div>
          </div>

          <div className="profile-info-box">
            <div className="info-row">
              <div className="info-label">Name</div>
              <div className="info-value">{userData?.name || "N/A"}</div>
            </div>

            <div className="info-row">
              <div className="info-label">Email</div>
              <div className="info-value">{userData?.email || "N/A"}</div>
            </div>

            <div className="info-row">
              <div className="info-label">Phone</div>
              <div className="info-value">
                {userData?.phone_number || "N/A"}
              </div>
            </div>

            {userData?.employee_id && (
              <div className="info-row">
                <div className="info-label">Employee ID</div>
                <div className="info-value">{userData.employee_id}</div>
              </div>
            )}
          </div>

          <div className="profile-actions-box">
            <Button
              text="Change Password"
              type="arrow"
              onClick={() => setIsModalOpen(true)}
            />

            <Button
              text="Logout"
              type="red"
              onClick={() => setIsLogoutOpen(true)}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default UserProfile;

// Confirmation Modal Component
const ConfirmationModal = ({ onConfirm, onCancel, ques }) => {
  return (
    <div className="confirmation-modal">
      <div className="modal-question">{ques}</div>
      <div className="modal-buttons">
        <Button text="Yes" onClick={onConfirm} type="red" />
        <Button text="No" onClick={onCancel} type="arrow" />
      </div>
    </div>
  );
};
