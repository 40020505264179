import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import axios from "axios";

import data from "../../assets/data/districtData";
import "./StudentDetails.scss";

import TextField from "../../components/TextField/TextField";
import DropDown from "../../components/DropDown/DropDown";
import DatePicker from "../../components/DatePicker/DatePicker";
import Button from "../../components/Button/Button";
import Loader from "../../components/Loader/Loader";
import Modal from "../../components/Modal/Modal";
import ObjectDropDown from "../../components/ObjectDropDown/ObjectDropDown";
import StudentFee from "../../components/StudentFee/StudentFee";
import LeavesList from "../../components/LeavesList/LeavesList";
import AssignedInventory from "../../components/AssignedInventory/AssignedInventory";
import StudentResults from "../StudentResults/StudentResults";
import CustomDropDown from "../../components/CustomDropDown/CustomDropDown";
import InstallmentsStudentDetailsEdit from "../../components/InstallmentsStudentDetailsEdit/InstallmentsStudentDetailsEdit";

// React Icons import
import {
  FaUserCircle,
  FaBuilding,
  FaIdCard,
  FaEnvelope,
  FaPhone,
  FaGraduationCap,
  FaCamera,
  FaDownload,
  FaEdit,
  FaExclamationTriangle,
  FaBan,
  FaLock,
  FaBed,
  FaWhatsapp,
  FaExclamationCircle,
} from "react-icons/fa";
import { useSelector } from "react-redux";

// Custom components for the new UI
const InfoField = ({
  label,
  value,
  onChange,
  isEditMode = false,
  type = "text",
  options = [],
  error = null,
  fullWidth = false,
  onIdChange = null,
}) => {
  const renderEditField = () => {
    switch (type) {
      case "date":
        return (
          <input
            type="date"
            className={`field-input ${error ? "error" : ""}`}
            value={value || ""}
            onChange={(e) => onChange(e.target.value)}
          />
        );

      case "select":
        return (
          <select
            className={`field-input ${error ? "error" : ""}`}
            value={value || ""}
            onChange={(e) => onChange(e.target.value)}
          >
            <option value="">Select {label}</option>
            {options.map((option, index) => (
              <option key={index} value={option}>
                {option}
              </option>
            ))}
          </select>
        );

      case "boolean-select":
        return (
          <select
            className={`field-input ${error ? "error" : ""}`}
            value={value === true ? "Yes" : value === false ? "No" : value}
            onChange={(e) => onChange(e.target.value)}
          >
            <option value="">Select Option</option>
            <option value="Yes">Yes</option>
            <option value="No">No</option>
          </select>
        );

      case "object-select":
        return (
          <select
            className={`field-input ${error ? "error" : ""}`}
            value={value || ""}
            onChange={(e) => {
              const selected = options.find(
                (opt) => opt.value === e.target.value
              );
              onChange(e.target.value);
              if (onIdChange && selected) {
                onIdChange(selected.label);
              }
            }}
          >
            <option value="">Select {label}</option>
            {options.map((option, index) => (
              <option key={index} value={option.value}>
                {option.value}
              </option>
            ))}
          </select>
        );

      case "custom-select":
        return (
          <select
            className={`field-input ${error ? "error" : ""}`}
            value={value || ""}
            onChange={(e) => onChange(e.target.value)}
          >
            <option value="">Select {label}</option>
            {options.map((option) => (
              <option key={option.id} value={option.id}>
                {option.name}
              </option>
            ))}
          </select>
        );

      case "number-select":
        return (
          <select
            className={`field-input ${error ? "error" : ""}`}
            value={value || ""}
            onChange={(e) => onChange(parseInt(e.target.value))}
          >
            <option value="">Select {label}</option>
            {options.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
        );

      default:
        return (
          <input
            type="text"
            className={`field-input ${error ? "error" : ""}`}
            value={value || ""}
            onChange={(e) => onChange(e.target.value)}
            placeholder={`Enter ${label}`}
          />
        );
    }
  };

  return (
    <div className={`info-field ${fullWidth ? "fullWidth" : ""}`}>
      <label className="field-label">{label}</label>

      {isEditMode ? (
        <>
          {renderEditField()}
          {error && <div className="error-message">{error}</div>}
        </>
      ) : (
        <div className={`field-value ${!value ? "empty" : ""}`}>
          {value || "Not provided"}
        </div>
      )}
    </div>
  );
};

// InfoSection Component - Groups related fields with a title and optional edit button
const InfoSection = ({ title, children, isEditMode, onEdit }) => {
  return (
    <div className="info-section">
      <div className="info-section-header">
        <h3>{title}</h3>
        {!isEditMode && onEdit && (
          <button onClick={onEdit}>
            <FaEdit /> Edit
          </button>
        )}
      </div>
      {children}
    </div>
  );
};

// Tabs & Tab Components - For the tabbed interface
const Tabs = ({ children, defaultActiveKey }) => {
  const [activeKey, setActiveKey] = useState(defaultActiveKey);

  // Filter out only Tab components
  const tabs = React.Children.toArray(children).filter(
    (child) => child.type === Tab
  );

  return (
    <div className="tabs">
      <div className="tabs-nav">
        {tabs.map((tab) => (
          <div
            key={tab.props.tabKey}
            className={`tab-item ${
              activeKey === tab.props.tabKey ? "active" : ""
            }`}
            onClick={() => setActiveKey(tab.props.tabKey)}
          >
            {tab.props.title}
          </div>
        ))}
      </div>

      <div className="tab-content">
        {tabs.find((tab) => tab.props.tabKey === activeKey)}
      </div>
    </div>
  );
};

const Tab = ({ children, tabKey, title }) => {
  return <div>{children}</div>;
};

// Message Sender Component
const MessageSender = ({ onSend }) => {
  const [message, setMessage] = useState("");
  const [sendOnWhatsapp, setSendOnWhatsapp] = useState(false);

  const handleSend = () => {
    if (!message.trim()) {
      alert("Message cannot be empty");
      return;
    }

    onSend({ message, sendOnWhatsapp });
    setMessage("");
    setSendOnWhatsapp(false);
  };

  return (
    <div className="message-sender">
      <input
        type="text"
        placeholder="Enter your message..."
        value={message}
        onChange={(e) => setMessage(e.target.value)}
        className="message-input"
      />
      <label className="whatsapp-checkbox">
        <input
          type="checkbox"
          checked={sendOnWhatsapp}
          onChange={(e) => setSendOnWhatsapp(e.target.checked)}
        />
        WhatsApp
      </label>
      <button className="send-button" onClick={handleSend}>
        Send
      </button>
    </div>
  );
};

// Main StudentDetails Component
const StudentDetails = () => {
  const navigate = useNavigate();
  const printDivRef = useRef();
  const { roll_no } = useParams();
  const location = useLocation();

  // State for edit mode
  const [isEditMode, setIsEditMode] = useState(false);

  // Keep all your existing state variables and functions
  const [is_senior, setis_senior] = useState(
    location.pathname.includes("seniorStudent")
  );

  // RBAC code block
  const allPermissions = useSelector((state) => state.permissions);
  let permissions = null;
  if (is_senior) {
    permissions = allPermissions
      .find((p) => p.name === "Students")
      ?.dropdowns.find((dd) => dd.name === "Senior Students")?.permissions;
  } else {
    permissions = allPermissions
      .find((p) => p.name === "Students")
      ?.dropdowns.find((dd) => dd.name === "Junior Students")?.permissions;
  }

  // All your existing state declarations and functions go here...
  const [districts, setdistricts] = useState([]);
  const [talukas, settalukas] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [id, setid] = useState(null);
  const [userId, setuserId] = useState(null);
  const [branch, setBranch] = useState(null);
  const [rollnumber, setrollnumber] = useState(null);
  const [OMRrollnumber, setOMRrollnumber] = useState(null);
  const [firstname, setFirstname] = useState(null);
  const [middlename, setmiddlename] = useState(null);
  const [lastname, setlastname] = useState(null);
  const [dob, setdob] = useState(null);
  const [fathername, setfathername] = useState(null);
  const [mothername, setmothername] = useState(null);
  const [email, setemail] = useState(null);
  const [whatsapp, setwhatsapp] = useState(null);
  const [alternateNo, setAlternateNo] = useState(null);
  const [aadhar, setaadhar] = useState(null);
  const [gender, setgender] = useState(null);
  const [stream, setstream] = useState(null);
  const [bloodgroup, setbloodgroup] = useState(null);
  const [admclass, setadmclass] = useState(null);
  const [hostel, sethostel] = useState(false);
  const [error, setError] = useState(null);
  const [errors, setErrors] = useState({});
  const [address, setaddress] = useState(null);
  const [district, setdistrict] = useState(null);
  const [taluka, settaluka] = useState(null);
  const [pin, setpin] = useState(null);
  const [cancelled, setcancelled] = useState(null);
  const [cancellationTime, setcancellationTime] = useState(new Date());
  const [scholarshipAmount, setscholarshipAmount] = useState(0);
  const [additionalAmount, setAdditionalAmount] = useState(0);
  const [totalFees, setTotalFees] = useState("");
  const [classes, setClasses] = useState([]);
  const [division, setDivision] = useState(null);
  const [divisions, setDivisions] = useState([]);
  const [selectedDivisionId, setSelectedDivisionId] = useState(null);
  const [year_start, setyear_start] = useState(null);
  const [year_end, setyear_end] = useState(null);
  const [feeStructures, setfeeStructures] = useState([]);
  const [selectedClassId, setSelectedClassId] = useState(null);
  const [bed_id, setBed_id] = useState(null);
  const [selectedBedCount, setSelectedBedCount] = useState(null);
  const [classFee, setClassFee] = useState(0);
  const [hostelFee, setHostelFee] = useState(0);
  const [installments, setinstallments] = useState([]);
  const [originalInstallments, setoriginalInstallments] = useState([]);
  const [reducedTotal, setReducedTotal] = useState(0);
  const [selectedAdditionalSubject, setSelectedAdditionalSubject] =
    useState(null);
  const [prev_roll_no, setprev_roll_no] = useState(null);
  const [additional_subject_name, setadditional_subject_name] = useState(null);
  const [additional_subject, setadditional_subject] = useState(null);
  const [messRequired, setMessRequired] = useState(false);
  const [messFee, setmessFee] = useState(null);
  const [messMonths, setMessMonths] = useState(0);
  const [messValidityDate, setMessValidityDate] = useState("");
  const [messPriceId, setmessPriceId] = useState(null);
  const [transportPriceId, settransportPriceId] = useState(null);
  const [transportRequired, setTransportRequired] = useState(false);
  const [transportMonths, setTransportMonths] = useState(0);
  const [transportFee, settransportFee] = useState(null);
  const [transportValidityDate, setTransportValidityDate] = useState("");
  const [messOptions, setmessOptions] = useState([]);
  const [transportOptions, settransportOptions] = useState([]);
  const [selectedMessPrice, setSelectedMessPrice] = useState("");
  const [selectedTransportPrice, setSelectedTransportPrice] = useState("");
  const [messages, setmessages] = useState([]);
  const [photo_url, setphoto_url] = useState(null);
  const [file, setFile] = useState(null);
  const [isImageModalOpen, setIsImageModalOpen] = useState(false);
  const [isStMsgOpen, setIsStMsgOpen] = useState(false);
  const [availableBeds, setAvailableBeds] = useState([]);
  const [bedDetails, setBedDetails] = useState([]);
  const [leaves, setleaves] = useState([]);
  const [feeEdit, setfeeEdit] = useState(false);

  const bloodgroups = ["A+", "A-", "B+", "B-", "AB+", "AB-", "O+", "O-"];
  const genders = ["Male", "Female"];
  const streams = ["Engineering", "Medical"];
  const academic_years = [
    "2023-2024",
    "2024-2025",
    "2025-2026",
    "2026-2027",
    "2027-2028",
    "2028-2029",
    "2029-2030",
  ];

  const tokens = JSON.parse(localStorage.getItem("ERPTokens"));
  // const BE_URL = "http://localhost:8000";
  const BE_URL = "https://server.classerp.in";

  // All your existing useEffects and functions...

  // Formatting functions
  const formatDateOnly = (timestamp) => {
    const date = new Date(timestamp);
    return date.toLocaleDateString();
  };

  const formatDateAndTime = (timestamp) => {
    const date = new Date(timestamp);
    return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
  };

  useEffect(() => {
    if (isLoading) return;
    setIsLoading(true);

    window.scrollTo(0, 0);
    let cities = [];
    data.map((city) => cities.push(city.name));
    setdistricts(cities);

    if (roll_no) {
      fetchStudentDetails();
    }
  }, []);

  useEffect(() => {
    if (bed_id) {
      fetchBedDetailsById();
    }
  }, [bed_id]); // Ensure this runs only when bed_id changes

  const fetchBedDetailsById = async () => {
    setIsLoading(true);
    try {
      const tokens = JSON.parse(localStorage.getItem("ERPTokens"));
      const response = await axios.get(`${BE_URL}/api/bedDetails/${bed_id}`, {
        headers: { Authorization: `Bearer ${tokens.accessToken}` },
      });
      // Assuming you want to store this data for display
      setBedDetails(response.data); // You need to define setBedDetails and bedDetails state
    } catch (error) {
      console.error("Failed to fetch bed details:", error);
      setError("Failed to fetch bed details. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  const fetchAvailableBeds = async (bedCount) => {
    setIsLoading(true);
    try {
      const response = await axios.get(
        `${BE_URL}/api/hostelBeds?gender=${gender}`,
        {
          headers: { Authorization: `Bearer ${tokens.accessToken}` },
        }
      );
      setAvailableBeds(response.data);
    } catch (error) {
      console.error("Error fetching available beds:", error);
      // Handle error appropriately
    } finally {
      setIsLoading(false);
    }
  };

  const fetchMessageById = async (studentId) => {
    setIsLoading(true);
    try {
      const tokens = JSON.parse(localStorage.getItem("ERPTokens"));
      const response = await axios.get(
        `${BE_URL}/api/students/message/${studentId}`,
        {
          headers: { Authorization: `Bearer ${tokens.accessToken}` },
        }
      );
      setmessages(response.data);
    } catch (error) {
      if (!error.response.status === 404) {
        console.error("Failed to fetch messages:", error);
        setError("Failed to fetch messages. Please try again.");
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleAssignBed = async (bedId) => {
    setIsLoading(true);
    try {
      await axios.post(
        `${BE_URL}/api/hostels/beds/assign`,
        {
          bed_id: bedId,
          student_id: id,
          student_roll_no: roll_no,
        },
        {
          headers: { Authorization: `Bearer ${tokens.accessToken}` },
        }
      );
      alert("Bed assigned successfully.");
      fetchAvailableBeds(selectedBedCount); // Refresh available beds
      fetchStudentDetails();
    } catch (error) {
      console.error("Error assigning bed:", error);
      // Handle error appropriately
    } finally {
      setIsLoading(false);
    }
  };
  const handleSendMessage = async (details) => {
    setIsLoading(true);

    console.log("msg details", details.sendOnWhatsapp);
    try {
      await axios.post(
        `${BE_URL}/api/students/message`,
        {
          message: details.message,
          student_id: id,
          send_on_whatsapp: details.sendOnWhatsapp,
        },
        {
          headers: { Authorization: `Bearer ${tokens.accessToken}` },
        }
      );
      alert("Message sent successfully.");
      fetchAvailableBeds(selectedBedCount); // Refresh available beds
      fetchStudentDetails();
    } catch (error) {
      console.error("Error assigning bed:", error);
      // Handle error appropriately
    } finally {
      setIsLoading(false);
    }
  };

  // Trigger this function when selectedBedCount changes
  useEffect(() => {
    if (selectedBedCount) {
      fetchAvailableBeds(selectedBedCount);
    }
  }, [selectedBedCount]);

  useEffect(() => {
    if (!hostel) {
      setSelectedBedCount(null);
    }
  }, [hostel]);

  const fetchPrices = async () => {
    try {
      const tokens = JSON.parse(localStorage.getItem("ERPTokens"));
      const { data } = await axios.get(`${BE_URL}/api/prices`, {
        headers: { Authorization: `Bearer ${tokens.accessToken}` },
      });

      const messData = data
        .filter((item) => item.type === "Mess")
        .map((item) => ({
          id: item.id,
          name: `${item.service} - Rs${item.price_per_month}`,
          price: item.price_per_month,
        }));

      const transportData = data
        .filter((item) => item.type === "Transport")
        .map((item) => ({
          id: item.id,
          name: `${item.service} - Rs${item.price_per_month}`,
          price: item.price_per_month,
        }));

      setmessOptions(messData);
      settransportOptions(transportData);
    } catch (err) {
      console.error("Error fetching prices:", err);
      alert("Failed to load prices.");
    }
  };

  const handleMessSelection = (selectedId) => {
    setmessPriceId(selectedId);
    const selectedOption = messOptions.find(
      (option) => option.id === selectedId
    );
    console.log("Selected option is ", selectedOption);
    setSelectedMessPrice(selectedOption ? selectedOption.price : "");
  };

  const handleTransportSelection = (selectedId) => {
    settransportPriceId(selectedId);
    const selectedOption = transportOptions.find(
      (option) => option.id === selectedId
    );
    setSelectedTransportPrice(selectedOption ? selectedOption.price : "");
  };

  useEffect(() => {
    fetchClasses();
    fetchfeeStructures();
    fetchPrices();
  }, []);

  useEffect(() => {
    if (selectedClassId) {
      setDivision(null);
      setSelectedDivisionId(null);
      fetchDivisions(selectedClassId);
    }
  }, [selectedClassId]);

  const fetchClasses = async () => {
    setIsLoading(true);
    try {
      const tokens = JSON.parse(localStorage.getItem("ERPTokens"));
      const response = await axios.get(`${BE_URL}/api/classes`, {
        headers: {
          Authorization: `Bearer ${tokens.accessToken}`, // Use the access token for authorization
        },
      });
      setClasses(response.data); // Assuming the API returns an array of class objects
    } catch (error) {
      console.error("Error fetching classes:", error);
      // Handle specific errors (e.g., unauthorized, server issues)
      if (error.response && error.response.status === 401) {
        // Handle unauthorized error, e.g., redirect to login or refresh token
        alert("Session expired. Please login again.");
      } else {
        alert("Failed to fetch classes. Please try again later.");
      }
    } finally {
      setIsLoading(false);
    }
  };

  const fetchDivisions = async (id) => {
    setIsLoading(true);
    try {
      const response = await axios.get(
        `${BE_URL}/api/general/class/${id}/divisions`,
        {}
      );
      setDivisions(response.data);
    } catch (error) {
      console.error("Error fetching classes:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchfeeStructures = async () => {
    setIsLoading(true);
    try {
      const tokens = JSON.parse(localStorage.getItem("ERPTokens"));
      const response = await axios.get(`${BE_URL}/api/feeStrAdmission`, {
        headers: {
          Authorization: `Bearer ${tokens.accessToken}`, // Use the access token for authorization
        },
      });
      setfeeStructures(response.data); // Assuming the API returns an array of hostel pricing objects
    } catch (error) {
      console.error("Error fetching hostel pricing:", error);
      // Handle specific errors similarly as done in fetchClasses
      if (error.response && error.response.status === 401) {
        alert("Session expired. Please login again.");
      } else {
        alert("Failed to fetch hostel pricing. Please try again later.");
      }
    } finally {
      setIsLoading(false); // Ensure loading state is updated in finally block
    }
  };

  const handleRefresh = () => {
    fetchStudentDetails();
  };

  const fetchStudentDetails = async () => {
    setIsLoading(true);
    try {
      const tokens = JSON.parse(localStorage.getItem("ERPTokens"));
      const url = is_senior
        ? `${BE_URL}/api/students/${roll_no}`
        : `${BE_URL}/api/students/junior/${roll_no}`;
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${tokens.accessToken}`,
        },
      });

      if (response.data) {
        const scholarship = response.data;

        // Parse the date and extract only the date part
        const dob = new Date(scholarship.date_of_birth);
        const formattedDob = dob.toISOString().split("T")[0];

        setid(scholarship.id);
        setuserId(scholarship.user_id);
        setBranch(scholarship.branch);
        setrollnumber(scholarship.roll_no);
        setOMRrollnumber(scholarship.omr_roll_no);
        setFirstname(scholarship.first_name);
        setmiddlename(scholarship.middle_name);
        setlastname(scholarship.last_name);
        setdob(formattedDob);
        setfathername(scholarship.fathers_name);
        setmothername(scholarship.mothers_name);
        setemail(scholarship.email);
        setwhatsapp(scholarship.whatsapp);
        setAlternateNo(scholarship.alternate_no);
        setaadhar(scholarship.aadhar);
        setgender(scholarship.gender);
        setstream(scholarship.stream);
        setbloodgroup(scholarship.blood_group);
        setadmclass(scholarship.adm_class);
        sethostel(scholarship.hostel);
        setphoto_url(scholarship.photo_url);

        setDivision(scholarship.division);
        setSelectedDivisionId(scholarship.division_id);

        setaddress(scholarship.address);
        setdistrict(scholarship.district);
        settaluka(scholarship.taluka);
        setpin(scholarship.pin);
        setcancelled(scholarship.is_cancelled);
        setcancellationTime(scholarship.cancellation_time);

        setscholarshipAmount(scholarship.scholarship_amt);
        setAdditionalAmount(scholarship.additial_amount);
        setBed_id(scholarship.bed_id);
        setSelectedBedCount(scholarship.bed_count);
        setadditional_subject(
          scholarship.additional_subject_name ? true : false
        );
        setSelectedAdditionalSubject(scholarship.additional_subject_name);

        setyear_start(scholarship.academic_year_start);
        setyear_end(scholarship.academic_year_end);

        setMessRequired(scholarship.mess);
        setmessFee(scholarship.messfees);
        setMessMonths(scholarship.messmonths);
        setmessPriceId(scholarship.messpriceid);
        setMessValidityDate(formatDate(scholarship.messvalidity));
        setTransportRequired(scholarship.transport);
        setTransportMonths(scholarship.transportmonths);
        settransportFee(scholarship.transportfees);
        settransportPriceId(scholarship.transportpriceid);
        setTransportValidityDate(formatDate(scholarship.transportvalidity));

        setReducedTotal(scholarship.discounted_fee);
        setTotalFees(scholarship.total_fee);
        setprev_roll_no(scholarship.prev_roll_no);
        setinstallments(scholarship.installments);
        setoriginalInstallments(scholarship.installments);

        const leavesresponse = await axios.get(
          `${BE_URL}/api/general/userLeaves/${scholarship.user_id}`,
          {
            headers: {
              Authorization: `Bearer ${tokens.accessToken}`, // Use the access token for authorization
            },
          }
        );
        setleaves(leavesresponse.data);
        fetchMessageById(scholarship.id);
        setIsLoading(false);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // Handle token expiry
        setIsLoading(false);
        const newTokens = await refreshToken();
        if (newTokens) {
          fetchStudentDetails(); // Retry fetching details with new token
        } else {
          // Redirect to login or show an error
          alert("User logged out.");
          // navigate("/login");
        }
      } else {
        console.error("Error fetching scholarship details:", error);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const validateForm = () => {
    const newErrors = {};

    const requiredFields = [
      "firstname",
      "middlename",
      "lastname",
      "fathername",
      "dob",
      "mothername",
      "email",
      "whatsapp",
      "alternateNo",
      "aadhar",
      "gender",
      "bloodgroup",
      "admclass",
      "address",
      "district",
      "taluka",
      "pin",
      "year_start",
    ];

    for (let field of requiredFields) {
      if (!eval(field)) {
        newErrors[field] = "This field is required";
      }
    }

    // New validations
    if (whatsapp && whatsapp.length !== 10) {
      newErrors.whatsapp = "WhatsApp number must be 10 digits";
    }

    if (alternateNo && alternateNo.length !== 10) {
      newErrors.alternateNo = "Alternate number must be 10 digits";
    }

    if (aadhar && aadhar.length !== 12) {
      newErrors.aadhar = "Aadhar number must be 12 digits";
    }

    if (pin && pin.length !== 6) {
      newErrors.pin = "Pin must be 6 digits";
    }

    setErrors(newErrors);
    if (Object.keys(newErrors).length === 0) {
      setError(null);
      return true;
    } else {
      setError("Please recheck the fields marked in red.");
      console.log(newErrors);
      setIsLoading(false);
      return false;
    }
  };

  const formatDate = (date) => {
    const newdate = new Date(date);
    return newdate.toISOString().split("T")[0];
  };

  const handleSubmit = async () => {
    if (!validateForm()) {
      alert("Please correct the errors before submitting.");
      return;
    }

    if (!admclass) {
      alert("Please select the class");
      return;
    }
    if (!selectedDivisionId) {
      alert("Please select the Division");
      return;
    }
    if (hostel && !selectedBedCount) {
      alert("Please select the bed count.");
      return;
    }

    if (!window.confirm("Are you sure you want to save the changes?")) {
      return;
    }

    setIsLoading(true);
    setError(null);

    const studentData = {
      // Gather all necessary fields from state
      id: id,
      is_senior: is_senior,
      prev_roll_no: roll_no,
      first_name: firstname,
      middle_name: middlename,
      last_name: lastname,
      date_of_birth: dob,
      fathers_name: fathername,
      mothers_name: mothername,
      email: email,
      whatsapp: whatsapp,
      alternate_no: alternateNo,
      aadhar: aadhar,
      gender: gender,
      stream: stream,
      blood_group: bloodgroup,
      adm_class: admclass.match(/\d+/)[0],
      class_id: selectedClassId, // Ensure this is the actual class ID
      division: division,
      division_id: selectedDivisionId,
      academic_year_start: year_start,
      academic_year_end: year_end,
      hostel: hostel,
      scholarship_amt: scholarshipAmount,
      additial_amount: additionalAmount > 0 ? additionalAmount : null,
      total_fee: totalFees,
      class_fee: classFee,
      discounted_fee: reducedTotal,
      hostel_fees: hostelFee,
      bed_count: hostel ? selectedBedCount : null,
      academic_year_start: year_start,
      academic_year_end: year_end,
      mess: messRequired,
      messMonths: messRequired ? messMonths : null,
      messFees: messRequired ? messFee : null,
      messValidity: messRequired ? messValidityDate : null,
      messPriceId: messRequired ? messPriceId : null,
      transport: transportRequired,
      transportMonths: transportRequired ? transportMonths : null,
      transportFees: transportRequired ? transportFee : null,
      transportPriceId: transportRequired ? transportPriceId : null,
      transportValidity: transportRequired ? transportValidityDate : null,
      address: address,
      district: district,
      taluka: taluka,
      pin: pin,
      updateInstallments: false,
      installments: null,
      additional_subject: additional_subject,
      additional_subject_name: additional_subject
        ? additional_subject_name
        : null,
      // Include any other fields you have in your form
    };

    try {
      const url = is_senior
        ? `${BE_URL}/api/students/${roll_no}`
        : `${BE_URL}/api/students/junior/${roll_no}`;
      const response = await axios.put(url, studentData, {
        headers: {
          Authorization: `Bearer ${tokens.accessToken}`,
        },
      });
      if (response.status === 200) {
        alert("Student data updated successfully!");
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // Token expired, attempt to refresh
        const refreshed = await refreshToken();
        if (refreshed) {
          handleSubmit();
        } else {
          navigate("/login");
        }
      } else {
        console.error("Failed to submit form:", error);
        setError("Failed to create student. Please try again.");
      }
    } finally {
      setIsLoading(false);
    }
  };

  const refreshToken = async () => {
    try {
      const tokens = JSON.parse(localStorage.getItem("ERPTokens"));
      const response = await fetch(`${BE_URL}/refreshToken`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ token: tokens.refreshToken }),
      });

      if (!response.ok) {
        throw new Error("Failed to refresh token");
      }

      const newTokens = await response.json();
      localStorage.setItem("ERPTokens", JSON.stringify(newTokens));
      return newTokens;
    } catch (error) {
      console.error("Error refreshing token:", error);
      // alert("User loggod out. Please login again");
      // navigate("/login");
      // return null;
    }
  };

  const handleDelete = async () => {
    setIsLoading(true);
    setError(null);

    try {
      const tokens = JSON.parse(localStorage.getItem("ERPTokens"));
      const url = is_senior
        ? `${BE_URL}/api/students/${roll_no}`
        : `${BE_URL}/api/students/junior/${roll_no}`;
      await axios.delete(url, {
        headers: { Authorization: `Bearer ${tokens.accessToken}` },
      });
      alert("Student deleted Successfully");
      navigate(is_senior ? "/admin/seniorStudents" : "/admin/juniorStudents");
    } catch (error) {
      if (error.response && error.response.status === 401) {
        const newAccessToken = await refreshToken();
        if (newAccessToken) {
          return handleDelete();
        } else {
          setError("Authentication failed. Please log in again.");
        }
      } else if (error.response && error.response.status === 409) {
        console.log(error.response);
        alert(
          "Deletion request denied! Reason - Student has paid fees. Please proceed to cancel the student admission and refund."
        );
      } else {
        setError(error.message || "Failed to delete subject.");
      }
    } finally {
      setIsLoading(false);
    }
  };

  const cancelStudent = async () => {
    if (!window.confirm("Are you sure you want to cancel this student?")) {
      return;
    }

    const refundAmount = prompt(
      "Please enter the refund amount for cancellation (enter 0 if not applicable):"
    );
    if (refundAmount === null) {
      return;
    }

    setIsLoading(true);
    try {
      const url = is_senior
        ? `${BE_URL}/api/students/cancel/${id}`
        : `${BE_URL}/api/students/junior/cancel/${id}`;
      const response = await axios.put(
        url,
        { refundAmount }, // Send refundAmount to the backend
        {
          headers: {
            Authorization: `Bearer ${tokens.accessToken}`,
          },
        }
      );
      if (response.status === 200) {
        alert(`Student cancelled successfully! `);
      }
      fetchStudentDetails();
    } catch (error) {
      if (error.response && error.response.status === 401) {
        try {
          const newAccessToken = await refreshToken();
          if (newAccessToken) {
            await cancelStudent(id); // Retry the API call with the new token
          }
        } catch (refreshError) {
          setError("Session expired. Please log in again.");
        }
      } else {
        console.error("Failed to cancel student:", error);
        setError("Failed to cancel student. Please try again.");
      }
    } finally {
      setIsLoading(false);
    }
  };

  const deallocateBedFromStudent = async () => {
    try {
      const tokens = JSON.parse(localStorage.getItem("ERPTokens"));
      if (!tokens || !tokens.accessToken) {
        throw new Error("Authentication token not found");
      }

      const response = await axios.post(
        `${BE_URL}/api/hostels/beds/deassign`,
        {
          bed_id: bed_id,
          student_id: id,
        },
        {
          headers: {
            Authorization: `Bearer ${tokens.accessToken}`,
          },
        }
      );
      fetchStudentDetails();

      console.log("Bed deallocated successfully:", response.data);
      return response.data; // or handle response data as needed
    } catch (error) {
      console.error("Error deallocating bed from student:", error);
      console.error("Error deallocating bed from student:", error);
      // Handle errors, e.g., show notification or error message
      if (error.response) {
        // Server responded with a status code outside the 2xx range
        console.error("Failed to deallocate bed:", error.response.data);
      } else if (error.request) {
        // The request was made but no response was received
        console.error("No response received:", error.request);
      } else {
        // Something else caused the error
        alert("Something went wrong, please try again later.");
        console.error("Error:", error.message);
      }
      throw error; // Re-throw to let the calling component handle it
    }
  };

  const reactivateStudent = async () => {
    if (!window.confirm("Are you sure you want to reactivate this student?")) {
      return;
    }
    setIsLoading(true);
    try {
      const url = is_senior
        ? `${BE_URL}/api/students/reactivate/${id}`
        : `${BE_URL}/api/students/junior/reactivate/${id}`;
      const response = await axios.put(
        url,
        {
          first_name: firstname,
          middle_name: middlename,
          last_name: lastname,
          email,
          whatsapp,
        },
        {
          headers: {
            Authorization: `Bearer ${tokens.accessToken}`,
          },
        }
      );
      if (response.status === 200) {
        alert("Student reactivated successfully!");
      }
      fetchStudentDetails();
    } catch (error) {
      if (error.response && error.response.status === 401) {
        try {
          const newAccessToken = await refreshToken();
          if (newAccessToken) {
            await reactivateStudent(id); // Retry the API call with the new token
          }
        } catch (refreshError) {
          setError("Session expired. Please log in again.");
          // Handle logout and redirection to login page here
        }
      } else {
        console.error("Failed to reactivate student:", error);
        setError("Failed to reactivate student. Please try again.");
      }
    } finally {
      setIsLoading(false);
    }
  };

  const selectDistrict = (val) => {
    setdistrict(val);
    const obj = data.find((city) => city.name === val);
    settalukas(obj.tahasil);
  };

  const monthsOptions = Array.from({ length: 11 }, (_, i) => ({
    label: `${i + 1}`, // Display numbers starting from 1 to 11 as string
    value: i + 1, // Actual value is the month number from 1 to 11
  }));

  // Handle file change
  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };
  // Handle Photo Change/Upload
  const handleFileUpload = async () => {
    if (!file) {
      alert("Please select a file first.");
      return;
    }

    const formData = new FormData();
    formData.append("photo", file);
    formData.append("type", "students");

    try {
      const tokens = JSON.parse(localStorage.getItem("ERPTokens")); // Ensure you have tokens stored in localStorage
      const response = await axios.post(
        `${BE_URL}/updateStudentPhoto/${id}/`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${tokens.accessToken}`, // Authorization header
            "Content-Type": "multipart/form-data", // Required for file uploads
          },
        }
      );

      setphoto_url(response.data.photoUrl); // Update photo with new URL
      alert("Photo updated successfully!");
    } catch (error) {
      console.error("Error uploading photo:", error);
      alert("Failed to upload photo. Please try again.");
    }
  };

  // Handle download photo
  const handleDownloadPhoto = () => {
    const link = document.createElement("a");
    link.href = photo_url; // Use the photo URL to download
    link.download = `${rollnumber}_${firstname}_${lastname}_photo.jpg`; // File name
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleEditFees = (type) => {
    if (type === "Cancel") {
      setfeeEdit(false);
    } else {
      setfeeEdit(true);
      setinstallments(originalInstallments);
    }
  };

  return (
    <div className="student-details-page">
      {/* Image Modals */}
      <Modal
        isOpen={isImageModalOpen}
        onClose={() => setIsImageModalOpen(false)}
      >
        <div className="photo-upload-container">
          <h3>Update Profile Photo</h3>
          <div className="photo-preview">
            {file && <img src={URL.createObjectURL(file)} alt="Preview" />}
          </div>
          <input type="file" onChange={handleFileChange} accept="image/*" />
          <div className="modal-actions">
            <button
              className="btn-secondary"
              onClick={() => setIsImageModalOpen(false)}
            >
              Cancel
            </button>
            <button className="btn-primary" onClick={handleFileUpload}>
              Upload
            </button>
          </div>
        </div>
      </Modal>

      <Modal isOpen={isStMsgOpen} onClose={() => setIsStMsgOpen(false)}>
        <MessageSender onSend={(details) => handleSendMessage(details)} />
      </Modal>

      {permissions?.can_read ? (
        <>
          {isLoading && <Loader />}

          {/* Page Header with Status Indicators */}
          <div className="page-header">
            <div className="breadcrumb">
              <span
                onClick={() =>
                  navigate(
                    is_senior
                      ? "/admin/seniorStudents"
                      : "/admin/juniorStudents"
                  )
                }
              >
                {is_senior ? "Senior Students" : "Junior Students"}
              </span>
              <span> / </span>
              <span>Student Details</span>
            </div>

            <div className="header-actions">
              <button
                className="btn-secondary"
                onClick={() => setIsEditMode(!isEditMode)}
                disabled={cancelled || !permissions?.can_update}
              >
                {isEditMode ? "Cancel Edit" : "Edit Details"}
              </button>
              {isEditMode && (
                <button className="btn-primary" onClick={handleSubmit}>
                  Save Changes
                </button>
              )}
            </div>
          </div>

          {/* Status Banners */}
          {!cancelled && hostel && !bed_id && (
            <div className="status-banner warning">
              <FaExclamationTriangle />
              <span>
                Hostel not allocated. Please assign hostel in the section below.
              </span>
            </div>
          )}

          {cancelled && (
            <div className="status-banner danger">
              <FaBan />
              <div>
                <strong>Admission Cancelled</strong>
                <p>Cancellation Ticket: cancel_{roll_no}</p>
                <p>Cancelled on: {formatDateOnly(cancellationTime)}</p>
              </div>
            </div>
          )}

          {/* Student Profile Card */}
          <div className="profile-card">
            <div className="profile-header">
              <div className="profile-photo">
                <img
                  src={
                    photo_url ||
                    "https://erpresources.s3.ap-south-1.amazonaws.com/profile.png"
                  }
                  alt={`${firstname} ${lastname}`}
                />
                {isEditMode && (
                  <div className="photo-actions">
                    <button onClick={() => setIsImageModalOpen(true)}>
                      <FaCamera /> Change
                    </button>
                    {photo_url && (
                      <button onClick={handleDownloadPhoto}>
                        <FaDownload /> Download
                      </button>
                    )}
                  </div>
                )}
              </div>

              <div className="profile-info">
                <h2>{`${firstname} ${middlename} ${lastname}`}</h2>
                <hr />
                <div className="info">
                  <div className="key-details">
                    <div className="detail-item">
                      <FaIdCard />
                      <span>
                        Roll No: <strong>{rollnumber}</strong>
                      </span>
                    </div>
                    <div className="detail-item">
                      <FaBuilding />
                      <span>
                        Branch: <strong>{branch}</strong>
                      </span>
                    </div>
                    <div
                      className="detail-item"
                      style={{ cursor: "pointer", textDecoration: "underline" }}
                    >
                      <FaIdCard />
                      <span
                        onClick={() =>
                          navigate(
                            is_senior
                              ? `/admin/scholarship/${prev_roll_no}`
                              : `/admin/entrance/${prev_roll_no}`
                          )
                        }
                      >
                        Registration No: <strong>{prev_roll_no}</strong>
                      </span>
                    </div>
                    {/* <div className="detail-item">
                    <FaEnvelope />
                    <span>{email}</span>
                  </div>
                  <div className="detail-item">
                    <FaPhone />
                    <span>{whatsapp}</span>
                  </div>
                  <div className="detail-item">
                    <FaGraduationCap />
                    <span>
                      Class: <strong>{admclass}</strong> | Division:{" "}
                      <strong>{division}</strong>
                    </span>
                  </div> */}
                  </div>
                  <div className="key-details">
                    {/* <div className="detail-item">
                    <FaIdCard />
                    <span>
                      Roll No: <strong>{rollnumber}</strong>
                    </span>
                  </div>
                  <div className="detail-item">
                    <FaBuilding />
                    <span>
                      Branch: <strong>{branch}</strong>
                    </span>
                  </div>
                  <div
                    className="detail-item"
                    style={{ cursor: "pointer", textDecoration: "underline" }}
                  >
                    <FaIdCard />
                    <span
                      onClick={() =>
                        navigate(
                          is_senior
                            ? `/admin/scholarship/${prev_roll_no}`
                            : `/admin/entrance/${prev_roll_no}`
                        )
                      }
                    >
                      Registration No: <strong>{prev_roll_no}</strong>
                    </span>
                  </div> */}
                    <div className="detail-item">
                      <FaEnvelope />
                      <span>{email}</span>
                    </div>
                    <div className="detail-item">
                      <FaPhone />
                      <span>{whatsapp}</span>
                    </div>
                    <div className="detail-item">
                      <FaGraduationCap />
                      <span>
                        Class: <strong>{admclass}</strong> | Division:{" "}
                        <strong>{division}</strong>
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="profile-actions">
                {permissions?.can_update && !cancelled ? (
                  <button className="btn-danger" onClick={cancelStudent}>
                    Cancel Admission
                  </button>
                ) : null}

                {permissions?.can_update && cancelled ? (
                  <button className="btn-success" onClick={reactivateStudent}>
                    Reactivate
                  </button>
                ) : null}

                {permissions?.can_delete && (
                  <button className="btn-danger-outline" onClick={handleDelete}>
                    Delete
                  </button>
                )}

                <button
                  className="btn-primary-outline"
                  onClick={() =>
                    navigate(`/admin/admission-form/${roll_no}`, {
                      state: {
                        is_senior: Number(admclass) < 10 ? false : true,
                        promotion: true,
                      },
                    })
                  }
                  disabled={cancelled || !permissions?.can_update}
                >
                  Promote
                </button>
              </div>
            </div>
          </div>

          {/* Main Content Tabs */}
          <div className="content-tabs">
            <Tabs defaultActiveKey="details">
              <Tab tabKey="details" title="Student Details">
                <div className="detail-sections">
                  {/* Personal Information */}
                  <InfoSection
                    title="Personal Information"
                    isEditMode={isEditMode}
                    onEdit={() => setIsEditMode(true)}
                  >
                    <div className="info-grid">
                      <InfoField
                        label="First Name"
                        value={firstname}
                        isEditMode={isEditMode}
                        error={errors.firstname}
                        onChange={setFirstname}
                      />
                      <InfoField
                        label="Middle Name"
                        value={middlename}
                        isEditMode={isEditMode}
                        error={errors.middlename}
                        onChange={setmiddlename}
                      />
                      <InfoField
                        label="Last Name"
                        value={lastname}
                        isEditMode={isEditMode}
                        error={errors.lastname}
                        onChange={setlastname}
                      />
                      <InfoField
                        label="Date of Birth"
                        value={dob}
                        type="date"
                        isEditMode={isEditMode}
                        error={errors.dob}
                        onChange={setdob}
                      />
                      <InfoField
                        label="Father's Name"
                        value={fathername}
                        isEditMode={isEditMode}
                        error={errors.fathername}
                        onChange={setfathername}
                      />
                      <InfoField
                        label="Mother's Name"
                        value={mothername}
                        isEditMode={isEditMode}
                        error={errors.mothername}
                        onChange={setmothername}
                      />
                      <InfoField
                        label="Gender"
                        value={gender}
                        type="select"
                        options={genders}
                        isEditMode={isEditMode}
                        error={errors.gender}
                        onChange={setgender}
                      />
                      <InfoField
                        label="Blood Group"
                        value={bloodgroup}
                        type="select"
                        options={bloodgroups}
                        isEditMode={isEditMode}
                        error={errors.bloodgroup}
                        onChange={setbloodgroup}
                      />
                      <InfoField
                        label="Stream"
                        value={stream}
                        type="select"
                        options={streams}
                        isEditMode={isEditMode}
                        error={errors.stream}
                        onChange={setstream}
                      />
                    </div>
                  </InfoSection>

                  {/* Contact Information */}
                  <InfoSection
                    title="Contact Information"
                    isEditMode={isEditMode}
                    onEdit={() => setIsEditMode(true)}
                  >
                    <div className="info-grid">
                      <InfoField
                        label="Email"
                        value={email}
                        isEditMode={isEditMode}
                        error={errors.email}
                        onChange={setemail}
                      />
                      <InfoField
                        label="WhatsApp No."
                        value={whatsapp}
                        isEditMode={isEditMode}
                        error={errors.whatsapp}
                        onChange={setwhatsapp}
                      />
                      <InfoField
                        label="Alternate No."
                        value={alternateNo}
                        isEditMode={isEditMode}
                        error={errors.alternateNo}
                        onChange={setAlternateNo}
                      />
                      <InfoField
                        label="Aadhar No."
                        value={aadhar}
                        isEditMode={isEditMode}
                        error={errors.aadhar}
                        onChange={setaadhar}
                      />
                    </div>
                  </InfoSection>

                  {/* Address */}
                  <InfoSection
                    title="Address"
                    isEditMode={isEditMode}
                    onEdit={() => setIsEditMode(true)}
                  >
                    <div className="info-grid">
                      <InfoField
                        label="Address"
                        value={address}
                        isEditMode={isEditMode}
                        error={errors.address}
                        onChange={setaddress}
                        fullWidth={true}
                      />
                      <InfoField
                        label="District"
                        value={district}
                        type="select"
                        options={districts}
                        isEditMode={isEditMode}
                        error={errors.district}
                        onChange={selectDistrict}
                      />
                      <InfoField
                        label="Taluka"
                        value={taluka}
                        type="select"
                        options={talukas}
                        isEditMode={isEditMode}
                        error={errors.taluka}
                        onChange={settaluka}
                      />
                      <InfoField
                        label="PIN Code"
                        value={pin}
                        isEditMode={isEditMode}
                        error={errors.pin}
                        onChange={setpin}
                      />
                    </div>
                  </InfoSection>

                  {/* Academic Information */}
                  <InfoSection
                    title="Academic Information"
                    isEditMode={isEditMode}
                    onEdit={() => setIsEditMode(true)}
                  >
                    <div className="info-grid">
                      <InfoField
                        label="Academic Year"
                        value={year_start}
                        type="select"
                        options={academic_years}
                        isEditMode={isEditMode}
                        error={errors.year_start}
                        onChange={setyear_start}
                      />
                      <InfoField
                        label="Class"
                        value={admclass}
                        type="object-select"
                        options={classes.map(({ class_name, id }) => ({
                          label: id,
                          value: class_name,
                        }))}
                        isEditMode={isEditMode}
                        error={errors.admclass}
                        onChange={setadmclass}
                        onIdChange={setSelectedClassId}
                      />
                      <InfoField
                        label="Division"
                        value={division}
                        type="object-select"
                        options={divisions.map(({ name, id }) => ({
                          label: id,
                          value: name,
                        }))}
                        isEditMode={isEditMode}
                        error={errors.division}
                        onChange={setDivision}
                        onIdChange={setSelectedDivisionId}
                      />
                      {is_senior && (
                        <>
                          <InfoField
                            label="Additional Subject?"
                            value={additional_subject ? "Yes" : "No"}
                            type="boolean-select"
                            isEditMode={isEditMode}
                            onChange={(val) =>
                              setadditional_subject(val === "Yes")
                            }
                          />
                          {additional_subject && (
                            <InfoField
                              label="Subject Name"
                              value={additional_subject_name}
                              type="select"
                              options={["I.T.", "Crop Science"]}
                              isEditMode={isEditMode}
                              onChange={setadditional_subject_name}
                            />
                          )}
                        </>
                      )}
                    </div>
                  </InfoSection>

                  {/* Hostel & Accommodation */}
                  <InfoSection
                    title="Hostel & Accommodation"
                    isEditMode={isEditMode}
                    onEdit={() => setIsEditMode(true)}
                  >
                    <div className="info-grid">
                      <InfoField
                        label="Hostel Required"
                        value={hostel ? "Yes" : "No"}
                        type="boolean-select"
                        isEditMode={isEditMode}
                        onChange={(val) => sethostel(val === "Yes")}
                      />

                      {hostel && (
                        <InfoField
                          label="Bed Type"
                          value={selectedBedCount}
                          type="select"
                          options={["4", "5", "6", "hostel"]}
                          isEditMode={isEditMode}
                          onChange={setSelectedBedCount}
                        />
                      )}
                    </div>

                    {hostel && bedDetails && (
                      <div className="bed-details-card">
                        <h4>Assigned Bed Details</h4>
                        <div className="bed-info-grid">
                          <div className="bed-info-item">
                            <span className="bed-info-label">Hostel</span>
                            <span className="bed-info-value">
                              {bedDetails.hostel_name}
                            </span>
                          </div>
                          <div className="bed-info-item">
                            <span className="bed-info-label">Floor</span>
                            <span className="bed-info-value">
                              {bedDetails.floor_number}
                            </span>
                          </div>
                          <div className="bed-info-item">
                            <span className="bed-info-label">Room</span>
                            <span className="bed-info-value">
                              {bedDetails.room_number}
                            </span>
                          </div>
                          <div className="bed-info-item">
                            <span className="bed-info-label">Bed</span>
                            <span className="bed-info-value">
                              {bedDetails.bed_number}
                            </span>
                          </div>
                        </div>

                        {isEditMode && (
                          <button
                            className="btn-danger-outline"
                            onClick={deallocateBedFromStudent}
                          >
                            Deallocate Bed
                          </button>
                        )}
                      </div>
                    )}
                  </InfoSection>

                  {/* Mess & Transport */}
                  <InfoSection
                    title="Mess & Transport"
                    isEditMode={isEditMode}
                    onEdit={() => setIsEditMode(true)}
                  >
                    <div className="services-container">
                      <div className="service-section">
                        <h4>Mess Facility</h4>
                        <div className="info-grid">
                          <InfoField
                            label="Mess Required"
                            value={messRequired ? "Yes" : "No"}
                            type="boolean-select"
                            isEditMode={isEditMode}
                            onChange={(val) => setMessRequired(val === "Yes")}
                          />

                          {messRequired && (
                            <>
                              <InfoField
                                label="Mess Type"
                                value={messPriceId}
                                type="custom-select"
                                options={messOptions}
                                isEditMode={isEditMode}
                                onChange={handleMessSelection}
                                fullWidth={true}
                              />
                              <InfoField
                                label="Duration (Months)"
                                value={messMonths}
                                type="number-select"
                                options={monthsOptions}
                                isEditMode={isEditMode}
                                onChange={setMessMonths}
                              />
                              <InfoField
                                label="Valid Until"
                                value={messValidityDate}
                                type="date"
                                isEditMode={isEditMode}
                                onChange={setMessValidityDate}
                              />
                            </>
                          )}
                        </div>
                      </div>

                      <div className="service-section">
                        <h4>Transport Facility</h4>
                        <div className="info-grid">
                          <InfoField
                            label="Transport Required"
                            value={transportRequired ? "Yes" : "No"}
                            type="boolean-select"
                            isEditMode={isEditMode}
                            onChange={(val) =>
                              setTransportRequired(val === "Yes")
                            }
                          />

                          {transportRequired && (
                            <>
                              <InfoField
                                label="Transport Type"
                                value={transportPriceId}
                                type="custom-select"
                                options={transportOptions}
                                isEditMode={isEditMode}
                                onChange={handleTransportSelection}
                                fullWidth={true}
                              />
                              <InfoField
                                label="Duration (Months)"
                                value={transportMonths}
                                type="number-select"
                                options={monthsOptions}
                                isEditMode={isEditMode}
                                onChange={setTransportMonths}
                              />
                              <InfoField
                                label="Valid Until"
                                value={transportValidityDate}
                                type="date"
                                isEditMode={isEditMode}
                                onChange={setTransportValidityDate}
                              />
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </InfoSection>
                </div>
              </Tab>

              <Tab tabKey="fees" title="Fees & Payments">
                <div className="fees-container">
                  <div className="fees-header">
                    <h3>Fee Details</h3>
                    {permissions?.can_update && !feeEdit ? (
                      <button
                        className="btn-secondary"
                        onClick={() => handleEditFees("Edit")}
                        disabled={cancelled}
                      >
                        Edit Fees
                      </button>
                    ) : (
                      feeEdit && (
                        <button
                          className="btn-secondary"
                          onClick={() => handleEditFees("Cancel")}
                        >
                          Cancel
                        </button>
                      )
                    )}
                  </div>

                  {feeEdit ? (
                    <div className="fee-edit-section">
                      {installments && installments.length > 0 ? (
                        <InstallmentsStudentDetailsEdit
                          session={year_start}
                          student_id={id}
                          student_roll_no={roll_no}
                          title="Class Fee Details"
                          fee={totalFees}
                          installments={installments}
                          setInstallments={setinstallments}
                          scholarshipAmount={scholarshipAmount}
                          additial_amount={additionalAmount}
                          finalFee={reducedTotal}
                          handleRefresh={handleRefresh}
                        />
                      ) : (
                        <div className="empty-state">
                          <FaExclamationCircle />
                          <p>
                            No fee structure found for the current selections.
                          </p>
                        </div>
                      )}
                    </div>
                  ) : (
                    <StudentFee
                      admin={true}
                      adminStudentDetails={{
                        total_fee: totalFees,
                        scholarship_amount: scholarshipAmount,
                        additial_amount: additionalAmount,
                        final_fee: reducedTotal,
                        roll_no: roll_no,
                        email: email,
                        whatsapp: whatsapp,
                      }}
                      cancelled={cancelled}
                      adminInstallments={originalInstallments}
                      handleRefresh={handleRefresh}
                    />
                  )}
                </div>
              </Tab>

              <Tab tabKey="hostel" title="Hostel Management">
                {hostel && !bed_id && !cancelled ? (
                  <div className="bed-assignment-container">
                    <h3>Available Beds</h3>
                    {availableBeds.length > 0 ? (
                      <div className="available-beds-grid">
                        {availableBeds.map((bed) => (
                          <div key={bed.bed_id} className="bed-card">
                            <div className="bed-card-header">
                              <h4>{bed.hostel_name}</h4>
                            </div>
                            <div className="bed-card-details">
                              <div className="bed-detail-item">
                                <span className="bed-detail-label">Floor:</span>
                                <span className="bed-detail-value">
                                  {bed.floor_number}
                                </span>
                              </div>
                              <div className="bed-detail-item">
                                <span className="bed-detail-label">Room:</span>
                                <span className="bed-detail-value">
                                  {bed.room_number}
                                </span>
                              </div>
                              <div className="bed-detail-item">
                                <span className="bed-detail-label">Bed:</span>
                                <span className="bed-detail-value">
                                  {bed.bed_number}
                                </span>
                              </div>
                            </div>
                            <div className="bed-card-actions">
                              <button
                                className="btn-primary"
                                onClick={() => handleAssignBed(bed.bed_id)}
                              >
                                Assign Bed
                              </button>
                            </div>
                          </div>
                        ))}
                      </div>
                    ) : (
                      <div className="empty-state">
                        <FaBed />
                        <p>No beds available matching the requirements</p>
                      </div>
                    )}
                  </div>
                ) : hostel && bedDetails ? (
                  <div className="assigned-bed-container">
                    <div className="assigned-bed-card">
                      <div className="assigned-bed-header">
                        <h3>Current Bed Assignment</h3>
                      </div>
                      <div className="assigned-bed-details">
                        <div className="assigned-bed-row">
                          <div className="assigned-bed-detail">
                            <span className="detail-label">Hostel</span>
                            <span className="detail-value">
                              {bedDetails.hostel_name}
                            </span>
                          </div>
                          <div className="assigned-bed-detail">
                            <span className="detail-label">Floor</span>
                            <span className="detail-value">
                              {bedDetails.floor_number}
                            </span>
                          </div>
                        </div>
                        <div className="assigned-bed-row">
                          <div className="assigned-bed-detail">
                            <span className="detail-label">Room</span>
                            <span className="detail-value">
                              {bedDetails.room_number}
                            </span>
                          </div>
                          <div className="assigned-bed-detail">
                            <span className="detail-label">Bed</span>
                            <span className="detail-value">
                              {bedDetails.bed_number}
                            </span>
                          </div>
                        </div>
                      </div>
                      {!cancelled && permissions?.can_update && (
                        <div className="assigned-bed-actions">
                          <button
                            className="btn-danger"
                            onClick={deallocateBedFromStudent}
                          >
                            Deallocate Bed
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                ) : (
                  <div className="empty-state">
                    <FaBed />
                    <p>Hostel not required for this student</p>
                  </div>
                )}
              </Tab>

              <Tab tabKey="leaves" title="Leaves">
                <div className="leaves-container">
                  <h3>Leave Applications</h3>
                  {leaves && leaves.length > 0 ? (
                    <LeavesList
                      leaveApplications={leaves}
                      cancel_allowed={false}
                    />
                  ) : (
                    <div className="empty-state">
                      <FaExclamationCircle />
                      <p>No leave applications found</p>
                    </div>
                  )}
                </div>
              </Tab>

              <Tab tabKey="inventory" title="Inventory">
                <div className="inventory-container">
                  <h3>Assigned Inventory</h3>
                  {userId ? (
                    <AssignedInventory
                      type="student"
                      admin={true}
                      id={userId}
                    />
                  ) : (
                    <div className="empty-state">
                      <FaExclamationCircle />
                      <p>Loading inventory data...</p>
                    </div>
                  )}
                </div>
              </Tab>

              <Tab tabKey="results" title="Results">
                <div className="results-container">
                  <h3>Academic Results</h3>
                  {userId ? (
                    <StudentResults is_admin={true} user={userId} />
                  ) : (
                    <div className="empty-state">
                      <FaExclamationCircle />
                      <p>Loading results data...</p>
                    </div>
                  )}
                </div>
              </Tab>

              <Tab tabKey="messages" title="Messages">
                <div className="messages-container">
                  <div className="messages-header">
                    <h3>Communication History</h3>
                    <button
                      className="btn-primary"
                      onClick={() => setIsStMsgOpen(true)}
                    >
                      Send New Message
                    </button>
                  </div>

                  <div className="messages-list">
                    {messages.length > 0 ? (
                      messages.map((msg) => (
                        <div className="message-card" key={msg.id}>
                          <div className="message-header">
                            <div className="message-sender">
                              {msg.user_name}
                            </div>
                            <div className="message-time">
                              {formatDateAndTime(msg.sent_at)}
                            </div>
                          </div>
                          <div className="message-content">{msg.message}</div>
                          {msg.sent_on_whatsapp && (
                            <div className="message-whatsapp-badge">
                              <FaWhatsapp /> Sent on WhatsApp
                            </div>
                          )}
                        </div>
                      ))
                    ) : (
                      <div className="empty-state">
                        <FaExclamationCircle />
                        <p>No messages yet</p>
                      </div>
                    )}
                  </div>
                </div>
              </Tab>
            </Tabs>
          </div>

          {error && (
            <div className="error-banner">
              <FaExclamationCircle />
              <span>{error}</span>
            </div>
          )}
        </>
      ) : (
        <div className="permission-denied">
          <FaLock />
          <h3>Access Denied</h3>
          <p>You don't have permission to view student details.</p>
        </div>
      )}
    </div>
  );
};

export default StudentDetails;
