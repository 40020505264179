import React, { useState, useEffect } from "react";
import "./Resources.scss";
import axios from "axios";

const BE_URL = "https://server.classerp.in";

const Resources = () => {
  const [documents, setDocuments] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [categoryFilter, setCategoryFilter] = useState("all");
  const [sortOption, setSortOption] = useState("nameAsc");

  useEffect(() => {
    fetchDocuments();
  }, []);

  const refreshToken = async () => {
    try {
      const tokens = JSON.parse(localStorage.getItem("ERPTokens"));
      const response = await axios.post(`${BE_URL}/refreshToken`, {
        token: tokens.refreshToken,
      });

      if (response.data) {
        localStorage.setItem("ERPTokens", JSON.stringify(response.data));
        return response.data.accessToken;
      }
    } catch (error) {
      console.error("Error refreshing token:", error);
      setError("Session expired. Please login again.");
      return null;
    }
  };

  const fetchDocuments = async () => {
    setLoading(true);
    setError("");

    try {
      const tokens = JSON.parse(localStorage.getItem("ERPTokens"));
      const response = await axios.get(`${BE_URL}/api/general/resources`, {
        headers: { Authorization: `Bearer ${tokens.accessToken}` },
      });

      setDocuments(response.data);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        const newAccessToken = await refreshToken();
        if (newAccessToken) {
          return fetchDocuments(); // Retry fetching documents with new token
        } else {
          // Redirect to login or show an error
          setError("Authentication failed. Please log in again.");
        }
      } else {
        setError(error.message || "Failed to fetch resources.");
      }
    } finally {
      setLoading(false);
    }
  };

  // Helper function to format file size
  const formatFileSize = (bytes) => {
    if (bytes < 1024) {
      return `${bytes} B`;
    } else if (bytes < 1024 * 1024) {
      return `${(bytes / 1024).toFixed(1)} KB`;
    } else {
      return `${(bytes / (1024 * 1024)).toFixed(1)} MB`;
    }
  };

  // Function to determine the file type icon
  const getFileTypeIcon = (filename) => {
    const extension = filename.split(".").pop().toLowerCase();

    const iconMap = {
      pdf: "pdf-icon",
      doc: "word-icon",
      docx: "word-icon",
      xls: "excel-icon",
      xlsx: "excel-icon",
      ppt: "powerpoint-icon",
      pptx: "powerpoint-icon",
      jpg: "image-icon",
      jpeg: "image-icon",
      png: "image-icon",
      gif: "image-icon",
      txt: "text-icon",
      zip: "archive-icon",
      rar: "archive-icon",
    };

    return iconMap[extension] || "generic-file-icon";
  };

  // Function to get document category based on filename or extension
  const getDocumentCategory = (filename) => {
    const extension = filename.split(".").pop().toLowerCase();
    const lowerFilename = filename.toLowerCase();

    if (["pdf", "doc", "docx", "txt"].includes(extension)) {
      return "documents";
    } else if (["jpg", "jpeg", "png", "gif", "svg"].includes(extension)) {
      return "images";
    } else if (["xls", "xlsx", "csv"].includes(extension)) {
      return "spreadsheets";
    } else if (["ppt", "pptx"].includes(extension)) {
      return "presentations";
    } else if (["zip", "rar", "7z"].includes(extension)) {
      return "archives";
    } else if (
      lowerFilename.includes("syllabus") ||
      lowerFilename.includes("course")
    ) {
      return "academic";
    } else {
      return "other";
    }
  };

  // Function to get document date from the created_at field
  const getDocumentDate = (doc) => {
    if (!doc.created_at) return "Unknown date";

    const date = new Date(doc.created_at);
    return date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "short",
      day: "numeric",
    });
  };

  // Filter and sort documents
  const processedDocuments = React.useMemo(() => {
    // First, filter by search term and category
    let filtered = documents.filter((doc) => {
      const matchesSearch = doc.name
        .toLowerCase()
        .includes(searchTerm.toLowerCase());
      const category = getDocumentCategory(doc.name);
      const matchesCategory =
        categoryFilter === "all" || category === categoryFilter;

      return matchesSearch && matchesCategory;
    });

    // Then, sort the filtered documents
    return filtered.sort((a, b) => {
      switch (sortOption) {
        case "nameAsc":
          return a.name.localeCompare(b.name);
        case "nameDesc":
          return b.name.localeCompare(a.name);
        case "dateAsc":
          return new Date(a.created_at || 0) - new Date(b.created_at || 0);
        case "dateDesc":
          return new Date(b.created_at || 0) - new Date(a.created_at || 0);
        case "sizeAsc":
          return (a.size_bytes || 0) - (b.size_bytes || 0);
        case "sizeDesc":
          return (b.size_bytes || 0) - (a.size_bytes || 0);
        default:
          return 0;
      }
    });
  }, [documents, searchTerm, categoryFilter, sortOption]);

  // Clear search term
  const handleClearSearch = () => {
    setSearchTerm("");
  };

  // Handle filter change
  const handleFilterChange = (e) => {
    setCategoryFilter(e.target.value);
  };

  // Handle sort change
  const handleSortChange = (e) => {
    setSortOption(e.target.value);
  };

  return (
    <div className="resources-container">
      <div className="resources-header">
        <h1 className="resources-title">Resources</h1>
        <p className="resources-subtitle">
          Access and download educational materials and documents
        </p>
      </div>

      <div className="resources-toolbar">
        <div className="search-bar">
          <span className="search-icon"></span>
          <input
            type="text"
            placeholder="Search by name..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          {searchTerm && (
            <button className="clear-search" onClick={handleClearSearch}>
              ×
            </button>
          )}
        </div>

        <div className="filters">
          <div className="filter-group">
            <label htmlFor="categoryFilter">Category:</label>
            <select
              id="categoryFilter"
              value={categoryFilter}
              onChange={handleFilterChange}
            >
              <option value="all">All Categories</option>
              <option value="documents">Documents</option>
              <option value="images">Images</option>
              <option value="spreadsheets">Spreadsheets</option>
              <option value="presentations">Presentations</option>
              <option value="archives">Archives</option>
              <option value="academic">Academic</option>
              <option value="other">Other</option>
            </select>
          </div>

          <div className="filter-group">
            <label htmlFor="sortOption">Sort By:</label>
            <select
              id="sortOption"
              value={sortOption}
              onChange={handleSortChange}
            >
              <option value="nameAsc">Name (A-Z)</option>
              <option value="nameDesc">Name (Z-A)</option>
              <option value="dateDesc">Date (Newest)</option>
              <option value="dateAsc">Date (Oldest)</option>
              <option value="sizeDesc">Size (Largest)</option>
              <option value="sizeAsc">Size (Smallest)</option>
            </select>
          </div>
        </div>
      </div>

      <div className="resources-content">
        {loading ? (
          <div className="resources-loading">
            <div className="loading-spinner"></div>
            <p>Loading resources...</p>
          </div>
        ) : error ? (
          <div className="resources-error">
            <div className="error-icon"></div>
            <p>{error}</p>
            <button className="retry-button" onClick={fetchDocuments}>
              Try Again
            </button>
          </div>
        ) : processedDocuments.length === 0 ? (
          <div className="resources-empty">
            <div className="empty-icon"></div>
            <p>No resources match your search criteria</p>
            {searchTerm && (
              <button
                className="clear-search-button"
                onClick={handleClearSearch}
              >
                Clear Search
              </button>
            )}
          </div>
        ) : (
          <div className="resources-grid">
            {processedDocuments.map((doc) => (
              <div key={doc.id} className="resource-card">
                <div
                  className={`resource-icon ${getFileTypeIcon(doc.name)}`}
                ></div>
                <div className="resource-details">
                  <h3 className="resource-name" title={doc.name}>
                    {doc.name}
                  </h3>
                  <div className="resource-meta">
                    <span className="resource-size">
                      {formatFileSize(doc.size_bytes || 0)}
                    </span>
                    <span className="resource-date">
                      {getDocumentDate(doc)}
                    </span>
                  </div>
                </div>
                <a
                  href={doc.s3_url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="download-button"
                  title={`Download ${doc.name}`}
                >
                  <span className="download-icon"></span>
                  <span className="download-text">Download</span>
                </a>
              </div>
            ))}
          </div>
        )}
      </div>

      <div className="resources-footer">
        <p>
          {processedDocuments.length}{" "}
          {processedDocuments.length === 1 ? "resource" : "resources"} available
        </p>
      </div>
    </div>
  );
};

export default Resources;
