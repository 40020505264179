import React, { useState, useEffect, useRef } from "react";
import { usePDF } from "react-to-pdf";
import axios from "axios";
import {
  FaFileInvoiceDollar,
  FaMoneyBillWave,
  FaMedal,
  FaReceipt,
  FaCalendarAlt,
  FaClock,
  FaCheckCircle,
  FaTimesCircle,
  FaDownload,
  FaChevronDown,
  FaChevronUp,
  FaLink,
  FaTimes,
  FaExclamationTriangle,
} from "react-icons/fa";
import { MdPayment, MdDiscount } from "react-icons/md";

import logo from "../../assets/images/logo.png";
import logoCIS from "../../assets/images/logoCIS.jpg";
import "./StudentFee.scss";
import Button from "../Button/Button";
import Loader from "../Loader/Loader";
import Modal from "../Modal/Modal";
import ResponseModal from "../ResponseModal/ResponseModal";

// const BE_URL = "http://localhost:8000";
const BE_URL = "https://server.classerp.in";

const StudentFee = ({
  admin = false,
  handleRefresh,
  adminStudentDetails = {},
  adminInstallments = [],
  cancelled,
}) => {
  const [details, setDetails] = useState({
    studentDetails: {},
    installments: [],
  });

  const [expireDays, setexpireDays] = useState(null);
  const [email, setemail] = useState(null);
  const [instId, setinstId] = useState(null);
  const [whatsapp, setwhatsapp] = useState(null);
  const [paymentInstallmentId, setpaymentInstallmentId] = useState(null);
  const [paymentAmount, setpaymentAmount] = useState(null);
  const [paymentName, setpaymentName] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isPaymentOpen, setisPaymentOpen] = useState(false);
  const [isPaymentLinkOpen, setisPaymentLinkOpen] = useState(false);
  const [error, setError] = useState("");
  const [receiptDetails, setreceiptDetails] = useState(null);

  const [msg, setmsg] = useState(null);
  const [responseType, setresponseType] = useState(null);
  const [isResponseOpen, setisResponseOpen] = useState(false);

  const [isReceiptOpen, setisReceiptOpen] = useState(false);
  const [isLinksOpen, setisLinksOpen] = useState(false);

  const receiptRef = useRef(null);
  const { toPDF, targetRef } = usePDF({ filename: "receipt.pdf" });

  useEffect(() => {
    if (!admin) {
      fetchDetails();
    }
  }, []);

  const fetchDetails = async () => {
    setIsLoading(true);
    try {
      const tokens = JSON.parse(localStorage.getItem("ERPTokens"));
      const response = await axios.get(`${BE_URL}/api/general/studentfee`, {
        headers: { Authorization: `Bearer ${tokens.accessToken}` },
      });
      setDetails(response.data);
      setIsLoading(false);
    } catch (err) {
      setError("Error fetching data. Please try again later.");
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (admin) {
      setDetails({
        studentDetails: adminStudentDetails,
        installments: adminInstallments,
      });
    }
  }, [admin, adminStudentDetails, adminInstallments]);

  const toggleInstallmentDetails = (index) => {
    setDetails((prevState) => ({
      ...prevState,
      installments: prevState.installments.map((inst, i) => {
        if (i === index) {
          return { ...inst, isOpen: !inst.isOpen };
        }
        return inst;
      }),
    }));
  };

  const handlePayment = async (installment) => {
    setIsLoading(true);
    try {
      console.log("student details : ", details.studentDetails);
      const tokens = JSON.parse(localStorage.getItem("ERPTokens"));

      // Step 1: Initiate payment on the backend (create order)
      const response = await axios.post(
        `${BE_URL}/api/payment/initiate`,
        {
          installmentId: installment.id,
          roll_no: details.studentDetails.roll_no,
          amount: installment.final_fee,
        },
        {
          headers: { Authorization: `Bearer ${tokens.accessToken}` },
        }
      );

      setIsLoading(false);

      if (response.data.status === "success") {
        // Step 2: Redirect to Razorpay Hosted Checkout
        const { order_id, amount, currency, studentDetails } = response.data;
        console.log(response.data);

        // Create and submit a form for Razorpay Hosted Checkout
        const form = document.createElement("form");
        form.method = "POST";
        form.action = "https://api.razorpay.com/v1/checkout/embedded";

        // Helper function to add hidden inputs to the form
        const addHiddenInput = (name, value) => {
          const input = document.createElement("input");
          input.type = "hidden";
          input.name = name;
          input.value = value;
          form.appendChild(input);
        };

        // Add necessary fields to the form

        if (installment.account === "CCF" || installment.account === "CIS") {
          addHiddenInput("key_id", process.env.REACT_APP_RAZORPAY_KEY_ID_CCF);
          addHiddenInput("name", "Chaitanya's Charitable Foundation.");
        } else if (
          installment.account === "KCEPL" ||
          installment.account === "CIS"
        ) {
          addHiddenInput("key_id", process.env.REACT_APP_RAZORPAY_KEY_ID);
          addHiddenInput("name", "Krishna Chaitanya Educare Pvt. Ltd.");
        }

        addHiddenInput("order_id", order_id);
        addHiddenInput("amount", amount);
        addHiddenInput("currency", currency);
        addHiddenInput("name", "Chaitanya's Charitable Foundation.");
        addHiddenInput("description", "Fee Payment");
        addHiddenInput(
          "image",
          "https://classerp.in/static/media/logo.6c36cc13ed9ba2894757.png"
        );
        addHiddenInput("prefill[name]", studentDetails.name);
        addHiddenInput("prefill[email]", studentDetails.email);
        addHiddenInput("prefill[contact]", studentDetails.phone);
        addHiddenInput("callback_url", `${BE_URL}/api/payment/callback`);
        // addHiddenInput("callback_url", `https://classerp.in/payment-success`);
        addHiddenInput("cancel_url", `${BE_URL}/payment/cancel`);

        // Append the form to the body and submit it
        document.body.appendChild(form);
        form.submit();
      } else {
        console.error("Failed to initiate payment:", response.data);
        alert("Failed to initiate payment. Please try again.");
      }
    } catch (error) {
      setIsLoading(false);
      console.error("Error initiating payment:", error);
      alert("Error initiating payment. Please try again.");
    }
  };

  const initiateLink = async (id) => {
    window.scrollTo(0, 0);
    setisLinksOpen(true);
    setinstId(id);
    setemail(details.studentDetails.email);
    setwhatsapp(details.studentDetails.whatsapp);
    setexpireDays(14);
  };

  const handleCreateLink = async () => {
    setIsLoading(true);
    try {
      const tokens = JSON.parse(localStorage.getItem("ERPTokens"));

      // Step 1: Initiate payment on the backend (create order)
      const response = await axios.post(
        `${BE_URL}/api/payment/createLink`,
        {
          installmentId: instId,
          roll_no: details.studentDetails.roll_no,
          expireDays,
          email,
          whatsapp,
        },
        {
          headers: { Authorization: `Bearer ${tokens.accessToken}` },
        }
      );

      setisLinksOpen(false);
      setIsLoading(false);

      if (response.data.status === "success") {
        handleRefresh();
        alert("Payment Link Created!");
      } else {
        console.error("Failed to create Payment Link", response.data);
        alert("Failed to create Payment Link. Please try again.");
      }
    } catch (error) {
      setIsLoading(false);
      console.error("Error initiating payment:", error);
      alert("Error initiating payment. Please try again.");
    }
  };

  const handleCancelLink = async (installmentId, payment_link) => {
    setIsLoading(true);

    try {
      const tokens = JSON.parse(localStorage.getItem("ERPTokens"));

      // Step 1: Initiate payment on the backend (create order)
      const response = await axios.post(
        `${BE_URL}/api/payment/cancelLink`,
        {
          installmentId,
          payment_link,
        },
        {
          headers: { Authorization: `Bearer ${tokens.accessToken}` },
        }
      );

      setIsLoading(false);

      if (response.data.status === "Cancelled") {
        handleRefresh();
        alert("Payment Link Cancelled!");
      } else {
        console.error("Failed to cancel Payment Link", response.data);
        alert("Failed to cancel Payment Link. Please try again.");
      }
    } catch (error) {
      setIsLoading(false);
      console.error("Error initiating payment:", error);
      alert("Error initiating payment. Please try again.");
    }
  };

  const checkPrevious = (index) => {
    if (index === 1) return true;

    console.log(index);

    console.log(details.installments);

    const prevInst = details.installments.find(
      (inst) => inst.installment_number === index - 1
    );
    if (prevInst.status === "Paid") return true;
    else return false;
  };

  const adminPayment = (id, amount, name) => {
    window.scrollTo(0, 0);
    setpaymentInstallmentId(id);
    setpaymentAmount(amount);
    setpaymentName(name);
    setisPaymentOpen(true);
  };

  //Receipt related code.
  const getReceiptDetails = async (installmentId) => {
    setIsLoading(true);
    window.scrollTo(0, 0);
    try {
      const tokens = JSON.parse(localStorage.getItem("ERPTokens"));
      const response = await axios.get(
        `${BE_URL}/api/receipt/${installmentId}`,
        {
          headers: { Authorization: `Bearer ${tokens.accessToken}` },
        }
      );
      setreceiptDetails(response.data);

      setIsLoading(false);
    } catch (err) {
      setError("Error fetching receipt data. Please try again later.");
      setIsLoading(false);
    }
  };

  const closeReceiptModal = () => {
    setreceiptDetails(null);
    setisReceiptOpen(false);
  };

  useEffect(() => {
    if (receiptDetails) {
      setisReceiptOpen(true);
    }
  }, [receiptDetails]);

  const renderReceiptComponent = (receiptDetails) => {
    const { installmentResult } = receiptDetails;
    const { account } = installmentResult;
    if (account === "KCEPL") {
      return (
        <div ref={targetRef}>
          <ReceiptKCEPL details={receiptDetails} />
        </div>
      );
    }
    if (account === "CIS") {
      return (
        <div ref={targetRef}>
          <ReceiptCIS details={receiptDetails} />
        </div>
      );
    }
    if (account === "CCF") {
      return (
        <div ref={targetRef}>
          <ReceiptCCF details={receiptDetails} />
        </div>
      );
    }
    return <div>No receipt type found.</div>;
  };

  return (
    <div className="student-fee">
      {/* Create Link Modal */}
      <Modal isOpen={isLinksOpen} onClose={() => setisLinksOpen(false)}>
        <div className="link-modal">
          <div className="modal-header">
            <h3>
              <FaLink /> Create Payment Link
            </h3>
            <button className="close-btn" onClick={() => setisLinksOpen(false)}>
              <FaTimes />
            </button>
          </div>
          <CreateLink
            email={email}
            setemail={setemail}
            whatsapp={whatsapp}
            setwhatsapp={setwhatsapp}
            expireDays={expireDays}
            isLoading={isLoading}
            setexpireDays={setexpireDays}
            handleCreateLink={handleCreateLink}
          />
        </div>
      </Modal>

      {/* Receipt Modal */}
      <Modal isOpen={isReceiptOpen} onClose={() => closeReceiptModal()}>
        <div className="receipt-modal">
          <div className="modal-header">
            <h3>
              <FaReceipt /> Payment Receipt
            </h3>
            <div className="modal-actions">
              <button className="download-btn" onClick={() => toPDF()}>
                <FaDownload /> Download Receipt
              </button>
              <button className="close-btn" onClick={() => closeReceiptModal()}>
                <FaTimes />
              </button>
            </div>
          </div>
          <div className="receipt-content" ref={receiptRef}>
            {receiptDetails && renderReceiptComponent(receiptDetails)}
          </div>
        </div>
      </Modal>

      {/* Payment Modal */}
      <Modal isOpen={isPaymentOpen} onClose={() => setisPaymentOpen(false)}>
        <div className="payment-modal">
          <div className="modal-header">
            <h3>
              <MdPayment /> Accept Payment
            </h3>
            <button
              className="close-btn"
              onClick={() => setisPaymentOpen(false)}
            >
              <FaTimes />
            </button>
          </div>
          <AcceptPayment
            installmentId={paymentInstallmentId}
            onClose={() => setisPaymentOpen(false)}
            handleRefresh={handleRefresh}
            amount={paymentAmount}
            name={paymentName}
          />
        </div>
      </Modal>

      {/* Response Modal */}
      <Modal isOpen={isResponseOpen} onClose={() => setisResponseOpen(false)}>
        <ResponseModal
          type={responseType}
          msg={msg}
          onClick={() => setisResponseOpen(false)}
        />
      </Modal>

      {/* Loading Overlay */}
      {isLoading && <Loader />}

      {/* Error Display */}
      {error && (
        <div className="error-message">
          <FaExclamationTriangle /> {error}
        </div>
      )}

      {/* Fee Summary Section */}
      <div className="fee-summary">
        <div className="section-header">
          <FaFileInvoiceDollar className="section-icon" />
          <h2>Fee Summary</h2>
        </div>

        <div className="summary-cards">
          <div className="summary-card total-fee">
            <div className="card-icon">
              <FaMoneyBillWave />
            </div>
            <div className="card-content">
              <h3>Total Fee</h3>
              <span className="amount">
                ₹ {details.studentDetails.total_fee || "0"}
              </span>
            </div>
          </div>

          <div className="summary-card scholarshipcard">
            <div className="card-icon">
              <FaMedal />
            </div>
            <div className="card-content">
              <h3>Scholarship Amount</h3>
              <span className="amount">
                ₹ {details.studentDetails.scholarship_amount || "0"}
              </span>
            </div>
          </div>

          <div className="summary-card discount">
            <div className="card-icon">
              <MdDiscount />
            </div>
            <div className="card-content">
              <h3>Additional Discount</h3>
              <span className="amount">
                ₹ {details.studentDetails.additial_amount || "0"}
              </span>
            </div>
          </div>
        </div>
      </div>

      {/* Installments Section */}
      <div className="installments-section">
        <div className="section-header">
          <MdPayment className="section-icon" />
          <h2>Installments</h2>
        </div>

        <div className="installments-container">
          {details.installments &&
            details.installments.map((installment, index) => (
              <>
                {installment.is_active && (
                  <div key={installment.id} className="installment-card">
                    <div
                      className={`installment-header ${
                        installment.status === "Paid" ? "paid" : "unpaid"
                      }`}
                      onClick={() => toggleInstallmentDetails(index)}
                    >
                      <div className="installment-info">
                        <div className="installment-name">
                          {installment.installment_name}
                        </div>
                        <div
                          className={`status-badge ${
                            installment.status === "Paid" ? "paid" : "unpaid"
                          }`}
                        >
                          {installment.status === "Paid" ? (
                            <>
                              <FaCheckCircle /> Paid
                            </>
                          ) : (
                            <>
                              <FaClock /> Unpaid
                            </>
                          )}
                        </div>
                      </div>
                      <div className="toggle-icon">
                        {installment.isOpen ? (
                          <FaChevronUp />
                        ) : (
                          <FaChevronDown />
                        )}
                      </div>
                    </div>

                    {installment.isOpen && (
                      <div className="installment-details">
                        <div className="details-grid">
                          <div className="detail-item">
                            <div className="detail-icon">
                              <FaCalendarAlt />
                            </div>
                            <div className="detail-content">
                              <span className="detail-label">Due Date</span>
                              <span className="detail-value">
                                {new Date(
                                  installment.due_date
                                ).toLocaleDateString("en-US", {
                                  year: "numeric",
                                  month: "short",
                                  day: "numeric",
                                })}
                              </span>
                            </div>
                          </div>

                          <div className="detail-item">
                            <div className="detail-icon">
                              <FaMoneyBillWave />
                            </div>
                            <div className="detail-content">
                              <span className="detail-label">Amount</span>
                              <span className="detail-value">
                                ₹ {installment.amount}
                              </span>
                            </div>
                          </div>

                          {installment.discount_amount ? (
                            <>
                              <div className="detail-item">
                                <div className="detail-icon">
                                  <MdDiscount />
                                </div>
                                <div className="detail-content">
                                  <span className="detail-label">
                                    Discount Name
                                  </span>
                                  <span className="detail-value">
                                    {installment.discount_name || "N/A"}
                                  </span>
                                </div>
                              </div>

                              <div className="detail-item">
                                <div className="detail-icon">
                                  <MdDiscount />
                                </div>
                                <div className="detail-content">
                                  <span className="detail-label">
                                    Discount Amount
                                  </span>
                                  <span className="detail-value">
                                    ₹ {installment.discount_amount || 0}
                                  </span>
                                </div>
                              </div>
                            </>
                          ) : null}

                          <div className="detail-item">
                            <div className="detail-icon">
                              <FaClock />
                            </div>
                            <div className="detail-content">
                              <span className="detail-label">Late Fees</span>
                              <span
                                className={`detail-value ${
                                  installment.late_fee > 0 ? "late-fee" : ""
                                }`}
                              >
                                ₹ {installment.late_fee}
                              </span>
                            </div>
                          </div>

                          <div className="detail-item final-fee">
                            <div className="detail-icon">
                              <FaMoneyBillWave />
                            </div>
                            <div className="detail-content">
                              <span className="detail-label">Final Fees</span>
                              <span className="detail-value">
                                ₹ {installment.final_fee}
                              </span>
                            </div>
                          </div>
                        </div>

                        <div className="installment-actions">
                          {installment.status === "UnPaid" &&
                            !admin &&
                            checkPrevious(installment.installment_number) && (
                              <button
                                className="btn-primary action-btn"
                                onClick={() => handlePayment(installment)}
                              >
                                <MdPayment /> Pay Now
                              </button>
                            )}

                          {installment.status === "UnPaid" &&
                            admin &&
                            !cancelled &&
                            checkPrevious(installment.installment_number) && (
                              <>
                                {installment.payment_link ? (
                                  <div className="payment-link-info">
                                    <div className="link-status">
                                      <FaLink /> Payment Link Generated
                                    </div>
                                    <div className="link-id">
                                      ID: {installment.payment_link}
                                    </div>
                                    <button
                                      className="btn-danger action-btn"
                                      onClick={() =>
                                        handleCancelLink(
                                          installment.id,
                                          installment.payment_link
                                        )
                                      }
                                    >
                                      <FaTimesCircle /> Cancel Link
                                    </button>
                                  </div>
                                ) : (
                                  <div className="admin-actions">
                                    <button
                                      className="btn-success action-btn"
                                      onClick={() =>
                                        initiateLink(installment.id)
                                      }
                                    >
                                      <FaLink /> Create Link
                                    </button>
                                    <button
                                      className="btn-primary action-btn"
                                      onClick={() =>
                                        adminPayment(
                                          installment.id,
                                          installment.final_fee,
                                          installment.installment_name
                                        )
                                      }
                                    >
                                      <MdPayment /> Accept Payment
                                    </button>
                                  </div>
                                )}
                              </>
                            )}

                          {installment.status === "Paid" && (
                            <button
                              className="btn-success action-btn"
                              onClick={() => getReceiptDetails(installment.id)}
                            >
                              <FaReceipt /> View Receipt
                            </button>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </>
            ))}
        </div>
      </div>
    </div>
  );
};

export default StudentFee;

const ReceiptCIS = ({ details }) => {
  const { installmentResult, components, paymentDetails } = details;
  const {
    first_name,
    middle_name,
    last_name,
    session,
    order_id,
    amount,
    late_fee,
    final_fee,
    adm_class,
    division,
    roll_no,
    installment_number,
    installment_name,
  } = installmentResult;

  const { created_at, payment_method, payment_id, processor_name } =
    paymentDetails;

  const formatDateAndTime = (timestamp) => {
    const date = new Date(timestamp);
    const formattedDate = date.toLocaleDateString();
    const formattedTime = date.toLocaleTimeString();
    return `${formattedDate} ${formattedTime}`;
  };

  return (
    <div className="receipt-cis">
      <div className="receipt-header">
        <div className="receipt-header-logo">
          <img src={logoCIS} alt="Logo" className="logo" />
        </div>
        <div className="receipt-header-text">
          <div className="receipt-header-text-sub">
            Chaitanya Charitable Foundation's
          </div>
          <div className="receipt-header-text-name">
            CHAITANYA'S INTERNATIONAL SCHOOL
          </div>
          {/* <div className="receipt-header-text-add">
            A/P SAWAL, TAL - BARAMATI, DIST - PUNE 413133.
          </div> */}
          <div className="receipt-header-text-add">
            Gate no 43, balgude phatta, Sawal
          </div>
          <div className="receipt-header-text-add">
            Bhigwan road, Baramati 413133.
          </div>
        </div>
      </div>
      <h4 style={{ textAlign: "center" }}>Payment Receipt</h4>
      <div className="receipt-section">
        <div className="receipt-section-title">Payment Details</div>
        <table className="receipt-table">
          <tbody>
            <tr>
              <th className="th25">Student Name:</th>
              <td className="td25">
                {`${first_name} ${middle_name} ${last_name}`}
              </td>
              <th className="th25">Roll No:</th>
              <td className="td25">{roll_no}</td>
            </tr>
            <tr>
              <th className="th25">Class:</th>
              <td className="td25">{`${adm_class} - ${division}`}</td>
              <th className="th25">Session:</th>
              <td className="td25">{session}</td>
            </tr>
            <tr>
              <th className="th25">Order ID:</th>
              <td className="td25">{order_id}</td>
              <th className="th25">Transaction No:</th>
              <td className="td25">{payment_id}</td>
            </tr>
            <tr>
              <th className="th25">Date:</th>
              <td className="td25">{formatDateAndTime(created_at)}</td>
              <th className="th25">Payment Method:</th>
              <td className="td25">{payment_method}</td>
            </tr>

            <tr>
              <th className="th25">Payment Accepted By:</th>
              <td className="td25">{processor_name ? processor_name : "-"}</td>
              <th className="th25">Installment:</th>
              <td className="td25">
                Installment No {installment_number} - {installment_name}
              </td>
            </tr>
            <tr>
              <th className="th25">Amount:</th>
              <td className="td25">₹{amount}</td>
              <th className="th25">Late Fee:</th>
              <td className="td25">₹{late_fee}</td>
            </tr>
            <tr>
              <th className="th50" colspan="2">
                Amount Paid:
              </th>
              <td className="td50" colspan="2">
                <strong> ₹{final_fee} </strong>
              </td>
            </tr>
          </tbody>
        </table>
        {components && components.length > 0 && (
          <div className="receipt-section">
            <div className="receipt-section-title">Payment Breakdown</div>
            <table className="receipt-table">
              <thead>
                <tr>
                  <th className="th50">Component</th>
                  <th className="th50">Amount</th>
                </tr>
              </thead>
              <tbody>
                {components.map((comp, index) => (
                  <tr key={index}>
                    <td className="th50">{comp.component_name}</td>
                    <td className="td50">₹{comp.amount}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
      <div class="receipt-compInfo">
        <p>UDISE No - 27250203806</p>
      </div>
      <div className="receipt-footer">
        <p>
          This is a system-generated receipt and does not require a signature.
        </p>
        <p>Thank you for your payment!</p>
      </div>
    </div>
  );
};

const ReceiptKCEPL = ({ details }) => {
  const { installmentResult, components, paymentDetails } = details;
  const {
    first_name,
    middle_name,
    last_name,
    session,
    order_id,
    amount,
    late_fee,
    final_fee,
    adm_class,
    division,

    roll_no,
    installment_number,
    installment_name,
  } = installmentResult;

  // Tax Calculations
  const taxRate = 0.09; // 9% for CGST and 9% for SGST
  const taxableValue = Number(amount) / (1 + 2 * taxRate);
  const cgst = taxableValue * taxRate;
  const sgst = taxableValue * taxRate;
  const totalTax = cgst + sgst;

  const { created_at, payment_method, payment_id, processor_name } =
    paymentDetails;

  const formatDateAndTime = (timestamp) => {
    const date = new Date(timestamp);
    const formattedDate = date.toLocaleDateString(); // e.g., "12/24/2023"
    const formattedTime = date.toLocaleTimeString(); // e.g., "12:32:35 AM"
    return `${formattedDate} ${formattedTime}`;
  };

  return (
    <div className="receipt-kcepl">
      <div className="receipt-header">
        <div className="receipt-header-logo">
          <img src={logo} alt="Logo" className="logo" />
        </div>
        <div className="receipt-header-text">
          <div className="receipt-header-text-name">
            Krishna Chaitanya Educare Private Ltd.
          </div>
          <div className="receipt-header-text-add">
            State: Maharashtra. Code: 27.
          </div>
        </div>
      </div>
      <hr />
      <h4 style={{ textAlign: "center" }}>Payment Receipt</h4>
      <br />
      <div className="receipt-section">
        <div className="receipt-section-title">Payment Details</div>
        <table className="receipt-table">
          <tbody>
            <tr>
              <th className="th25">Student Name:</th>
              <td className="td25">
                {`${first_name} ${middle_name} ${last_name}`}
              </td>
              <th className="th25">Roll No:</th>
              <td className="td25">{roll_no}</td>
            </tr>
            <tr>
              <th className="th25">Class:</th>
              <td className="td25">{`${adm_class} - ${division}`}</td>
              <th className="th25">Session:</th>
              <td className="td25">{session}</td>
            </tr>
            <tr>
              <th className="th25">Order ID:</th>
              <td className="td25">{order_id}</td>
              <th className="th25">Transaction No:</th>
              <td className="td25">{payment_id}</td>
            </tr>
            <tr>
              <th className="th25">Date:</th>
              <td className="td25">{formatDateAndTime(created_at)}</td>
              <th className="th25">Payment Method:</th>
              <td className="td25">{payment_method}</td>
            </tr>

            <tr>
              <th className="th25">Payment Accepted By:</th>
              <td className="td25">{processor_name ? processor_name : "-"}</td>
              <th className="th25">Installment:</th>
              <td className="td25">
                Installment No {installment_number} - {installment_name}
              </td>
            </tr>
            <tr>
              <th className="th25">Amount:</th>
              <td className="td25">₹{amount}</td>
              <th className="th25">Late Fee:</th>
              <td className="td25">₹{late_fee}</td>
            </tr>
            <tr>
              <th className="th50" colspan="2">
                Amount Paid:
              </th>
              <td className="td50" colspan="2">
                <strong> ₹{final_fee} </strong>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      {components && components.length > 0 && (
        <div className="receipt-section">
          <div className="receipt-section-title">Payment Breakdown</div>
          <table className="receipt-table">
            <thead>
              <tr>
                <th className="th50">Component</th>
                <th className="th50">Amount</th>
              </tr>
            </thead>
            <tbody>
              {components.map((comp, index) => (
                <tr key={index}>
                  <td className="th50">{comp.component_name}</td>
                  <td className="td50">₹{comp.amount}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
      <div className="receipt-section">
        <div className="receipt-section-title">Tax Details</div>

        <table className="receipt-table">
          <thead>
            <tr>
              <th className="th25">Taxable Value</th>
              <th className="th25">CGST (9%)</th>
              <th className="th25">SGST (9%)</th>
              <th className="th25">Total Tax</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="td25">₹{taxableValue.toFixed(2)}</td>
              <td className="td25">₹{cgst.toFixed(2)}</td>
              <td className="td25">₹{sgst.toFixed(2)}</td>
              <td className="td25">₹{(cgst + sgst).toFixed(2)}</td>
            </tr>
          </tbody>
        </table>

        <div class="receipt-compInfo">
          <p>HSN/SAC : 9241</p>
          <p>Company's GSTIN/UIN : 27AAICK1499A1ZJ</p>
          <p>Company's PAN : AAICK1499A </p>
        </div>

        <div className="receipt-footer">
          <p>
            This is a system-generated receipt and does not require a signature.
          </p>
          <p>Thank you for your payment!</p>
        </div>
      </div>
    </div>
  );
};

const ReceiptCCF = ({ details }) => {
  const { installmentResult, components, paymentDetails } = details;
  const {
    first_name,
    middle_name,
    last_name,
    session,
    order_id,
    adm_class,
    division,
    amount,
    late_fee,
    final_fee,
    roll_no,
    installment_number,
    installment_name,
    transaction_no,
  } = installmentResult;

  const { created_at, payment_method, processor_name } = paymentDetails;

  const formatDateAndTime = (timestamp) => {
    const date = new Date(timestamp);
    const formattedDate = date.toLocaleDateString(); // e.g., "12/24/2023"
    const formattedTime = date.toLocaleTimeString(); // e.g., "12:32:35 AM"
    return `${formattedDate} ${formattedTime}`;
  };

  return (
    <div className="receipt-ccf">
      <div className="receipt-header">
        <div className="receipt-header-logo">
          <img src={logoCIS} alt="Logo" className="logo" />
        </div>
        <div className="receipt-header-text">
          <div className="receipt-header-text-name">
            Chaitanya Charitable Foundation
          </div>
          {/* <div className="receipt-header-text-add">
            Chaitanya's Academy, Baramati
          </div> */}
          <div className="receipt-header-text-add">
            Gate no 43, balgude phatta, Sawal
          </div>
          <div className="receipt-header-text-add">
            Bhigwan road, Baramati 413133.
          </div>
        </div>
      </div>
      <hr />
      <h4 style={{ textAlign: "center" }}>Payment Receipt</h4>
      <div className="receipt-section">
        <div className="receipt-section-title">Payment Details</div>
        <table className="receipt-table">
          <tbody>
            <tr>
              <th className="th50">Student Name:</th>
              <td className="td50">{`${first_name} ${middle_name} ${last_name}`}</td>
            </tr>
            <tr>
              <th className="th50">Roll No:</th>
              <td className="td50">{roll_no}</td>
            </tr>
            <tr>
              <th className="th50">Class:</th>
              <td className="td50">{`${adm_class} - ${division}`}</td>
            </tr>
            <tr>
              <th className="th50">Session:</th>
              <td className="td50">{session}</td>
            </tr>
            <tr>
              <th className="th50">Order Id:</th>
              <td className="td50">{order_id}</td>
            </tr>
            <tr>
              <th className="th50">Transaction No:</th>
              <td className="td50">{transaction_no ? transaction_no : "-"}</td>
            </tr>
            <tr>
              <th className="th50">Date:</th>
              <td className="td50">{formatDateAndTime(created_at)}</td>
            </tr>
            <tr>
              <th className="th50">Payment Mode:</th>
              <td className="td50">{payment_method}</td>
            </tr>

            <tr>
              <th className="th50">Payment Accepted By:</th>
              <td className="td50">{processor_name ? processor_name : "-"}</td>
            </tr>
            <tr>
              <th className="th50">Installment:</th>
              <td className="td50">
                Installment No {installment_number} - {installment_name}
              </td>
            </tr>
            <tr>
              <th className="th50">Amount:</th>
              <td className="td50">₹{amount}</td>
            </tr>
            <tr>
              <th className="th50">Late Fees:</th>
              <td className="td50">₹{late_fee}</td>
            </tr>
            <tr>
              <th className="th50">Amount Paid:</th>
              <td className="td50">
                <strong> ₹{final_fee} </strong>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      {components && components.length > 0 && (
        <div className="receipt-section">
          <div className="receipt-section-title">Payment Breakdown</div>
          <table className="receipt-table">
            <thead>
              <tr>
                <th className="th50">Component</th>
                <th className="th50">Amount</th>
              </tr>
            </thead>
            <tbody>
              {components.map((comp, index) => (
                <tr key={index}>
                  <td className="th50">{comp.component_name}</td>
                  <td className="td50">₹{comp.amount}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
      <div className="receipt-footer">
        <p>
          This is a system-generated receipt and does not require a signature.
        </p>
        <p>Thank you for your payment!</p>
      </div>
    </div>
  );
};

const AcceptPayment = ({
  installmentId,
  amount,
  name,
  onClose,
  handleRefresh,
}) => {
  const [paymentMethod, setPaymentMethod] = useState("Bank Transfer");
  const [receiptNumber, setReceiptNumber] = useState("");
  const [transactionDate, setTransactionDate] = useState(
    new Date().toISOString().split("T")[0]
  );
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");

  const handlePaymentAcceptance = async () => {
    if (amount > 0 && (!paymentMethod || !receiptNumber)) {
      alert("Please fill in all fields.");
      return;
    }

    setIsLoading(true);

    try {
      const tokens = JSON.parse(localStorage.getItem("ERPTokens"));
      const response = await axios.post(
        `${BE_URL}/api/acceptPayment`,
        {
          installmentId,
          paymentMethod: amount > 0 ? paymentMethod : "Paid_By_Discount",
          transaction_date: transactionDate,
          transaction_no:
            amount > 0 ? receiptNumber : `Paid_By_Discount${installmentId}`,
        },
        {
          headers: { Authorization: `Bearer ${tokens.accessToken}` },
        }
      );

      if (response.data.status === "success") {
        alert("Payment accepted successfully.");
        handleRefresh();
        onClose(); // Close modal on success
      } else {
        setError("Failed to accept payment.");
      }
    } catch (error) {
      console.error("Error accepting payment:", error);
      alert("Error accepting payment. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="accept-payment">
      <div className="info">
        {name} - &#8377; {amount}{" "}
      </div>

      {amount > 0 && (
        <>
          <div className="form-group">
            <label htmlFor="payment-method">Payment Method</label>
            <select
              id="payment-method"
              value={paymentMethod}
              onChange={(e) => setPaymentMethod(e.target.value)}
            >
              <option value="">Select Method</option>
              <option value="Cash">Cash</option>
              <option value="Demand Draft">Demand Draft (DD)</option>
              <option value="Bank Transfer">Bank Transfer</option>
            </select>
          </div>
          <div className="form-group">
            <label htmlFor="receipt-number">Transaction Number</label>
            <input
              type="text"
              id="receipt-number"
              value={receiptNumber}
              onChange={(e) => setReceiptNumber(e.target.value)}
            />
          </div>
          <div className="form-group">
            <label htmlFor="receipt-number">Transaction Date</label>
            <input
              type="date"
              id="transaction-date"
              value={transactionDate}
              onChange={(e) => setTransactionDate(e.target.value)}
            />
          </div>
        </>
      )}

      <div className="action-buttons">
        <button onClick={handlePaymentAcceptance} disabled={isLoading}>
          {isLoading ? "Processing..." : "Accept Payment"}
        </button>
      </div>
    </div>
  );
};

const CreateLink = ({
  email,
  setemail,
  whatsapp,
  setwhatsapp,
  expireDays,
  setexpireDays,
  isLoading,
  handleCreateLink,
}) => {
  return (
    <div className="accept-payment">
      <>
        <div className="form-group">
          <label htmlFor="receipt-number">Email</label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={(e) => setemail(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label htmlFor="phone-number">Phone Number</label>
          <input
            type="text"
            id="phone-number"
            value={whatsapp}
            onChange={(e) => setwhatsapp(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label htmlFor="expire-days">Expires in Days</label>
          <input
            type="text"
            id="expire-days"
            value={expireDays}
            onChange={(e) => setexpireDays(e.target.value)}
          />
        </div>
      </>

      <div className="action-buttons">
        <button onClick={handleCreateLink} disabled={isLoading}>
          {isLoading ? "Processing..." : "Create Link"}
        </button>
      </div>
    </div>
  );
};
