import React, { useEffect, useState } from "react";
import "./AdminScholarshipList.scss"; // Importing CSS file
import axios from "axios";

import { utils, writeFile } from "xlsx";
import { DateRangePicker } from "rsuite";
import { Input, SelectPicker } from "rsuite";

import Button from "../../components/Button/Button";
import Loader from "../../components/Loader/Loader";
import EntranceTable from "../../components/EntranceTable/EntranceTable";
import { useSelector } from "react-redux";

// const BE_URL = "http://localhost:8000";
const BE_URL = "https://server.classerp.in";

const AdminScholarshipList = () => {
  //RBAC code block
  const allPermissions = useSelector((state) => state.permissions);
  const permissions = allPermissions
    .find((p) => p.name === "Admissions")
    ?.dropdowns.find((dd) => dd.name === "Scholarship List")?.permissions;
  //RBAC Code block ends.

  const [isLoading, setIsLoading] = useState(false);
  const [enquiries, setEnquiries] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [sortOrder, setSortOrder] = useState("asc"); // 'desc' or 'asc'
  const [dateRange, setDateRange] = useState([]);

  const [selectedClass, setSelectedClass] = useState("All Classes");

  const [hostelFilter, setHostelFilter] = useState("All");
  const [mathsMarksFilter, setMathsMarksFilter] = useState("All");
  const [scienceMarksFilter, setScienceMarksFilter] = useState("All");
  const [selectedSession, setSelectedSession] = useState("2025-2026");
  const [branch, setBranch] = useState("Baramati");

  const branches = ["Baramati", "Pune", "Nagar", "Ranchi", "All"];
  const hostelOptions = ["All", "Yes", "No"];
  const marksOptions = [
    "All",
    "Above 95",
    "Between 90 and 95",
    "Between 85 and 90",
    "Between 80 and 85",
    "Between 75 and 80",
    "Between 70 and 75",
  ];

  // Dropdown options
  const classOptions = [
    "All Classes",
    "Class 6th",
    "Class 7th",
    "Class 8th",
    "Class 9th",
    "Class 10th",
    "Class 11th",
    "Class 12th",
    "Neet Repeater",
    "JEE Repeater",
  ];

  const sessionOptions = ["2024-2025", "2025-2026", "2026-2027", "All"];

  const classData = classOptions.map((cls) => ({
    label: cls,
    value: cls,
    role: cls === "All Classes" ? "All" : "Class",
  }));

  const filterMarks = (marks, filter) => {
    switch (filter) {
      case "Above 95":
        return marks > 95;
      case "Between 90 and 95":
        return marks > 90 && marks <= 95;
      case "Between 85 and 90":
        return marks > 85 && marks <= 90;
      case "Between 80 and 85":
        return marks > 80 && marks <= 85;
      case "Between 75 and 80":
        return marks > 75 && marks <= 80;
      case "Between 70 and 75":
        return marks > 70 && marks <= 75;
      default:
        return true;
    }
  };

  const filteredEnquiries = enquiries.filter((enquiry) => {
    const enquiryDate = new Date(enquiry.timestamp);
    const [start, end] = dateRange;

    // Convert to lowercase for case-insensitive comparison
    const searchTermLower = searchTerm.toLowerCase();
    const isMatch =
      enquiry.first_name.toLowerCase().includes(searchTermLower) ||
      enquiry.last_name.toLowerCase().includes(searchTermLower) ||
      enquiry.email.toLowerCase().includes(searchTermLower) ||
      enquiry.roll_no.toLowerCase().includes(searchTermLower) ||
      enquiry.whatsapp.toLowerCase().includes(searchTermLower) ||
      enquiry.aadhar.toLowerCase().includes(searchTermLower);

    const isInClass =
      selectedClass === "All Classes" || enquiry.adm_class === selectedClass;

    const matchesHostel =
      hostelFilter === "All" || enquiry.hostel === hostelFilter;
    const matchesMathsMarks =
      mathsMarksFilter === "All" ||
      filterMarks(enquiry.prev_math, mathsMarksFilter);
    const matchesScienceMarks =
      scienceMarksFilter === "All" ||
      filterMarks(enquiry.prev_sci, scienceMarksFilter);

    return (
      (!start || enquiryDate >= start) &&
      (!end || enquiryDate <= end) &&
      isMatch &&
      isInClass &&
      matchesHostel &&
      matchesMathsMarks &&
      matchesScienceMarks
    );
  });

  // Handle class filter change
  const handleClassFilterChange = (value) => {
    // console.log(event);
    setSelectedClass(value);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value.toLowerCase());
  };

  const handleDateRangeChange = (item) => {
    // Check if the date range is cleared and set it to an empty array
    if (!item) {
      setDateRange([]);
    } else {
      setDateRange(item);
    }
  };

  const handleSendEmails = async (emails, subject, text) => {
    if (!emails || emails.length === 0) {
      alert("Please select at least one recipient.");
      return;
    }

    if (!subject.trim() || !text.trim()) {
      alert("Subject and email content cannot be empty.");
      return;
    }

    if (
      !window.confirm(
        "Are you sure you want to send this email to the selected recipients?"
      )
    ) {
      return;
    }

    setIsLoading(true);

    try {
      const tokens = JSON.parse(localStorage.getItem("ERPTokens"));

      // API call to send emails
      await axios.post(
        `${BE_URL}/sendInfoEmailsSch`,
        {
          emails, // Array of recipient email IDs
          subject,
          text,
        },
        {
          headers: {
            Authorization: `Bearer ${tokens.accessToken}`, // Attach the token
          },
        }
      );

      alert("Emails sent successfully.");
      setIsLoading(false);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // Token expired, refresh and retry
        const newTokens = await refreshToken();
        if (newTokens) {
          handleSendEmails(emails, subject, text); // Retry with refreshed tokens
        }
        setIsLoading(false);
      } else {
        console.error("Error sending emails:", error);
        alert("Failed to send emails. Please try again later.");
        setIsLoading(false);
      }
    }
  };

  const fetchEnquiries = async (selectedSession) => {
    setIsLoading(true);
    try {
      const tokens = JSON.parse(localStorage.getItem("ERPTokens"));
      let response = null;
      if (branch === "All" && selectedSession === "All") {
        response = await fetch(`${BE_URL}/getAllscholarships`, {
          headers: {
            Authorization: `Bearer ${tokens.accessToken}`,
          },
        });
      } else if (branch === "All") {
        response = await fetch(
          `${BE_URL}/getAllscholarships?session=${selectedSession}`,
          {
            headers: {
              Authorization: `Bearer ${tokens.accessToken}`,
            },
          }
        );
      } else if (selectedSession === "All") {
        response = await fetch(
          `${BE_URL}/getAllscholarships?branch=${branch}`,
          {
            headers: {
              Authorization: `Bearer ${tokens.accessToken}`,
            },
          }
        );
      } else {
        response = await fetch(
          `${BE_URL}/getAllscholarships?session=${selectedSession}&branch=${branch}`,
          {
            headers: {
              Authorization: `Bearer ${tokens.accessToken}`,
            },
          }
        );
      }

      if (!response.ok) {
        if (response.status === 403) {
          alert("Unauthorized Access");
        }
        // Handle token expiry
        if (response.status === 401) {
          const newTokens = await refreshToken();
          if (newTokens) {
            return fetchEnquiries(selectedSession); // Retry fetching enquiries
          }
        }
        throw new Error("Failed to fetch Scholarships forms");
      }

      const data = await response.json();
      // Sorting logic...
      setEnquiries(data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    setIsLoading(false);
  };

  const refreshToken = async () => {
    try {
      const tokens = JSON.parse(localStorage.getItem("ERPTokens"));
      // const response = await fetch("http://localhost:8000/refreshToken", {
      const response = await fetch("https://server.classerp.in/refreshToken", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ token: tokens.refreshToken }),
      });

      if (!response.ok) {
        throw new Error("Failed to refresh token");
      }

      const newTokens = await response.json();
      localStorage.setItem("ERPTokens", JSON.stringify(newTokens));
      return newTokens;
    } catch (error) {
      console.error("Error refreshing token:", error);
      alert("User loggod out. Please login again");
      // navigate("/login");
      return null;
    }
  };

  useEffect(() => {
    fetchEnquiries(selectedSession);
  }, [selectedSession, branch]);

  useEffect(() => {
    setIsLoading(true);
    if (sortOrder === "desc") {
      setEnquiries((prevEnquiries) =>
        [...prevEnquiries].sort(
          (a, b) => new Date(b.timestamp) - new Date(a.timestamp)
        )
      );
    } else {
      setEnquiries((prevEnquiries) =>
        [...prevEnquiries].sort(
          (a, b) => new Date(a.timestamp) - new Date(b.timestamp)
        )
      );
    }
    setIsLoading(false);
  }, [sortOrder]);

  const downloadExcel = () => {
    setIsLoading(true);
    const ws = utils.json_to_sheet(filteredEnquiries);
    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws, "Scholarships");

    // Generate Excel file and download
    writeFile(wb, "Scholarships.xlsx");
    setIsLoading(false);
  };

  return (
    <div className="slist">
      {isLoading && <Loader />}
      {permissions?.can_read ? (
        <>
          <div className="slist-filters">
            <div className="slist-header">
              {/* <div className="slist-heading">Scholarship List</div> */}
              {permissions.can_update && (
                <Button
                  // type={"minigreen"}
                  text={"Excel"}
                  onClick={downloadExcel}
                />
              )}
            </div>
            <div className="slist-filters-search">
              <div className="slist-filters-label">Search.</div>
              <Input
                placeholder="Search by first name, last name, email, roll no or Aadhar..."
                onChange={(value) =>
                  handleSearchChange({ target: { value: value.toLowerCase() } })
                }
                className="search-bar"
              />
            </div>
            <div className="slist-filters-datepicker">
              <div className="slist-filters-label">Select Date Range.</div>
              <DateRangePicker
                format="yyyy-MM-dd"
                onChange={handleDateRangeChange}
                value={dateRange}
              />
            </div>
            <div className="slist-filters-branch">
              <div className="slist-filters-label">Select Branch.</div>
              <SelectPicker
                data={branches.map((opt) => ({ label: opt, value: opt }))}
                onChange={setBranch}
                value={branch}
                cleanable={false}
                searchable={false}
                placeholder="Select Branch"
              />
            </div>
            <div className="slist-filters-class">
              <div className="slist-filters-label">Select Class.</div>
              <SelectPicker
                data={classData}
                onChange={handleClassFilterChange}
                value={selectedClass}
                cleanable={false}
                searchable={false}
                placeholder="Select Class"
              />
            </div>

            <div className="slist-filters-hostel">
              <div className="slist-filters-label">Session</div>
              <SelectPicker
                data={sessionOptions.map((opt) => ({ label: opt, value: opt }))}
                onChange={setSelectedSession}
                value={selectedSession}
                cleanable={false}
                searchable={false}
              />
            </div>
            <div className="slist-filters-hostel">
              <div className="slist-filters-label">Hostel</div>
              <SelectPicker
                data={hostelOptions.map((opt) => ({ label: opt, value: opt }))}
                onChange={setHostelFilter}
                value={hostelFilter}
                cleanable={false}
                searchable={false}
              />
            </div>

            <div className="slist-filters-mathsMarks">
              <div className="slist-filters-label">Maths Marks</div>
              <SelectPicker
                data={marksOptions.map((opt) => ({ label: opt, value: opt }))}
                onChange={setMathsMarksFilter}
                value={mathsMarksFilter}
                cleanable={false}
                searchable={false}
              />
            </div>

            <div className="slist-filters-scienceMarks">
              <div className="slist-filters-label">Science Marks</div>
              <SelectPicker
                data={marksOptions.map((opt) => ({ label: opt, value: opt }))}
                onChange={setScienceMarksFilter}
                value={scienceMarksFilter}
                cleanable={false}
                searchable={false}
              />
            </div>
          </div>
          {/* <div className="slist-filters2"></div> */}
          <div className="slist-table">
            <EntranceTable
              listname={`Scholarship List`}
              data={filteredEnquiries}
              onSendEmail={(emailData) => {
                console.log("Sending emails to:", emailData);
                handleSendEmails(
                  emailData.emails,
                  emailData.subject,
                  emailData.body
                );
              }}
              navigateBaseUrl={`/admin/scholarship`}
              setSortOrder={setSortOrder}
              sortOrder={sortOrder}
            />
            <div className="slist-table-footer">
              {filteredEnquiries.length} items found.
            </div>
          </div>
        </>
      ) : (
        ""
      )}{" "}
    </div>
  );
};

export default AdminScholarshipList;
