import React, { useState } from "react";
import "./HalfDetails.scss";
import Modal from "../Modal/Modal";
import ConfirmationModal from "../ConfirmationModal/ConfirmationModal";
import Button from "../Button/Button";
import deleteIcon from "../../assets/images/Delete.png";

const HalfDetails = ({
  id,
  name,
  handleDelete,
  handleEdit,
  children,
  can_delete,
  can_update,
}) => {
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [isAnimating, setIsAnimating] = useState(true);

  // Animation effect on mount
  React.useEffect(() => {
    const timer = setTimeout(() => {
      setIsAnimating(false);
    }, 300);

    return () => clearTimeout(timer);
  }, []);

  const handleSaveChanges = () => {
    handleEdit();
    setIsEditOpen(false);
  };

  const handleDeleteConfirm = () => {
    handleDelete(id);
    setIsDeleteOpen(false);
  };

  return (
    <div className={`half-details ${isAnimating ? "animate-in" : ""}`}>
      <Modal isOpen={isDeleteOpen} onClose={() => setIsDeleteOpen(false)}>
        <ConfirmationModal
          onConfirm={handleDeleteConfirm}
          onCancel={() => setIsDeleteOpen(false)}
          ques="Are you sure you want to delete?"
        />
      </Modal>

      <Modal isOpen={isEditOpen} onClose={() => setIsEditOpen(false)}>
        <ConfirmationModal
          onConfirm={handleSaveChanges}
          onCancel={() => setIsEditOpen(false)}
          ques="Are you sure you want to save the changes?"
        />
      </Modal>

      <div className="details-header">
        <div className="title-container">
          <h2 className="details-title">{name}</h2>
          {/* <div className="details-id">ID: {id}</div> */}
        </div>

        <div className="action-buttons">
          {can_delete && (
            <button
              className="delete-button"
              onClick={() => setIsDeleteOpen(true)}
              title="Delete"
            >
              <img src={deleteIcon} alt="Delete" />
              <span className="button-tooltip">Delete</span>
            </button>
          )}
        </div>
      </div>

      <div className="details-divider"></div>

      <div className="details-content">{children}</div>

      {can_update && (
        <div className="details-footer">
          <Button
            type="arrow"
            text="Save Changes"
            onClick={() => {
              window.scrollTo(0, 0);
              setIsEditOpen(true);
            }}
          />
        </div>
      )}
    </div>
  );
};

export default HalfDetails;

// import "./HalfDetails.scss";

// import deleteicon from "../../assets/images/Delete.png";

// import React, { useState } from "react";
// import Modal from "../Modal/Modal";
// import ConfirmationModal from "../ConfirmationModal/ConfirmationModal";
// import Button from "../Button/Button";

// const HalfDetails = ({
//   id,
//   name,
//   handleDelete,
//   handleEdit,
//   children,
//   can_delete,
//   can_update,
// }) => {
//   const [isDeleteOpen, setIsDeleteOpen] = useState(false);
//   const [isEditOpen, setIsEditOpen] = useState(false);

//   const handTest = () => {
//     handleEdit();
//     setIsEditOpen(false);
//   };
//   const handleDeleteHere = () => {
//     handleDelete(id);
//     setIsDeleteOpen(false);
//   };

//   return (
//     <div className="hdetails">
//       <Modal isOpen={isDeleteOpen} onClose={() => setIsDeleteOpen(false)}>
//         <ConfirmationModal
//           onConfirm={handleDeleteHere}
//           onCancel={() => setIsDeleteOpen(false)}
//           ques="Are you sure you want to delete?"
//         />
//       </Modal>
//       <Modal isOpen={isEditOpen} onClose={() => setIsEditOpen(false)}>
//         <ConfirmationModal
//           onConfirm={handTest}
//           onCancel={() => setIsEditOpen(false)}
//           ques="Are you sure you want to save the changes?"
//         />
//       </Modal>
//       <div className="hdetails-header">
//         <div className="hdetails-header-name">{name}</div>
//         {/* <Button text={`Delete`} type={`arrow`} /> */}
//         {can_delete && (
//           <img
//             src={deleteicon}
//             alt="Delete"
//             onClick={() => setIsDeleteOpen(true)}
//           />
//         )}
//       </div>
//       <div className="hdetails-contents">{children}</div>

//       {can_update && (
//         <div className="hdetails-footer">
//           <Button
//             type={`arrow`}
//             text={`Save Changes`}
//             onClick={() => {
//               window.scrollTo(0, 0);
//               setIsEditOpen(true);
//             }}
//           />
//         </div>
//       )}
//     </div>
//   );
// };

// export default HalfDetails;
