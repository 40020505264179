import "./AdmissionForm.scss";
import print from "../../assets/images/Print.png";

import data from "../../assets/data/districtData";

import React, { useEffect, useRef, useState } from "react";
import TextField from "../../components/TextField/TextField";
import DropDown from "../../components/DropDown/DropDown";
import DatePicker from "../../components/DatePicker/DatePicker";
import Button from "../../components/Button/Button";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import axios from "axios";
import Loader from "../../components/Loader/Loader";
import { jwtDecode } from "jwt-decode";
import { useSelector } from "react-redux";
import ObjectDropDown from "../../components/ObjectDropDown/ObjectDropDown";
import InstallmentsWithDiscountsDisplay from "../../components/InstallmentsWithDiscountsDisplay/InstallmentsWithDiscountsDisplay";
import CustomDropDown from "../../components/CustomDropDown/CustomDropDown";

// const BE_URL = "http://localhost:8000";
const BE_URL = "https://server.classerp.in";

const AdmissionForm = () => {
  // Hardcoded districts for Bihar and Jharkhand
  const biharJharkhandDistricts = [
    // Bihar Districts
    "Araria",
    "Arwal",
    "Aurangabad",
    "Banka",
    "Begusarai",
    "Bhagalpur",
    "Bhojpur",
    "Buxar",
    "Darbhanga",
    "East Champaran",
    "Gaya",
    "Gopalganj",
    "Jamui",
    "Jehanabad",
    "Kaimur",
    "Katihar",
    "Khagaria",
    "Kishanganj",
    "Lakhisarai",
    "Madhepura",
    "Madhubani",
    "Munger",
    "Muzaffarpur",
    "Nalanda",
    "Nawada",
    "Patna",
    "Purnia",
    "Rohtas",
    "Saharsa",
    "Samastipur",
    "Saran",
    "Sheikhpura",
    "Sheohar",
    "Sitamarhi",
    "Siwan",
    "Supaul",
    "Vaishali",
    "West Champaran",

    // Jharkhand Districts
    "Bokaro",
    "Chatra",
    "Deoghar",
    "Dhanbad",
    "Dumka",
    "East Singhbhum",
    "Garhwa",
    "Giridih",
    "Godda",
    "Gumla",
    "Hazaribagh",
    "Jamtara",
    "Khunti",
    "Koderma",
    "Latehar",
    "Lohardaga",
    "Pakur",
    "Palamu",
    "Ramgarh",
    "Ranchi",
    "Sahebganj",
    "Seraikela-Kharsawan",
    "Simdega",
    "West Singhbhum",
  ];

  const navigate = useNavigate();
  const printDivRef = useRef();
  const { roll_no } = useParams();

  const location = useLocation();
  const { is_senior, promotion } = location.state || {};
  console.log("Promition is ", promotion);

  //RBAC code block
  const allPermissions = useSelector((state) => state.permissions);
  let permissions = null;
  if (is_senior) {
    permissions = allPermissions
      .find((p) => p.name === "Students")
      ?.dropdowns.find((dd) => dd.name === "Senior Students")?.permissions;
  } else {
    permissions = allPermissions
      .find((p) => p.name === "Students")
      ?.dropdowns.find((dd) => dd.name === "Junior Students")?.permissions;
  }

  const tokens = JSON.parse(localStorage.getItem("ERPTokens"));
  const decodedToken = tokens ? jwtDecode(tokens.accessToken) : null;
  const roleId = decodedToken ? decodedToken.role_id : null;

  const [districts, setdistricts] = useState([]);
  const [talukas, settalukas] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  const [id, setid] = useState(null);
  const [branch, setbranch] = useState(null);
  const [caste, setcaste] = useState(null);
  const [stream, setstream] = useState(null);
  const [rollnumber, setrollnumber] = useState(null);
  const [firstname, setFirstname] = useState(null);
  const [middlename, setmiddlename] = useState(null);
  const [lastname, setlastname] = useState(null);
  const [dob, setdob] = useState(null);
  const [fathername, setfathername] = useState(null);
  const [mothername, setmothername] = useState(null);
  const [email, setemail] = useState(null);
  const [whatsapp, setwhatsapp] = useState(null);
  const [alternateNo, setAlternateNo] = useState(null);
  const [aadhar, setaadhar] = useState(null);
  const [gender, setgender] = useState(null);
  const [bloodgroup, setbloodgroup] = useState(null);
  const [parentOccupation, setparentOccupation] = useState(null);

  const [admclass, setadmclass] = useState(null);
  const [classes, setClasses] = useState([]);
  const [division, setDivision] = useState(null);
  const [divisions, setDivisions] = useState([]);
  const [year_start, setyear_start] = useState(null);
  const [year_end, setyear_end] = useState(null);

  const [selectedClassId, setSelectedClassId] = useState(null);
  const [selectedDivisionId, setSelectedDivisionId] = useState(null);
  const [selectedBedCount, setSelectedBedCount] = useState(null);
  const [classFee, setClassFee] = useState(0);
  const [hostelFee, setHostelFee] = useState(0);
  const [installments, setinstallments] = useState([]);

  const [hostel, sethostel] = useState(false);
  const [feeStructures, setfeeStructures] = useState([]);

  const [address, setaddress] = useState(null);
  const [district, setdistrict] = useState(null);
  const [taluka, settaluka] = useState(null);
  const [pin, setpin] = useState(null);
  const [photo_url, setphoto_url] = useState(null);

  const [fees, setfees] = useState(null);
  const [scholarshipAmount, setscholarshipAmount] = useState(0);
  const [additionalAmount, setAdditionalAmount] = useState(0);
  const [totalFees, setTotalFees] = useState(""); //Fees with everything added
  const [reducedTotal, setReducedTotal] = useState(0); //Fees after reducting scholarship and additional amount

  const [additional_subject_name, setadditional_subject_name] = useState(null);
  const [additional_subject, setadditional_subject] = useState(false);

  const [messRequired, setMessRequired] = useState(false);
  const [messFee, setmessFee] = useState(null);
  const [messMonths, setMessMonths] = useState(0);
  const [messValidityDate, setMessValidityDate] = useState(null);
  const [messPriceId, setmessPriceId] = useState(null);

  const [transportPriceId, settransportPriceId] = useState(null);
  const [transportRequired, setTransportRequired] = useState(false);
  const [transportMonths, setTransportMonths] = useState(0);
  const [transportFee, settransportFee] = useState(null);
  const [transportValidityDate, setTransportValidityDate] = useState(null);

  const [messOptions, setmessOptions] = useState([]);
  const [transportOptions, settransportOptions] = useState([]);
  const [selectedMessPrice, setSelectedMessPrice] = useState("");
  const [selectedTransportPrice, setSelectedTransportPrice] = useState("");

  const [error, setError] = useState(null);
  const [errors, setErrors] = useState({});

  const bloodgroups = ["A+", "A-", "B+", "B-", "AB+", "AB-", "O+", "O-"];
  const genders = ["Male", "Female"];

  const bool = ["Yes", "No"];
  const branches = ["Baramati", "Pune", "Nagar", "Ranchi"];
  const casteOptions = ["General/Open", "OBC", "SC", "ST", "EWS", "Others"];
  const streams = ["Engineering", "Medical"];

  const academic_years = [
    "2023-2024",
    "2024-2025",
    "2025-2026",
    "2026-2027",
    "2027-2028",
    "2028-2029",
    "2029-2030",
  ];

  useEffect(() => {
    if (!hostel) {
      setSelectedBedCount(null);
    }
  }, [hostel]);

  useEffect(() => {
    // Calculate mess and transport fees

    const messFees = messRequired ? messMonths * selectedMessPrice : 0;
    const transportFees = transportRequired
      ? transportMonths * selectedTransportPrice
      : 0;

    setmessFee(messFees);
    settransportFee(transportFees);

    // Calculate total fees
    const total =
      Number(classFee) + Number(hostelFee) + messFees + transportFees;
    setTotalFees(total);

    // Calculate reduced total
    const reduced =
      total -
      (scholarshipAmount ? Number(scholarshipAmount) : 0) -
      (additionalAmount ? Number(additionalAmount) : 0);
    setReducedTotal(reduced);
  }, [
    admclass,
    classFee,
    hostelFee,
    scholarshipAmount,
    additionalAmount,
    messRequired,
    messMonths,
    transportRequired,
    transportMonths,
    selectedMessPrice,
    selectedTransportPrice,
  ]);

  function toCamelCase(str) {
    return str.replace(/(?:^\w|\b\w)/g, (match) => match.toUpperCase()); // Capitalize first letter of each word
  }

  useEffect(() => {
    const totalAmount =
      installments &&
      installments.reduce((sum, installment) => {
        return sum + (Number(installment.amount) || 0);
      }, 0);

    console.log("Total Amount:", totalAmount);
    setTotalFees(totalAmount);
    // Calculate reduced total
    const reduced =
      totalAmount -
      (scholarshipAmount ? Number(scholarshipAmount) : 0) -
      (additionalAmount ? Number(additionalAmount) : 0);
    setReducedTotal(reduced);
    // You can set it to a state or use it as needed
  }, [installments]);

  const fetchPrices = async () => {
    try {
      const tokens = JSON.parse(localStorage.getItem("ERPTokens"));
      const { data } = await axios.get(`${BE_URL}/api/prices`, {
        headers: { Authorization: `Bearer ${tokens.accessToken}` },
      });

      const messData = data
        .filter((item) => item.type === "Mess")
        .map((item) => ({
          id: item.id,
          name: `${item.service} - Rs${item.price_per_month}`,
          price: item.price_per_month,
        }));

      const transportData = data
        .filter((item) => item.type === "Transport")
        .map((item) => ({
          id: item.id,
          name: `${item.service} - Rs${item.price_per_month}`,
          price: item.price_per_month,
        }));

      setmessOptions(messData);
      settransportOptions(transportData);
    } catch (err) {
      console.error("Error fetching prices:", err);
      alert("Failed to load prices.");
    }
  };

  const handleMessSelection = (selectedId) => {
    setmessPriceId(selectedId);
    const selectedOption = messOptions.find(
      (option) => option.id === selectedId
    );
    setSelectedMessPrice(selectedOption ? selectedOption.price : "");
  };

  const handleTransportSelection = (selectedId) => {
    settransportPriceId(selectedId);
    const selectedOption = transportOptions.find(
      (option) => option.id === selectedId
    );
    setSelectedTransportPrice(selectedOption ? selectedOption.price : "");
  };

  useEffect(() => {
    fetchClasses();
    fetchfeeStructures();
    fetchPrices();
  }, []);

  useEffect(() => {
    if (selectedClassId) {
      setDivision(null);
      setSelectedDivisionId(null);
      fetchDivisions(selectedClassId);
    }
  }, [selectedClassId]);

  const fetchClasses = async () => {
    setIsLoading(true);
    try {
      const tokens = JSON.parse(localStorage.getItem("ERPTokens")); // Ensure you have tokens stored in localStorage
      const response = await axios.get(`${BE_URL}/api/classes`, {
        headers: {
          Authorization: `Bearer ${tokens.accessToken}`, // Use the access token for authorization
        },
      });
      setClasses(response.data); // Assuming the API returns an array of class objects
    } catch (error) {
      console.error("Error fetching classes:", error);
      // Handle specific errors (e.g., unauthorized, server issues)
      if (error.response && error.response.status === 401) {
        // Handle unauthorized error, e.g., redirect to login or refresh token
        alert("Session expired. Please login again.");
      } else {
        alert("Failed to fetch classes. Please try again later.");
      }
    } finally {
      setIsLoading(false);
    }
  };
  const fetchDivisions = async (id) => {
    setIsLoading(true);
    try {
      const response = await axios.get(
        `${BE_URL}/api/general/class/${id}/divisions`,
        {}
      );
      setDivisions(response.data);
    } catch (error) {
      console.error("Error fetching classes:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchfeeStructures = async () => {
    setIsLoading(true);
    try {
      const tokens = JSON.parse(localStorage.getItem("ERPTokens"));
      const response = await axios.get(`${BE_URL}/api/feeStrAdmission`, {
        headers: {
          Authorization: `Bearer ${tokens.accessToken}`, // Use the access token for authorization
        },
      });
      setfeeStructures(response.data);
    } catch (error) {
      console.error("Error fetching fee structures:", error);
      // Handle specific errors similarly as done in fetchClasses
      if (error.response && error.response.status === 401) {
        alert("Session expired. Please login again.");
      } else {
        alert("Failed to fetch fee structures. Please try again later.");
      }
    } finally {
      setIsLoading(false); // Ensure loading state is updated in finally block
    }
  };

  // const handleRefresh = () => {
  //   fetchStudentDetails();
  // };

  const fetchStudentDetails = async () => {
    try {
      const tokens = JSON.parse(localStorage.getItem("ERPTokens"));
      const url = is_senior
        ? `${BE_URL}/api/students/${roll_no}`
        : `${BE_URL}/api/students/junior/${roll_no}`;
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${tokens.accessToken}`,
        },
      });

      if (response.data) {
        const scholarship = response.data;

        // Parse the date and extract only the date part
        const dob = new Date(scholarship.date_of_birth);
        const formattedDob = dob.toISOString().split("T")[0];

        setid(scholarship.id);
        setrollnumber(scholarship.roll_no);
        setstream(scholarship.stream);
        setbranch(scholarship.branch);
        setcaste(scholarship.caste);

        setFirstname(toCamelCase(scholarship.first_name.toLowerCase()));
        setmiddlename(toCamelCase(scholarship.middle_name.toLowerCase()));
        setlastname(toCamelCase(scholarship.last_name.toLowerCase()));
        setdob(formattedDob);
        setfathername(toCamelCase(scholarship.fathers_name.toLowerCase()));
        setmothername(toCamelCase(scholarship.mothers_name.toLowerCase()));
        setemail(scholarship.email.toLowerCase());
        setwhatsapp(scholarship.whatsapp);
        setAlternateNo(scholarship.alternate_no);
        setaadhar(scholarship.aadhar);
        setgender(scholarship.gender);
        setbloodgroup(scholarship.blood_group);
        setparentOccupation(scholarship.parent_occupation);
        setyear_start(scholarship.session);

        setadmclass(scholarship.adm_class);
        sethostel(scholarship.hostel);

        setDivision(scholarship.division);
        setSelectedDivisionId(scholarship.division_id);

        setaddress(scholarship.address);
        setdistrict(scholarship.district);
        settaluka(scholarship.taluka);
        setpin(scholarship.pin);
        setphoto_url(scholarship.photo_url);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // Handle token expiry
        const newTokens = await refreshToken();
        if (newTokens) {
          fetchStudentDetails(); // Retry fetching details with new token
        } else {
          // Redirect to login or show an error
          alert("User logged out.");
          // navigate("/login");
        }
      } else {
        console.error("Error fetching scholarship details:", error);
      }
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (isLoading) return;
    setIsLoading(true);

    // Initialize with default districts based on branch
    if (branch === "Ranchi") {
      setdistricts(biharJharkhandDistricts);
    } else if (branch === "Baramati") {
      let cities = [];
      data.map((city) => cities.push(city.name));
      setdistricts(cities);
    } else {
      // Initialize with default districts for other cases
      let cities = [];
      data.map((city) => cities.push(city.name));
      setdistricts(cities);
    }

    const fetchScholarshipDetail = async () => {
      try {
        const tokens = JSON.parse(localStorage.getItem("ERPTokens"));
        const url = is_senior
          ? `${BE_URL}/getScholarship/${roll_no}`
          : `${BE_URL}/getEntrance/${roll_no}`;
        const response = await axios.get(url, {
          headers: {
            Authorization: `Bearer ${tokens.accessToken}`,
          },
        });

        if (response.data) {
          const scholarship = response.data;

          console.log(scholarship.branch, scholarship.caste);

          // Parse the date and extract only the date part
          const dob = new Date(scholarship.date_of_birth);
          const formattedDob = dob.toISOString().split("T")[0];

          const branchValue = scholarship.branch;
          setbranch(branchValue);
          // Update districts based on branch value
          if (branchValue === "Ranchi") {
            setdistricts(biharJharkhandDistricts);
          } else if (branchValue === "Baramati") {
            let cities = [];
            data.map((city) => cities.push(city.name));
            setdistricts(cities);

            // Also set talukas if district is available
            if (scholarship.district) {
              const obj = data.find(
                (city) => city.name === scholarship.district
              );
              if (obj && obj.tahasil) {
                settalukas(obj.tahasil);
              }
            }
          }

          setcaste(scholarship.caste);
          setstream(scholarship.stream);
          setrollnumber(scholarship.roll_no);
          setFirstname(toCamelCase(scholarship.first_name.toLowerCase()));
          setmiddlename(toCamelCase(scholarship.middle_name.toLowerCase()));
          setlastname(toCamelCase(scholarship.last_name.toLowerCase()));
          setdob(formattedDob);
          setfathername(toCamelCase(scholarship.fathers_name.toLowerCase()));
          setmothername(toCamelCase(scholarship.mothers_name.toLowerCase()));
          setemail(scholarship.email.toLowerCase());
          setwhatsapp(scholarship.whatsapp);
          setAlternateNo(scholarship.alternate_no);
          setaadhar(scholarship.aadhar);
          setgender(scholarship.gender);
          setbloodgroup(scholarship.blood_group);
          setparentOccupation(scholarship.parent_occupation);
          setyear_start(scholarship.session);
          setadmclass(scholarship.adm_class.match(/\d+/)[0]);
          sethostel(scholarship.hostel === "Yes" ? true : false);

          setaddress(scholarship.address);
          setdistrict(scholarship.district);
          settaluka(scholarship.taluka);
          setpin(scholarship.pin);
          setphoto_url(scholarship.photo_url);

          setscholarshipAmount(scholarship.scholarship_amt);
          setfees(scholarship.fees);
          setAdditionalAmount(scholarship.additial_amount);
          const newClass = scholarship.adm_class.match(/\d+/)[0];
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          // Handle token expiry
          const newTokens = await refreshToken();
          if (newTokens) {
            fetchScholarshipDetail(); // Retry fetching details with new token
          } else {
            // Redirect to login or show an error
            alert("User logged out.");
            // navigate("/login");
          }
        } else {
          console.error("Error fetching scholarship details:", error);
        }
      } finally {
        setIsLoading(false);
      }
    };

    window.scrollTo(0, 0);

    if (roll_no && !promotion) {
      fetchScholarshipDetail();
    } else if (roll_no && promotion) {
      fetchStudentDetails();
    }
  }, []);

  // Handle branch change
  const handleBranchChange = (selectedBranch) => {
    setbranch(selectedBranch);

    // Reset district and taluka when branch changes
    setdistrict(null);
    settaluka(null);

    if (selectedBranch === "Ranchi") {
      // Use Bihar and Jharkhand districts for Ranchi branch
      setdistricts(biharJharkhandDistricts);
    } else {
      // Use existing district data from the data import
      let cities = [];
      data.map((city) => cities.push(city.name));
      setdistricts(cities);
    }
  };

  const validateForm = () => {
    const newErrors = {};

    // Define required fields based on branch selection
    let requiredFields = [
      "branch",
      "firstname",
      "middlename",
      "lastname",
      "fathername",
      "dob",
      "mothername",
      "email",
      "whatsapp",
      "alternateNo",
      "aadhar",
      "gender",
      "bloodgroup",
      "admclass",
      "address",
      "district",
      "pin",
      "year_start",
    ];

    // Only include taluka validation for Baramati branch
    if (branch !== "Ranchi") {
      requiredFields.push("taluka");
    }

    for (let field of requiredFields) {
      if (!eval(field)) {
        newErrors[field] = "This field is required";
      }
    }

    // New validations
    if (whatsapp && whatsapp.length !== 10) {
      newErrors.whatsapp = "WhatsApp number must be 10 digits";
    }

    if (alternateNo && alternateNo.length !== 10) {
      newErrors.alternateNo = "Alternate number must be 10 digits";
    }

    if (aadhar && aadhar.length !== 12) {
      newErrors.aadhar = "Aadhar number must be 12 digits";
    }

    if (pin && pin.length !== 6) {
      newErrors.pin = "Pin must be 6 digits";
    }

    setErrors(newErrors);
    if (Object.keys(newErrors).length === 0) {
      setError(null);
      return true;
    } else {
      setError("Please recheck the fields marked in red.");
      console.log(newErrors);
      setIsLoading(false);
      return false;
    }
  };

  const validateInstallments = (instmnts) => {
    // Check if any installment has a null or undefined account or due_date
    return instmnts.every(
      (installment) => installment.account && installment.due_date
    );
  };

  const handleSubmit = async () => {
    if (!validateForm()) {
      alert("Please correct the errors before submitting.");
      return;
    }

    if (!admclass) {
      alert("Please select the class");
      return;
    }
    if (!selectedDivisionId) {
      alert("Please select the Division");
      return;
    }
    if (hostel && !selectedBedCount) {
      alert("Please select the bed count.");
      return;
    }
    if (!validateInstallments(installments)) {
      alert("Please check all the installments - account & due date.");
      return;
    }

    if (!window.confirm("Are you sure you want to submit?")) {
      return;
    }

    setIsLoading(true);
    setError(null);

    const studentData = {
      // Gather all necessary fields from state
      id: id,
      is_senior: is_senior,
      prev_roll_no: roll_no,
      first_name: firstname,
      middle_name: middlename,
      last_name: lastname,
      date_of_birth: dob,
      branch: branch,
      stream: stream,
      caste: caste,
      fathers_name: fathername,
      mothers_name: mothername,
      email: email,
      whatsapp: whatsapp,
      alternate_no: alternateNo,
      aadhar: aadhar,
      gender: gender,
      blood_group: bloodgroup,
      adm_class: admclass.match(/\d+/)[0],
      class_id: selectedClassId, // Ensure this is the actual class ID
      division: division,
      division_id: selectedDivisionId,
      academic_year_start: year_start,
      parent_occupation: parentOccupation,
      hostel: hostel,
      bed_count: hostel ? selectedBedCount : null,
      mess: messRequired,
      messMonths: messRequired ? messMonths : null,
      messFees: messRequired ? messFee : null,
      messPriceId: messRequired ? messPriceId : null,
      messValidity: messRequired ? messValidityDate : null,
      transport: transportRequired,
      transportMonths: transportRequired ? transportMonths : null,
      transportFees: transportRequired ? transportFee : null,
      transportPriceId: transportRequired ? transportPriceId : null,
      transportValidity: transportRequired ? transportValidityDate : null,
      scholarship_amt: scholarshipAmount,
      additial_amount: additionalAmount > 0 ? additionalAmount : null,
      total_fee: totalFees,
      class_fee: classFee,
      discounted_fee: reducedTotal,
      hostel_fees: hostelFee,
      // Make sure to convert to the correct format if needed
      address: address,
      district: district,
      taluka: taluka,
      pin: pin,
      photo_url: photo_url,
      additional_subject: additional_subject,
      additional_subject_name: additional_subject
        ? additional_subject_name
        : null,

      installments: installments,
      // Include any other fields you have in your form
    };

    try {
      let url;
      if (promotion) {
        url = is_senior
          ? `${BE_URL}/api/students/promote/${rollnumber}`
          : `${BE_URL}/api/students/junior/promote/${rollnumber}`;
      } else {
        url = is_senior
          ? `${BE_URL}/api/students`
          : `${BE_URL}/api/students/junior`;
      }

      console.log("Url is", url);
      const response = await axios.post(url, studentData, {
        headers: {
          Authorization: `Bearer ${tokens.accessToken}`,
        },
      });

      const { roll_no } = response.data;

      if (promotion) {
        alert("Student promoted successfully!");
      } else {
        alert("Student added successfully!");
      }

      if (is_senior) {
        navigate(`/admin/seniorStudent/${response.data.roll_no}`);
      } else {
        navigate(`/admin/juniorStudent/${response.data.roll_no}`);
      }
      // Redirect or perform additional actions upon success
    } catch (error) {
      setIsLoading(false);
      if (error.response) {
        // Check for token expiration
        console.log(error.response.data);
        if (error.response.status === 401) {
          // Handle token refresh
          const refreshed = await refreshToken();
          if (refreshed) {
            handleSubmit(); // Retry submission with new token
          } else {
            navigate("/login"); // Redirect to login on token refresh failure
          }
        } else if (
          error.response.data &&
          error.response.data.code === "23505"
        ) {
          // Handle unique constraint violation
          let detail = error.response.data.detail;
          if (detail.includes("phone_number")) {
            alert(
              "The WhatsApp number already exists in the system. Please use a different number."
            );
          } else if (detail.includes("email")) {
            alert(
              "The email address already exists in the system. Please use a different email address."
            );
          } else if (detail.includes("aadhar")) {
            alert("Failed - The Aadhar Number already exists in the system!");
          }
        } else {
          // General error handling
          console.error("Failed to submit form:", error);
          setError("Failed to create student. Please try again.");
          alert(
            "Failed to create student due to an unexpected error. Please try again."
          );
        }
      } else {
        // Handle errors not related to the HTTP response
        console.error("Failed to submit form:", error);
        setError("Failed to create student. Please try again.");
        alert(
          "Failed to create student due to an unexpected error. Please try again."
        );
      }
    }
  };

  const refreshToken = async () => {
    try {
      const tokens = JSON.parse(localStorage.getItem("ERPTokens"));
      // const response = await fetch("http://localhost:8000/refreshToken", {
      const response = await fetch("${BE_URL}/refreshToken", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ token: tokens.refreshToken }),
      });

      if (!response.ok) {
        throw new Error("Failed to refresh token");
      }

      const newTokens = await response.json();
      localStorage.setItem("ERPTokens", JSON.stringify(newTokens));
      return newTokens;
    } catch (error) {
      console.error("Error refreshing token:", error);
      // alert("User loggod out. Please login again");
      // navigate("/login");
      // return null;
    }
  };

  // Modified selectDistrict to handle branch-specific behavior
  const selectDistrict = (val) => {
    setdistrict(val);

    // Only set talukas for Baramati branch
    if (branch !== "Ranchi") {
      const obj = data.find((city) => city.name === val);
      if (obj && obj.tahasil) {
        settalukas(obj.tahasil);
      } else {
        settalukas([]);
      }
    }
  };

  const monthsOptions = Array.from({ length: 11 }, (_, i) => ({
    label: `${i + 1}`, // Display numbers starting from 1 to 11 as string
    value: i + 1, // Actual value is the month number from 1 to 11
  }));

  const handlePrint = () => {
    if (isLoading) return;
    setIsLoading(true);

    const printContents = printDivRef.current.innerHTML;
    const originalContents = document.body.innerHTML;

    document.body.innerHTML = printContents;
    window.print();
    document.body.innerHTML = originalContents;
    setIsLoading(false);
  };

  const changeAdditional = (val) => {
    setadditional_subject(val);
    setadditional_subject_name(null);
  };
  const changeHostel = (val) => {
    sethostel(val);
    setSelectedBedCount(null);
  };

  useEffect(() => {
    if (selectedClassId) {
      setDivision(null);
      setSelectedDivisionId(null);
      fetchDivisions(selectedClassId);
      setinstallments(null);
    }
  }, [selectedClassId]);

  useEffect(() => {
    setinstallments(null);
    const matchingStructure = feeStructures.find((structure) => {
      const matchesClass = structure.class_id === selectedClassId;
      const matchesHostel = structure.hostel === hostel;
      const matchesBedCount = structure.bed_count == selectedBedCount;
      const matchesAdditionalSubject =
        structure.additional_subject === additional_subject;
      const matchesAdditionalSubjectName =
        structure.additional_subject_name === additional_subject_name;
      return (
        matchesClass &&
        matchesHostel &&
        matchesBedCount &&
        matchesAdditionalSubject &&
        matchesAdditionalSubjectName
      );
    });

    // Update installments and fees based on the matching fee structure
    if (matchingStructure) {
      setinstallments(matchingStructure.installments);
      setClassFee(matchingStructure.fee);
    }
  }, [
    hostel,
    selectedBedCount,
    additional_subject,
    additional_subject_name,
    feeStructures,
    selectedClassId,
  ]);

  return (
    <div className="asd">
      {permissions?.can_read ? (
        <>
          {isLoading && <Loader />}

          <div className="asd-cta">
            <div
              className="asd-cta-back"
              onClick={() => navigate("/admin/scholarships")}
            >
              &larr; Back
            </div>
            <div className="asd-cta-note">
              {promotion ? "Promotion Form" : "Admission Form"} of {rollnumber}
            </div>
            <div className="asd-cta-buttons">
              <img src={print} onClick={handlePrint} alt="Print" />
            </div>
          </div>
          <div className="asd-header"></div>
          <div ref={printDivRef} className="asd-contents">
            {/* <div className="asd-contents-title">
              Senior Admission Form of {rollnumber}
            </div> */}
            <div className="asd-contents-section">
              <div className="asd-contents-section-title">
                Basic Information
              </div>
              <div className="asd-contents-section-fields">
                <div className="asd-contents-section-fields-field">
                  <DropDown
                    invalid={errors.branch}
                    options={branches}
                    label={`Branch`}
                    value={branch}
                    onChange={handleBranchChange}
                  />
                </div>
                <div className="asd-contents-section-fields-field">
                  <DropDown
                    invalid={errors.stream}
                    options={streams}
                    label={`Stream`}
                    value={stream}
                    onChange={setstream}
                  />
                </div>

                <div
                  className={`asd-contents-section-fields-field ${
                    errors.firstname ? "invalid" : ""
                  }`}
                >
                  <TextField
                    placeholder={`First Name`}
                    value={firstname}
                    onChange={setFirstname}
                  />
                </div>
                <div
                  className={`asd-contents-section-fields-field ${
                    errors.middlename ? "invalid" : ""
                  }`}
                >
                  <TextField
                    placeholder={`Middle Name`}
                    value={middlename}
                    onChange={setmiddlename}
                  />
                </div>
                <div
                  className={`asd-contents-section-fields-field ${
                    errors.lastname ? "invalid" : ""
                  }`}
                >
                  <TextField
                    className={errors.lastname ? "input-error" : ""}
                    placeholder={`Last Name`}
                    value={lastname}
                    onChange={setlastname}
                  />
                </div>
                <div className="asd-contents-section-fields-field">
                  <DatePicker
                    invalid={errors.dob}
                    label={`Date of Birth`}
                    value={dob}
                    onChange={setdob}
                  />
                </div>
                <div
                  className={`asd-contents-section-fields-field ${
                    errors.fathername ? "invalid" : ""
                  }`}
                >
                  <TextField
                    placeholder={`Father's Name`}
                    value={fathername}
                    onChange={setfathername}
                  />
                </div>
                <div
                  className={`asd-contents-section-fields-field ${
                    errors.mothername ? "invalid" : ""
                  }`}
                >
                  <TextField
                    placeholder={`Mother's Name`}
                    value={mothername}
                    onChange={setmothername}
                  />
                </div>
                <div
                  className={`asd-contents-section-fields-field ${
                    errors.email ? "invalid" : ""
                  }`}
                >
                  <TextField
                    placeholder={`Email`}
                    value={email}
                    onChange={setemail}
                  />
                </div>
                <div
                  className={`asd-contents-section-fields-field ${
                    errors.whatsapp ? "invalid" : ""
                  }`}
                >
                  <TextField
                    placeholder={`WhatsApp No.`}
                    value={whatsapp}
                    onChange={setwhatsapp}
                  />
                </div>
                <div
                  className={`asd-contents-section-fields-field ${
                    errors.alternateNo ? "invalid" : ""
                  }`}
                >
                  <TextField
                    placeholder={`Alternate No.`}
                    value={alternateNo}
                    onChange={setAlternateNo}
                  />
                </div>
                <div
                  className={`asd-contents-section-fields-field ${
                    errors.aadhar ? "invalid" : ""
                  }`}
                >
                  <TextField
                    placeholder={`Aadhar No.`}
                    value={aadhar}
                    onChange={setaadhar}
                  />
                </div>
                <div className={`asd-contents-section-fields-field`}>
                  <DropDown
                    invalid={errors.year_start}
                    options={academic_years}
                    label={`Academic Year`}
                    value={year_start}
                    onChange={setyear_start}
                  />
                </div>
                {/* <div
                  className={`asd-contents-section-fields-field ${
                    errors.year_start ? "invalid" : ""
                  }`}
                >
                  <TextField
                    placeholder={`Academic Year Start`}
                    value={year_start}
                    onChange={setyear_start}
                  />
                </div> */}
                {/* <div
                  className={`asd-contents-section-fields-field ${
                    errors.year_end ? "invalid" : ""
                  }`}
                >
                  <TextField
                    placeholder={`Academic Year End`}
                    value={year_end}
                    onChange={setyear_end}
                  />
                </div> */}
                <div className="asd-contents-section-fields-field">
                  <DropDown
                    invalid={errors.gender}
                    options={genders}
                    label={`Gender`}
                    value={gender}
                    onChange={setgender}
                  />
                </div>
                <div className="asd-contents-section-fields-field">
                  <DropDown
                    invalid={errors.bloodgroup}
                    options={bloodgroups}
                    label={`Blood Group`}
                    value={bloodgroup}
                    onChange={setbloodgroup}
                  />
                </div>
                <div className="asd-contents-section-fields-field">
                  <DropDown
                    invalid={errors.caste}
                    options={casteOptions}
                    label={`Caste`}
                    value={caste}
                    onChange={setcaste}
                  />
                </div>
                <div
                  className={`scholarship-contents-section-fields-field ${
                    errors.parentOccupation ? "invalid" : ""
                  }`}
                >
                  <TextField
                    placeholder={`Parent Occupation`}
                    value={parentOccupation}
                    onChange={setparentOccupation}
                  />
                </div>
                {/* <div className="asd-contents-section-fields-field"></div> */}
              </div>
            </div>

            <hr className="asd-contents-seperator"></hr>

            {/* Address */}
            <div className="asd-contents-section">
              <div className="asd-contents-section-title">Address</div>
              <div className="asd-contents-section-fields">
                <div
                  className={`asd-contents-section-fields-field ${
                    errors.address ? "invalid" : ""
                  }`}
                >
                  <TextField
                    placeholder={`Address`}
                    value={address}
                    onChange={setaddress}
                  />
                </div>
                <div className="asd-contents-section-fields-field">
                  {/* <DropDown
                    invalid={errors.district}
                    options={districts}
                    label={`District`}
                    value={district}
                    onChange={selectDistrict}
                  /> */}

                  <CustomDropDown
                    data={districts.map((city) => ({
                      id: city,
                      name: city,
                    }))}
                    searchable={true}
                    label="Select District"
                    placeholder="Select District"
                    onChange={selectDistrict}
                    value={district}
                  />
                </div>

                {/* Conditionally render taluka dropdown only for Baramati branch */}
                {branch !== "Ranchi" ? (
                  <div className="asd-contents-section-fields-field">
                    <DropDown
                      invalid={errors.taluka}
                      options={talukas}
                      label={`Taluka`}
                      value={taluka}
                      onChange={settaluka}
                    />
                  </div>
                ) : null}

                <div
                  className={`asd-contents-section-fields-field ${
                    errors.pin ? "invalid" : ""
                  }`}
                >
                  <TextField
                    placeholder={`Pin`}
                    value={pin}
                    onChange={setpin}
                  />
                </div>
                <div className="asd-contents-section-fields-field"></div>
              </div>
            </div>

            <hr className="asd-contents-seperator"></hr>

            <div className="asd-contents-section">
              <div className="asd-contents-section-title">Mess & Transport</div>
              <div className="asd-contents-section-fields">
                <div className="asd-contents-section-fields-field">
                  <ObjectDropDown
                    label="Mess Required"
                    options={[
                      { value: "Yes", label: true },
                      { value: "No", label: false },
                    ].map(({ label, value }) => ({
                      label: label,
                      value: value,
                    }))}
                    value={messRequired}
                    setValue={(val) =>
                      val === "Yes"
                        ? setMessRequired(true)
                        : setMessRequired(false)
                    }
                    setId={() => {}}
                  />
                </div>

                {messRequired && (
                  <>
                    <div className="asd-contents-section-fields-field">
                      <CustomDropDown
                        label="Mess Type"
                        data={messOptions}
                        value={messPriceId}
                        onChange={handleMessSelection}
                        searchable={false}
                      />
                    </div>
                    <div className="asd-contents-section-fields-field">
                      <ObjectDropDown
                        label="Number of Months"
                        options={monthsOptions}
                        value={messMonths}
                        setId={() => {}}
                        setValue={setMessMonths}
                      />
                    </div>
                    <div className="asd-contents-section-fields-field">
                      <DatePicker
                        label="Mess Validity Date"
                        value={messValidityDate}
                        onChange={setMessValidityDate}
                      />
                    </div>
                  </>
                )}
                <div className="asd-contents-section-fields-field">
                  <ObjectDropDown
                    label="Transport Required"
                    options={[
                      { value: "Yes", label: true },
                      { value: "No", label: false },
                    ].map(({ label, value }) => ({
                      label: label,
                      value: value,
                    }))}
                    value={transportRequired}
                    setValue={(val) =>
                      val === "Yes"
                        ? setTransportRequired(true)
                        : setTransportRequired(false)
                    }
                    setId={() => {}}
                  />
                </div>
                {transportRequired && (
                  <>
                    <div className="asd-contents-section-fields-field">
                      <CustomDropDown
                        label="Transport Type"
                        data={transportOptions}
                        value={transportPriceId}
                        onChange={handleTransportSelection}
                      />
                    </div>
                    <div className="asd-contents-section-fields-field">
                      <ObjectDropDown
                        label="Number of Months"
                        options={monthsOptions}
                        value={transportMonths}
                        setId={() => {}}
                        setValue={setTransportMonths}
                      />
                    </div>
                    <div className="asd-contents-section-fields-field">
                      <DatePicker
                        label="Transport Validity Date"
                        value={transportValidityDate}
                        onChange={setTransportValidityDate}
                      />
                    </div>
                  </>
                )}

                <div>
                  {messRequired && messMonths ? (
                    <div className="asd-contents-section-fees">
                      Mess fees is {messFee}. Add it to the installments
                      manually.
                    </div>
                  ) : null}
                </div>
                {transportRequired && transportMonths ? (
                  <div className="asd-contents-section-fees">
                    Transport fees is {transportFee}. Add it to the installments
                    manually.
                  </div>
                ) : null}
              </div>
            </div>

            <hr className="asd-contents-seperator"></hr>

            {/* Admission Related Information */}
            <div className="asd-contents-section">
              <div className="asd-contents-section-title">
                Admission Related Information
              </div>
              <div className="asd-contents-section-fields">
                {/* <div className="asd-contents-section-fields-field">
                  <CustomNumberInput
                    value={additionalAmount}
                    label={`Additional Discount`}
                    placeholder={`Additional discount`}
                    onChange={(e) => setAdditionalAmount(e)}
                  />
                </div> */}
                <div className="asd-contents-section-fields-field">
                  <ObjectDropDown
                    label="Select Class"
                    options={classes.map(({ class_name, id }) => ({
                      label: id,
                      value: class_name,
                    }))}
                    value={admclass}
                    setId={setSelectedClassId}
                    setValue={setadmclass}
                  />
                </div>
                <div className="asd-contents-section-fields-field">
                  <ObjectDropDown
                    label="Select Division"
                    options={divisions.map(({ name, id }) => ({
                      label: id,
                      value: name,
                    }))}
                    value={division}
                    setId={setSelectedDivisionId}
                    setValue={setDivision}
                  />
                </div>

                <div className="asd-contents-section-fields-field">
                  <ObjectDropDown
                    label="Opting for Hostel"
                    options={[
                      { value: "Yes", label: true },
                      { value: "No", label: false },
                    ].map(({ label, value }) => ({
                      label: label,
                      value: value,
                    }))}
                    value={hostel}
                    setId={() => {}}
                    setValue={(val) =>
                      val === "Yes" ? sethostel(true) : changeHostel(false)
                    }
                  />
                </div>
                {hostel && (
                  <div className="asd-contents-section-fields-field">
                    <ObjectDropDown
                      label="Bed Count"
                      options={[
                        { value: 4, label: 4 },
                        { value: 5, label: 5 },
                        { value: 6, label: 6 },
                        { value: "hostel", label: "hostel" },
                      ].map(({ label, value }) => ({
                        label: label,
                        value: value,
                      }))}
                      value={selectedBedCount}
                      setValue={setSelectedBedCount}
                      setId={() => {}}
                    />
                  </div>
                )}

                {is_senior && (
                  <>
                    <div className="asd-contents-section-fields-field">
                      <ObjectDropDown
                        label="Additional Subject?"
                        options={[
                          { value: "Yes", label: true },
                          { value: "No", label: false },
                        ].map(({ label, value }) => ({
                          label: label,
                          value: value,
                        }))}
                        value={additional_subject}
                        setValue={(val) =>
                          val === "Yes"
                            ? setadditional_subject(true)
                            : changeAdditional(false)
                        }
                        setId={() => {}}
                      />
                    </div>
                    {additional_subject && (
                      <div className="asd-contents-section-fields-field">
                        <ObjectDropDown
                          label="Additional Subject Name"
                          options={[
                            { value: "I.T.", label: "I.T." },
                            { value: "Crop Science", label: "Crop Science" },
                          ].map(({ label, value }) => ({
                            label: label,
                            value: value,
                          }))}
                          value={additional_subject_name}
                          setValue={setadditional_subject_name}
                          setId={() => {}}
                        />
                      </div>
                    )}
                  </>
                )}

                {/* Displaying Class Fee and Installments */}
                {installments && installments.length > 0 ? (
                  <div className="asd-contents-section-fields-inst">
                    <InstallmentsWithDiscountsDisplay
                      title="Class Fee Details"
                      fee={totalFees}
                      installments={installments}
                      setInstallments={setinstallments}
                      scholarshipAmount={scholarshipAmount}
                      additial_amount={additionalAmount}
                    />

                    {/* <InstallmentsStudentDetailsEdit
                      session={year_start}
                      student_id={id}
                      student_roll_no={roll_no}
                      title="Class Fee Details"
                      fee={totalFees}
                      installments={installments}
                      setInstallments={setinstallments}
                      scholarshipAmount={scholarshipAmount}
                      additial_amount={additionalAmount}
                      finalFee={reducedTotal}
                      handleRefresh={handleRefresh}
                    /> */}
                  </div>
                ) : (
                  "No Fees structure found for the current selections."
                )}

                <div className="asd-contents-section-fields-field"></div>
              </div>
            </div>

            <hr className="asd-contents-seperator"></hr>

            {/* {is_senior && <hr className="asd-contents-seperator"></hr>} */}

            {error && <div className="asd-error">{error}</div>}
          </div>

          {permissions?.can_update && (
            <div className="asd-button">
              <Button text={`Submit`} onClick={handleSubmit} />
            </div>
          )}
        </>
      ) : (
        ""
      )}
    </div>
  );
};

export default AdmissionForm;

// import "./AdmissionForm.scss";
// import print from "../../assets/images/Print.png";

// import data from "../../assets/data/districtData";

// import React, { useEffect, useRef, useState } from "react";
// import TextField from "../../components/TextField/TextField";
// import DropDown from "../../components/DropDown/DropDown";
// import DatePicker from "../../components/DatePicker/DatePicker";
// import Button from "../../components/Button/Button";
// import { useNavigate, useParams, useLocation } from "react-router-dom";
// import axios from "axios";
// import Loader from "../../components/Loader/Loader";
// import { jwtDecode } from "jwt-decode";
// import { useSelector } from "react-redux";
// import ObjectDropDown from "../../components/ObjectDropDown/ObjectDropDown";
// import InstallmentsWithDiscountsDisplay from "../../components/InstallmentsWithDiscountsDisplay/InstallmentsWithDiscountsDisplay";
// import CustomDropDown from "../../components/CustomDropDown/CustomDropDown";

// // const BE_URL = "http://localhost:8000";
// const BE_URL = "https://server.classerp.in";

// const AdmissionForm = () => {
//   const navigate = useNavigate();
//   const printDivRef = useRef();
//   const { roll_no } = useParams();

//   const location = useLocation();
//   const { is_senior, promotion } = location.state || {};
//   console.log("Promition is ", promotion);

//   //RBAC code block
//   const allPermissions = useSelector((state) => state.permissions);
//   let permissions = null;
//   if (is_senior) {
//     permissions = allPermissions
//       .find((p) => p.name === "Students")
//       ?.dropdowns.find((dd) => dd.name === "Senior Students")?.permissions;
//   } else {
//     permissions = allPermissions
//       .find((p) => p.name === "Students")
//       ?.dropdowns.find((dd) => dd.name === "Junior Students")?.permissions;
//   }

//   const tokens = JSON.parse(localStorage.getItem("ERPTokens"));
//   const decodedToken = tokens ? jwtDecode(tokens.accessToken) : null;
//   const roleId = decodedToken ? decodedToken.role_id : null;

//   const [districts, setdistricts] = useState([]);
//   const [talukas, settalukas] = useState([]);

//   const [isLoading, setIsLoading] = useState(false);

//   const [id, setid] = useState(null);
//   const [branch, setbranch] = useState(null);
//   const [caste, setcaste] = useState(null);
//   const [stream, setstream] = useState(null);
//   const [rollnumber, setrollnumber] = useState(null);
//   const [firstname, setFirstname] = useState(null);
//   const [middlename, setmiddlename] = useState(null);
//   const [lastname, setlastname] = useState(null);
//   const [dob, setdob] = useState(null);
//   const [fathername, setfathername] = useState(null);
//   const [mothername, setmothername] = useState(null);
//   const [email, setemail] = useState(null);
//   const [whatsapp, setwhatsapp] = useState(null);
//   const [alternateNo, setAlternateNo] = useState(null);
//   const [aadhar, setaadhar] = useState(null);
//   const [gender, setgender] = useState(null);
//   const [bloodgroup, setbloodgroup] = useState(null);
//   const [parentOccupation, setparentOccupation] = useState(null);

//   const [admclass, setadmclass] = useState(null);
//   const [classes, setClasses] = useState([]);
//   const [division, setDivision] = useState(null);
//   const [divisions, setDivisions] = useState([]);
//   const [year_start, setyear_start] = useState(null);
//   const [year_end, setyear_end] = useState(null);

//   const [selectedClassId, setSelectedClassId] = useState(null);
//   const [selectedDivisionId, setSelectedDivisionId] = useState(null);
//   const [selectedBedCount, setSelectedBedCount] = useState(null);
//   const [classFee, setClassFee] = useState(0);
//   const [hostelFee, setHostelFee] = useState(0);
//   const [installments, setinstallments] = useState([]);

//   const [hostel, sethostel] = useState(false);
//   const [feeStructures, setfeeStructures] = useState([]);

//   const [address, setaddress] = useState(null);
//   const [district, setdistrict] = useState(null);
//   const [taluka, settaluka] = useState(null);
//   const [pin, setpin] = useState(null);
//   const [photo_url, setphoto_url] = useState(null);

//   const [fees, setfees] = useState(null);
//   const [scholarshipAmount, setscholarshipAmount] = useState(0);
//   const [additionalAmount, setAdditionalAmount] = useState(0);
//   const [totalFees, setTotalFees] = useState(""); //Fees with everything added
//   const [reducedTotal, setReducedTotal] = useState(0); //Fees after reducting scholarship and additional amount

//   const [additional_subject_name, setadditional_subject_name] = useState(null);
//   const [additional_subject, setadditional_subject] = useState(false);

//   const [messRequired, setMessRequired] = useState(false);
//   const [messFee, setmessFee] = useState(null);
//   const [messMonths, setMessMonths] = useState(0);
//   const [messValidityDate, setMessValidityDate] = useState(null);
//   const [messPriceId, setmessPriceId] = useState(null);

//   const [transportPriceId, settransportPriceId] = useState(null);
//   const [transportRequired, setTransportRequired] = useState(false);
//   const [transportMonths, setTransportMonths] = useState(0);
//   const [transportFee, settransportFee] = useState(null);
//   const [transportValidityDate, setTransportValidityDate] = useState(null);

//   const [messOptions, setmessOptions] = useState([]);
//   const [transportOptions, settransportOptions] = useState([]);
//   const [selectedMessPrice, setSelectedMessPrice] = useState("");
//   const [selectedTransportPrice, setSelectedTransportPrice] = useState("");

//   const [error, setError] = useState(null);
//   const [errors, setErrors] = useState({});

//   const bloodgroups = ["A+", "A-", "B+", "B-", "AB+", "AB-", "O+", "O-"];
//   const genders = ["Male", "Female"];

//   const bool = ["Yes", "No"];
//   const branches = ["Baramati", "Pune", "Nagar", "Ranchi"];
//   const casteOptions = ["General/Open", "OBC", "SC", "ST", "EWS", "Others"];
//   const streams = ["Engineering", "Medical"];

//   const academic_years = [
//     "2023-2024",
//     "2024-2025",
//     "2025-2026",
//     "2026-2027",
//     "2027-2028",
//     "2028-2029",
//     "2029-2030",
//   ];

//   useEffect(() => {
//     if (!hostel) {
//       setSelectedBedCount(null);
//     }
//   }, [hostel]);

//   useEffect(() => {
//     // Calculate mess and transport fees

//     const messFees = messRequired ? messMonths * selectedMessPrice : 0;
//     const transportFees = transportRequired
//       ? transportMonths * selectedTransportPrice
//       : 0;

//     setmessFee(messFees);
//     settransportFee(transportFees);

//     // Calculate total fees
//     const total =
//       Number(classFee) + Number(hostelFee) + messFees + transportFees;
//     setTotalFees(total);

//     // Calculate reduced total
//     const reduced =
//       total -
//       (scholarshipAmount ? Number(scholarshipAmount) : 0) -
//       (additionalAmount ? Number(additionalAmount) : 0);
//     setReducedTotal(reduced);
//   }, [
//     admclass,
//     classFee,
//     hostelFee,
//     scholarshipAmount,
//     additionalAmount,
//     messRequired,
//     messMonths,
//     transportRequired,
//     transportMonths,
//     selectedMessPrice,
//     selectedTransportPrice,
//   ]);

//   function toCamelCase(str) {
//     return str.replace(/(?:^\w|\b\w)/g, (match) => match.toUpperCase()); // Capitalize first letter of each word
//   }

//   useEffect(() => {
//     const totalAmount =
//       installments &&
//       installments.reduce((sum, installment) => {
//         return sum + (Number(installment.amount) || 0);
//       }, 0);

//     console.log("Total Amount:", totalAmount);
//     setTotalFees(totalAmount);
//     // Calculate reduced total
//     const reduced =
//       totalAmount -
//       (scholarshipAmount ? Number(scholarshipAmount) : 0) -
//       (additionalAmount ? Number(additionalAmount) : 0);
//     setReducedTotal(reduced);
//     // You can set it to a state or use it as needed
//   }, [installments]);

//   const fetchPrices = async () => {
//     try {
//       const tokens = JSON.parse(localStorage.getItem("ERPTokens"));
//       const { data } = await axios.get(`${BE_URL}/api/prices`, {
//         headers: { Authorization: `Bearer ${tokens.accessToken}` },
//       });

//       const messData = data
//         .filter((item) => item.type === "Mess")
//         .map((item) => ({
//           id: item.id,
//           name: `${item.service} - Rs${item.price_per_month}`,
//           price: item.price_per_month,
//         }));

//       const transportData = data
//         .filter((item) => item.type === "Transport")
//         .map((item) => ({
//           id: item.id,
//           name: `${item.service} - Rs${item.price_per_month}`,
//           price: item.price_per_month,
//         }));

//       setmessOptions(messData);
//       settransportOptions(transportData);
//     } catch (err) {
//       console.error("Error fetching prices:", err);
//       alert("Failed to load prices.");
//     }
//   };

//   const handleMessSelection = (selectedId) => {
//     setmessPriceId(selectedId);
//     const selectedOption = messOptions.find(
//       (option) => option.id === selectedId
//     );
//     setSelectedMessPrice(selectedOption ? selectedOption.price : "");
//   };

//   const handleTransportSelection = (selectedId) => {
//     settransportPriceId(selectedId);
//     const selectedOption = transportOptions.find(
//       (option) => option.id === selectedId
//     );
//     setSelectedTransportPrice(selectedOption ? selectedOption.price : "");
//   };

//   useEffect(() => {
//     fetchClasses();
//     fetchfeeStructures();
//     fetchPrices();
//   }, []);

//   useEffect(() => {
//     if (selectedClassId) {
//       setDivision(null);
//       setSelectedDivisionId(null);
//       fetchDivisions(selectedClassId);
//     }
//   }, [selectedClassId]);

//   const fetchClasses = async () => {
//     setIsLoading(true);
//     try {
//       const tokens = JSON.parse(localStorage.getItem("ERPTokens")); // Ensure you have tokens stored in localStorage
//       const response = await axios.get(`${BE_URL}/api/classes`, {
//         headers: {
//           Authorization: `Bearer ${tokens.accessToken}`, // Use the access token for authorization
//         },
//       });
//       setClasses(response.data); // Assuming the API returns an array of class objects
//     } catch (error) {
//       console.error("Error fetching classes:", error);
//       // Handle specific errors (e.g., unauthorized, server issues)
//       if (error.response && error.response.status === 401) {
//         // Handle unauthorized error, e.g., redirect to login or refresh token
//         alert("Session expired. Please login again.");
//       } else {
//         alert("Failed to fetch classes. Please try again later.");
//       }
//     } finally {
//       setIsLoading(false);
//     }
//   };
//   const fetchDivisions = async (id) => {
//     setIsLoading(true);
//     try {
//       const response = await axios.get(
//         `${BE_URL}/api/general/class/${id}/divisions`,
//         {}
//       );
//       setDivisions(response.data);
//     } catch (error) {
//       console.error("Error fetching classes:", error);
//     } finally {
//       setIsLoading(false);
//     }
//   };

//   const fetchfeeStructures = async () => {
//     setIsLoading(true);
//     try {
//       const tokens = JSON.parse(localStorage.getItem("ERPTokens"));
//       const response = await axios.get(`${BE_URL}/api/feeStrAdmission`, {
//         headers: {
//           Authorization: `Bearer ${tokens.accessToken}`, // Use the access token for authorization
//         },
//       });
//       setfeeStructures(response.data);
//     } catch (error) {
//       console.error("Error fetching fee structures:", error);
//       // Handle specific errors similarly as done in fetchClasses
//       if (error.response && error.response.status === 401) {
//         alert("Session expired. Please login again.");
//       } else {
//         alert("Failed to fetch fee structures. Please try again later.");
//       }
//     } finally {
//       setIsLoading(false); // Ensure loading state is updated in finally block
//     }
//   };

//   // const handleRefresh = () => {
//   //   fetchStudentDetails();
//   // };

//   const fetchStudentDetails = async () => {
//     try {
//       const tokens = JSON.parse(localStorage.getItem("ERPTokens"));
//       const url = is_senior
//         ? `${BE_URL}/api/students/${roll_no}`
//         : `${BE_URL}/api/students/junior/${roll_no}`;
//       const response = await axios.get(url, {
//         headers: {
//           Authorization: `Bearer ${tokens.accessToken}`,
//         },
//       });

//       if (response.data) {
//         const scholarship = response.data;

//         // Parse the date and extract only the date part
//         const dob = new Date(scholarship.date_of_birth);
//         const formattedDob = dob.toISOString().split("T")[0];

//         setid(scholarship.id);
//         setrollnumber(scholarship.roll_no);
//         setstream(scholarship.stream);
//         setbranch(scholarship.branch);
//         setcaste(scholarship.caste);

//         setFirstname(toCamelCase(scholarship.first_name.toLowerCase()));
//         setmiddlename(toCamelCase(scholarship.middle_name.toLowerCase()));
//         setlastname(toCamelCase(scholarship.last_name.toLowerCase()));
//         setdob(formattedDob);
//         setfathername(toCamelCase(scholarship.fathers_name.toLowerCase()));
//         setmothername(toCamelCase(scholarship.mothers_name.toLowerCase()));
//         setemail(scholarship.email.toLowerCase());
//         setwhatsapp(scholarship.whatsapp);
//         setAlternateNo(scholarship.alternate_no);
//         setaadhar(scholarship.aadhar);
//         setgender(scholarship.gender);
//         setbloodgroup(scholarship.blood_group);
//         setparentOccupation(scholarship.parent_occupation);
//         setyear_start(scholarship.session);

//         setadmclass(scholarship.adm_class);
//         sethostel(scholarship.hostel);

//         setDivision(scholarship.division);
//         setSelectedDivisionId(scholarship.division_id);

//         setaddress(scholarship.address);
//         setdistrict(scholarship.district);
//         settaluka(scholarship.taluka);
//         setpin(scholarship.pin);
//         setphoto_url(scholarship.photo_url);
//       }
//     } catch (error) {
//       if (error.response && error.response.status === 401) {
//         // Handle token expiry
//         const newTokens = await refreshToken();
//         if (newTokens) {
//           fetchStudentDetails(); // Retry fetching details with new token
//         } else {
//           // Redirect to login or show an error
//           alert("User logged out.");
//           // navigate("/login");
//         }
//       } else {
//         console.error("Error fetching scholarship details:", error);
//       }
//     } finally {
//       setIsLoading(false);
//     }
//   };

//   useEffect(() => {
//     if (isLoading) return;
//     setIsLoading(true);

//     let cities = [];
//     data.map((city) => cities.push(city.name));
//     setdistricts(cities);

//     const fetchScholarshipDetail = async () => {
//       try {
//         const tokens = JSON.parse(localStorage.getItem("ERPTokens"));
//         const url = is_senior
//           ? `${BE_URL}/getScholarship/${roll_no}`
//           : `${BE_URL}/getEntrance/${roll_no}`;
//         const response = await axios.get(url, {
//           headers: {
//             Authorization: `Bearer ${tokens.accessToken}`,
//           },
//         });

//         if (response.data) {
//           const scholarship = response.data;

//           console.log(scholarship.branch, scholarship.caste);

//           // Parse the date and extract only the date part
//           const dob = new Date(scholarship.date_of_birth);
//           const formattedDob = dob.toISOString().split("T")[0];

//           setbranch(scholarship.branch);
//           setcaste(scholarship.caste);
//           setstream(scholarship.stream);
//           setrollnumber(scholarship.roll_no);
//           setFirstname(toCamelCase(scholarship.first_name.toLowerCase()));
//           setmiddlename(toCamelCase(scholarship.middle_name.toLowerCase()));
//           setlastname(toCamelCase(scholarship.last_name.toLowerCase()));
//           setdob(formattedDob);
//           setfathername(toCamelCase(scholarship.fathers_name.toLowerCase()));
//           setmothername(toCamelCase(scholarship.mothers_name.toLowerCase()));
//           setemail(scholarship.email.toLowerCase());
//           setwhatsapp(scholarship.whatsapp);
//           setAlternateNo(scholarship.alternate_no);
//           setaadhar(scholarship.aadhar);
//           setgender(scholarship.gender);
//           setbloodgroup(scholarship.blood_group);
//           setparentOccupation(scholarship.parent_occupation);
//           setyear_start(scholarship.session);
//           setadmclass(scholarship.adm_class.match(/\d+/)[0]);
//           sethostel(scholarship.hostel === "Yes" ? true : false);

//           setaddress(scholarship.address);
//           setdistrict(scholarship.district);
//           settaluka(scholarship.taluka);
//           setpin(scholarship.pin);
//           setphoto_url(scholarship.photo_url);

//           setscholarshipAmount(scholarship.scholarship_amt);
//           setfees(scholarship.fees);
//           setAdditionalAmount(scholarship.additial_amount);
//           const newClass = scholarship.adm_class.match(/\d+/)[0];
//         }
//       } catch (error) {
//         if (error.response && error.response.status === 401) {
//           // Handle token expiry
//           const newTokens = await refreshToken();
//           if (newTokens) {
//             fetchScholarshipDetail(); // Retry fetching details with new token
//           } else {
//             // Redirect to login or show an error
//             alert("User logged out.");
//             // navigate("/login");
//           }
//         } else {
//           console.error("Error fetching scholarship details:", error);
//         }
//       } finally {
//         setIsLoading(false);
//       }
//     };

//     window.scrollTo(0, 0);

//     if (roll_no && !promotion) {
//       fetchScholarshipDetail();
//     } else if (roll_no && promotion) {
//       fetchStudentDetails();
//     }
//   }, []);

//   const validateForm = () => {
//     const newErrors = {};

//     const requiredFields = [
//       "branch",
//       "firstname",
//       "middlename",
//       "lastname",
//       "fathername",
//       "dob",
//       "mothername",
//       "email",
//       "whatsapp",
//       "alternateNo",
//       "aadhar",
//       "gender",
//       "bloodgroup",
//       "admclass",
//       "address",
//       "district",
//       "taluka",
//       "pin",
//       "year_start",
//     ];

//     for (let field of requiredFields) {
//       if (!eval(field)) {
//         newErrors[field] = "This field is required";
//       }
//     }
//     // for (let field of requiredFields) {
//     //   if (!formData[field]) {
//     //     newErrors[field] = "This field is required";
//     //   }
//     // }

//     // New validations
//     if (whatsapp && whatsapp.length !== 10) {
//       newErrors.whatsapp = "WhatsApp number must be 10 digits";
//     }

//     if (alternateNo && alternateNo.length !== 10) {
//       newErrors.alternateNo = "Alternate number must be 10 digits";
//     }

//     if (aadhar && aadhar.length !== 12) {
//       newErrors.aadhar = "Aadhar number must be 12 digits";
//     }

//     if (pin && pin.length !== 6) {
//       newErrors.pin = "Pin must be 6 digits";
//     }

//     setErrors(newErrors);
//     if (Object.keys(newErrors).length === 0) {
//       setError(null);
//       return true;
//     } else {
//       setError("Please recheck the fields marked in red.");
//       console.log(newErrors);
//       setIsLoading(false);
//       return false;
//     }
//   };

//   const validateInstallments = (instmnts) => {
//     // Check if any installment has a null or undefined account or due_date
//     return instmnts.every(
//       (installment) => installment.account && installment.due_date
//     );
//   };

//   const handleSubmit = async () => {
//     if (!validateForm()) {
//       alert("Please correct the errors before submitting.");
//       return;
//     }

//     if (!admclass) {
//       alert("Please select the class");
//       return;
//     }
//     if (!selectedDivisionId) {
//       alert("Please select the Division");
//       return;
//     }
//     if (hostel && !selectedBedCount) {
//       alert("Please select the bed count.");
//       return;
//     }
//     if (!validateInstallments(installments)) {
//       alert("Please check all the installments - account & due date.");
//       return;
//     }

//     if (!window.confirm("Are you sure you want to submit?")) {
//       return;
//     }

//     setIsLoading(true);
//     setError(null);

//     const studentData = {
//       // Gather all necessary fields from state
//       id: id,
//       is_senior: is_senior,
//       prev_roll_no: roll_no,
//       first_name: firstname,
//       middle_name: middlename,
//       last_name: lastname,
//       date_of_birth: dob,
//       branch: branch,
//       stream: stream,
//       caste: caste,
//       fathers_name: fathername,
//       mothers_name: mothername,
//       email: email,
//       whatsapp: whatsapp,
//       alternate_no: alternateNo,
//       aadhar: aadhar,
//       gender: gender,
//       blood_group: bloodgroup,
//       adm_class: admclass.match(/\d+/)[0],
//       class_id: selectedClassId, // Ensure this is the actual class ID
//       division: division,
//       division_id: selectedDivisionId,
//       academic_year_start: year_start,
//       parent_occupation: parentOccupation,
//       hostel: hostel,
//       bed_count: hostel ? selectedBedCount : null,
//       mess: messRequired,
//       messMonths: messRequired ? messMonths : null,
//       messFees: messRequired ? messFee : null,
//       messPriceId: messRequired ? messPriceId : null,
//       messValidity: messRequired ? messValidityDate : null,
//       transport: transportRequired,
//       transportMonths: transportRequired ? transportMonths : null,
//       transportFees: transportRequired ? transportFee : null,
//       transportPriceId: transportRequired ? transportPriceId : null,
//       transportValidity: transportRequired ? transportValidityDate : null,
//       scholarship_amt: scholarshipAmount,
//       additial_amount: additionalAmount > 0 ? additionalAmount : null,
//       total_fee: totalFees,
//       class_fee: classFee,
//       discounted_fee: reducedTotal,
//       hostel_fees: hostelFee,
//       // Make sure to convert to the correct format if needed
//       address: address,
//       district: district,
//       taluka: taluka,
//       pin: pin,
//       photo_url: photo_url,
//       additional_subject: additional_subject,
//       additional_subject_name: additional_subject
//         ? additional_subject_name
//         : null,

//       installments: installments,
//       // Include any other fields you have in your form
//     };

//     try {
//       let url;
//       if (promotion) {
//         url = is_senior
//           ? `${BE_URL}/api/students/promote/${rollnumber}`
//           : `${BE_URL}/api/students/junior/promote/${rollnumber}`;
//       } else {
//         url = is_senior
//           ? `${BE_URL}/api/students`
//           : `${BE_URL}/api/students/junior`;
//       }

//       console.log("Url is", url);
//       const response = await axios.post(url, studentData, {
//         headers: {
//           Authorization: `Bearer ${tokens.accessToken}`,
//         },
//       });

//       const { roll_no } = response.data;

//       if (promotion) {
//         alert("Student promoted successfully!");
//       } else {
//         alert("Student added successfully!");
//       }

//       if (is_senior) {
//         navigate(`/admin/seniorStudent/${response.data.roll_no}`);
//       } else {
//         navigate(`/admin/juniorStudent/${response.data.roll_no}`);
//       }
//       // Redirect or perform additional actions upon success
//     } catch (error) {
//       setIsLoading(false);
//       if (error.response) {
//         // Check for token expiration
//         console.log(error.response.data);
//         if (error.response.status === 401) {
//           // Handle token refresh
//           const refreshed = await refreshToken();
//           if (refreshed) {
//             handleSubmit(); // Retry submission with new token
//           } else {
//             navigate("/login"); // Redirect to login on token refresh failure
//           }
//         } else if (
//           error.response.data &&
//           error.response.data.code === "23505"
//         ) {
//           // Handle unique constraint violation
//           let detail = error.response.data.detail;
//           if (detail.includes("phone_number")) {
//             alert(
//               "The WhatsApp number already exists in the system. Please use a different number."
//             );
//           } else if (detail.includes("email")) {
//             alert(
//               "The email address already exists in the system. Please use a different email address."
//             );
//           } else if (detail.includes("aadhar")) {
//             alert("Failed - The Aadhar Number already exists in the system!");
//           }
//         } else {
//           // General error handling
//           console.error("Failed to submit form:", error);
//           setError("Failed to create student. Please try again.");
//           alert(
//             "Failed to create student due to an unexpected error. Please try again."
//           );
//         }
//       } else {
//         // Handle errors not related to the HTTP response
//         console.error("Failed to submit form:", error);
//         setError("Failed to create student. Please try again.");
//         alert(
//           "Failed to create student due to an unexpected error. Please try again."
//         );
//       }
//     }
//   };

//   const refreshToken = async () => {
//     try {
//       const tokens = JSON.parse(localStorage.getItem("ERPTokens"));
//       // const response = await fetch("http://localhost:8000/refreshToken", {
//       const response = await fetch("${BE_URL}/refreshToken", {
//         method: "POST",
//         headers: {
//           "Content-Type": "application/json",
//         },
//         body: JSON.stringify({ token: tokens.refreshToken }),
//       });

//       if (!response.ok) {
//         throw new Error("Failed to refresh token");
//       }

//       const newTokens = await response.json();
//       localStorage.setItem("ERPTokens", JSON.stringify(newTokens));
//       return newTokens;
//     } catch (error) {
//       console.error("Error refreshing token:", error);
//       // alert("User loggod out. Please login again");
//       // navigate("/login");
//       // return null;
//     }
//   };

//   const selectDistrict = (val) => {
//     setdistrict(val);
//     const obj = data.find((city) => city.name === val);
//     settalukas(obj.tahasil);
//   };

//   const monthsOptions = Array.from({ length: 11 }, (_, i) => ({
//     label: `${i + 1}`, // Display numbers starting from 1 to 11 as string
//     value: i + 1, // Actual value is the month number from 1 to 11
//   }));

//   const handlePrint = () => {
//     if (isLoading) return;
//     setIsLoading(true);

//     const printContents = printDivRef.current.innerHTML;
//     const originalContents = document.body.innerHTML;

//     document.body.innerHTML = printContents;
//     window.print();
//     document.body.innerHTML = originalContents;
//     setIsLoading(false);
//   };

//   const changeAdditional = (val) => {
//     setadditional_subject(val);
//     setadditional_subject_name(null);
//   };
//   const changeHostel = (val) => {
//     sethostel(val);
//     setSelectedBedCount(null);
//   };

//   useEffect(() => {
//     if (selectedClassId) {
//       setDivision(null);
//       setSelectedDivisionId(null);
//       fetchDivisions(selectedClassId);
//       setinstallments(null);
//     }
//   }, [selectedClassId]);

//   useEffect(() => {
//     setinstallments(null);
//     const matchingStructure = feeStructures.find((structure) => {
//       const matchesClass = structure.class_id === selectedClassId;
//       const matchesHostel = structure.hostel === hostel;
//       const matchesBedCount = structure.bed_count == selectedBedCount;
//       const matchesAdditionalSubject =
//         structure.additional_subject === additional_subject;
//       const matchesAdditionalSubjectName =
//         structure.additional_subject_name === additional_subject_name;
//       return (
//         matchesClass &&
//         matchesHostel &&
//         matchesBedCount &&
//         matchesAdditionalSubject &&
//         matchesAdditionalSubjectName
//       );
//     });

//     // Update installments and fees based on the matching fee structure
//     if (matchingStructure) {
//       setinstallments(matchingStructure.installments);
//       setClassFee(matchingStructure.fee);
//     }
//   }, [
//     hostel,
//     selectedBedCount,
//     additional_subject,
//     additional_subject_name,
//     feeStructures,
//     selectedClassId,
//   ]);

//   return (
//     <div className="asd">
//       {permissions?.can_read ? (
//         <>
//           {isLoading && <Loader />}

//           <div className="asd-cta">
//             <div
//               className="asd-cta-back"
//               onClick={() => navigate("/admin/scholarships")}
//             >
//               &larr; Back
//             </div>
//             <div className="asd-cta-note">
//               {promotion ? "Promotion Form" : "Admission Form"} of {rollnumber}
//             </div>
//             <div className="asd-cta-buttons">
//               <img src={print} onClick={handlePrint} alt="Print" />
//             </div>
//           </div>
//           <div className="asd-header"></div>
//           <div ref={printDivRef} className="asd-contents">
//             {/* <div className="asd-contents-title">
//               Senior Admission Form of {rollnumber}
//             </div> */}
//             <div className="asd-contents-section">
//               <div className="asd-contents-section-title">
//                 Basic Information
//               </div>
//               <div className="asd-contents-section-fields">
//                 <div className="asd-contents-section-fields-field">
//                   <DropDown
//                     invalid={errors.branch}
//                     options={branches}
//                     label={`Branch`}
//                     value={branch}
//                     onChange={setbranch}
//                   />
//                 </div>
//                 <div className="asd-contents-section-fields-field">
//                   <DropDown
//                     invalid={errors.stream}
//                     options={streams}
//                     label={`Stream`}
//                     value={stream}
//                     onChange={setstream}
//                   />
//                 </div>

//                 <div
//                   className={`asd-contents-section-fields-field ${
//                     errors.firstname ? "invalid" : ""
//                   }`}
//                 >
//                   <TextField
//                     placeholder={`First Name`}
//                     value={firstname}
//                     onChange={setFirstname}
//                   />
//                 </div>
//                 <div
//                   className={`asd-contents-section-fields-field ${
//                     errors.middlename ? "invalid" : ""
//                   }`}
//                 >
//                   <TextField
//                     placeholder={`Middle Name`}
//                     value={middlename}
//                     onChange={setmiddlename}
//                   />
//                 </div>
//                 <div
//                   className={`asd-contents-section-fields-field ${
//                     errors.lastname ? "invalid" : ""
//                   }`}
//                 >
//                   <TextField
//                     className={errors.lastname ? "input-error" : ""}
//                     placeholder={`Last Name`}
//                     value={lastname}
//                     onChange={setlastname}
//                   />
//                 </div>
//                 <div className="asd-contents-section-fields-field">
//                   <DatePicker
//                     invalid={errors.dob}
//                     label={`Date of Birth`}
//                     value={dob}
//                     onChange={setdob}
//                   />
//                 </div>
//                 <div
//                   className={`asd-contents-section-fields-field ${
//                     errors.fathername ? "invalid" : ""
//                   }`}
//                 >
//                   <TextField
//                     placeholder={`Father's Name`}
//                     value={fathername}
//                     onChange={setfathername}
//                   />
//                 </div>
//                 <div
//                   className={`asd-contents-section-fields-field ${
//                     errors.mothername ? "invalid" : ""
//                   }`}
//                 >
//                   <TextField
//                     placeholder={`Mother's Name`}
//                     value={mothername}
//                     onChange={setmothername}
//                   />
//                 </div>
//                 <div
//                   className={`asd-contents-section-fields-field ${
//                     errors.email ? "invalid" : ""
//                   }`}
//                 >
//                   <TextField
//                     placeholder={`Email`}
//                     value={email}
//                     onChange={setemail}
//                   />
//                 </div>
//                 <div
//                   className={`asd-contents-section-fields-field ${
//                     errors.whatsapp ? "invalid" : ""
//                   }`}
//                 >
//                   <TextField
//                     placeholder={`WhatsApp No.`}
//                     value={whatsapp}
//                     onChange={setwhatsapp}
//                   />
//                 </div>
//                 <div
//                   className={`asd-contents-section-fields-field ${
//                     errors.alternateNo ? "invalid" : ""
//                   }`}
//                 >
//                   <TextField
//                     placeholder={`Alternate No.`}
//                     value={alternateNo}
//                     onChange={setAlternateNo}
//                   />
//                 </div>
//                 <div
//                   className={`asd-contents-section-fields-field ${
//                     errors.aadhar ? "invalid" : ""
//                   }`}
//                 >
//                   <TextField
//                     placeholder={`Aadhar No.`}
//                     value={aadhar}
//                     onChange={setaadhar}
//                   />
//                 </div>
//                 <div className={`asd-contents-section-fields-field`}>
//                   <DropDown
//                     invalid={errors.year_start}
//                     options={academic_years}
//                     label={`Academic Year`}
//                     value={year_start}
//                     onChange={setyear_start}
//                   />
//                 </div>
//                 {/* <div
//                   className={`asd-contents-section-fields-field ${
//                     errors.year_start ? "invalid" : ""
//                   }`}
//                 >
//                   <TextField
//                     placeholder={`Academic Year Start`}
//                     value={year_start}
//                     onChange={setyear_start}
//                   />
//                 </div> */}
//                 {/* <div
//                   className={`asd-contents-section-fields-field ${
//                     errors.year_end ? "invalid" : ""
//                   }`}
//                 >
//                   <TextField
//                     placeholder={`Academic Year End`}
//                     value={year_end}
//                     onChange={setyear_end}
//                   />
//                 </div> */}
//                 <div className="asd-contents-section-fields-field">
//                   <DropDown
//                     invalid={errors.gender}
//                     options={genders}
//                     label={`Gender`}
//                     value={gender}
//                     onChange={setgender}
//                   />
//                 </div>
//                 <div className="asd-contents-section-fields-field">
//                   <DropDown
//                     invalid={errors.bloodgroup}
//                     options={bloodgroups}
//                     label={`Blood Group`}
//                     value={bloodgroup}
//                     onChange={setbloodgroup}
//                   />
//                 </div>
//                 <div className="asd-contents-section-fields-field">
//                   <DropDown
//                     invalid={errors.caste}
//                     options={casteOptions}
//                     label={`Caste`}
//                     value={caste}
//                     onChange={setcaste}
//                   />
//                 </div>
//                 <div
//                   className={`scholarship-contents-section-fields-field ${
//                     errors.parentOccupation ? "invalid" : ""
//                   }`}
//                 >
//                   <TextField
//                     placeholder={`Parent Occupation`}
//                     value={parentOccupation}
//                     onChange={setparentOccupation}
//                   />
//                 </div>
//                 {/* <div className="asd-contents-section-fields-field"></div> */}
//               </div>
//             </div>

//             <hr className="asd-contents-seperator"></hr>

//             {/* Address */}
//             <div className="asd-contents-section">
//               <div className="asd-contents-section-title">Address</div>
//               <div className="asd-contents-section-fields">
//                 <div
//                   className={`asd-contents-section-fields-field ${
//                     errors.address ? "invalid" : ""
//                   }`}
//                 >
//                   <TextField
//                     placeholder={`Address`}
//                     value={address}
//                     onChange={setaddress}
//                   />
//                 </div>
//                 <div className="asd-contents-section-fields-field">
//                   <DropDown
//                     invalid={errors.district}
//                     options={districts}
//                     label={`District`}
//                     value={district}
//                     onChange={selectDistrict}
//                   />
//                 </div>
//                 <div className="asd-contents-section-fields-field">
//                   <DropDown
//                     invalid={errors.taluka}
//                     options={talukas}
//                     label={`Taluka`}
//                     value={taluka}
//                     onChange={settaluka}
//                   />
//                 </div>
//                 <div
//                   className={`asd-contents-section-fields-field ${
//                     errors.pin ? "invalid" : ""
//                   }`}
//                 >
//                   <TextField
//                     placeholder={`Pin`}
//                     value={pin}
//                     onChange={setpin}
//                   />
//                 </div>
//                 <div className="asd-contents-section-fields-field"></div>
//               </div>
//             </div>

//             <hr className="asd-contents-seperator"></hr>

//             <div className="asd-contents-section">
//               <div className="asd-contents-section-title">Mess & Transport</div>
//               <div className="asd-contents-section-fields">
//                 <div className="asd-contents-section-fields-field">
//                   <ObjectDropDown
//                     label="Mess Required"
//                     options={[
//                       { value: "Yes", label: true },
//                       { value: "No", label: false },
//                     ].map(({ label, value }) => ({
//                       label: label,
//                       value: value,
//                     }))}
//                     value={messRequired}
//                     setValue={(val) =>
//                       val === "Yes"
//                         ? setMessRequired(true)
//                         : setMessRequired(false)
//                     }
//                     setId={() => {}}
//                   />
//                 </div>

//                 {messRequired && (
//                   <>
//                     <div className="asd-contents-section-fields-field">
//                       <CustomDropDown
//                         label="Mess Type"
//                         data={messOptions}
//                         value={messPriceId}
//                         onChange={handleMessSelection}
//                         searchable={false}
//                       />
//                     </div>
//                     <div className="asd-contents-section-fields-field">
//                       <ObjectDropDown
//                         label="Number of Months"
//                         options={monthsOptions}
//                         value={messMonths}
//                         setId={() => {}}
//                         setValue={setMessMonths}
//                       />
//                     </div>
//                     <div className="asd-contents-section-fields-field">
//                       <DatePicker
//                         label="Mess Validity Date"
//                         value={messValidityDate}
//                         onChange={setMessValidityDate}
//                       />
//                     </div>
//                   </>
//                 )}
//                 <div className="asd-contents-section-fields-field">
//                   <ObjectDropDown
//                     label="Transport Required"
//                     options={[
//                       { value: "Yes", label: true },
//                       { value: "No", label: false },
//                     ].map(({ label, value }) => ({
//                       label: label,
//                       value: value,
//                     }))}
//                     value={transportRequired}
//                     setValue={(val) =>
//                       val === "Yes"
//                         ? setTransportRequired(true)
//                         : setTransportRequired(false)
//                     }
//                     setId={() => {}}
//                   />
//                 </div>
//                 {transportRequired && (
//                   <>
//                     <div className="asd-contents-section-fields-field">
//                       <CustomDropDown
//                         label="Transport Type"
//                         data={transportOptions}
//                         value={transportPriceId}
//                         onChange={handleTransportSelection}
//                       />
//                     </div>
//                     <div className="asd-contents-section-fields-field">
//                       <ObjectDropDown
//                         label="Number of Months"
//                         options={monthsOptions}
//                         value={transportMonths}
//                         setId={() => {}}
//                         setValue={setTransportMonths}
//                       />
//                     </div>
//                     <div className="asd-contents-section-fields-field">
//                       <DatePicker
//                         label="Transport Validity Date"
//                         value={transportValidityDate}
//                         onChange={setTransportValidityDate}
//                       />
//                     </div>
//                   </>
//                 )}

//                 <div>
//                   {messRequired && messMonths ? (
//                     <div className="asd-contents-section-fees">
//                       Mess fees is {messFee}. Add it to the installments
//                       manually.
//                     </div>
//                   ) : null}
//                 </div>
//                 {transportRequired && transportMonths ? (
//                   <div className="asd-contents-section-fees">
//                     Transport fees is {transportFee}. Add it to the installments
//                     manually.
//                   </div>
//                 ) : null}
//               </div>
//             </div>

//             <hr className="asd-contents-seperator"></hr>

//             {/* Admission Related Information */}
//             <div className="asd-contents-section">
//               <div className="asd-contents-section-title">
//                 Admission Related Information
//               </div>
//               <div className="asd-contents-section-fields">
//                 {/* <div className="asd-contents-section-fields-field">
//                   <CustomNumberInput
//                     value={additionalAmount}
//                     label={`Additional Discount`}
//                     placeholder={`Additional discount`}
//                     onChange={(e) => setAdditionalAmount(e)}
//                   />
//                 </div> */}
//                 <div className="asd-contents-section-fields-field">
//                   <ObjectDropDown
//                     label="Select Class"
//                     options={classes.map(({ class_name, id }) => ({
//                       label: id,
//                       value: class_name,
//                     }))}
//                     value={admclass}
//                     setId={setSelectedClassId}
//                     setValue={setadmclass}
//                   />
//                 </div>
//                 <div className="asd-contents-section-fields-field">
//                   <ObjectDropDown
//                     label="Select Division"
//                     options={divisions.map(({ name, id }) => ({
//                       label: id,
//                       value: name,
//                     }))}
//                     value={division}
//                     setId={setSelectedDivisionId}
//                     setValue={setDivision}
//                   />
//                 </div>

//                 <div className="asd-contents-section-fields-field">
//                   <ObjectDropDown
//                     label="Opting for Hostel"
//                     options={[
//                       { value: "Yes", label: true },
//                       { value: "No", label: false },
//                     ].map(({ label, value }) => ({
//                       label: label,
//                       value: value,
//                     }))}
//                     value={hostel}
//                     setId={() => {}}
//                     setValue={(val) =>
//                       val === "Yes" ? sethostel(true) : changeHostel(false)
//                     }
//                   />
//                 </div>
//                 {hostel && (
//                   <div className="asd-contents-section-fields-field">
//                     <ObjectDropDown
//                       label="Bed Count"
//                       options={[
//                         { value: 4, label: 4 },
//                         { value: 5, label: 5 },
//                         { value: 6, label: 6 },
//                         { value: "hostel", label: "hostel" },
//                       ].map(({ label, value }) => ({
//                         label: label,
//                         value: value,
//                       }))}
//                       value={selectedBedCount}
//                       setValue={setSelectedBedCount}
//                       setId={() => {}}
//                     />
//                   </div>
//                 )}

//                 {is_senior && (
//                   <>
//                     <div className="asd-contents-section-fields-field">
//                       <ObjectDropDown
//                         label="Additional Subject?"
//                         options={[
//                           { value: "Yes", label: true },
//                           { value: "No", label: false },
//                         ].map(({ label, value }) => ({
//                           label: label,
//                           value: value,
//                         }))}
//                         value={additional_subject}
//                         setValue={(val) =>
//                           val === "Yes"
//                             ? setadditional_subject(true)
//                             : changeAdditional(false)
//                         }
//                         setId={() => {}}
//                       />
//                     </div>
//                     {additional_subject && (
//                       <div className="asd-contents-section-fields-field">
//                         <ObjectDropDown
//                           label="Additional Subject Name"
//                           options={[
//                             { value: "I.T.", label: "I.T." },
//                             { value: "Crop Science", label: "Crop Science" },
//                           ].map(({ label, value }) => ({
//                             label: label,
//                             value: value,
//                           }))}
//                           value={additional_subject_name}
//                           setValue={setadditional_subject_name}
//                           setId={() => {}}
//                         />
//                       </div>
//                     )}
//                   </>
//                 )}

//                 {/* Displaying Class Fee and Installments */}
//                 {installments && installments.length > 0 ? (
//                   <div className="asd-contents-section-fields-inst">
//                     <InstallmentsWithDiscountsDisplay
//                       title="Class Fee Details"
//                       fee={totalFees}
//                       installments={installments}
//                       setInstallments={setinstallments}
//                       scholarshipAmount={scholarshipAmount}
//                       additial_amount={additionalAmount}
//                     />

//                     {/* <InstallmentsStudentDetailsEdit
//                       session={year_start}
//                       student_id={id}
//                       student_roll_no={roll_no}
//                       title="Class Fee Details"
//                       fee={totalFees}
//                       installments={installments}
//                       setInstallments={setinstallments}
//                       scholarshipAmount={scholarshipAmount}
//                       additial_amount={additionalAmount}
//                       finalFee={reducedTotal}
//                       handleRefresh={handleRefresh}
//                     /> */}
//                   </div>
//                 ) : (
//                   "No Fees structure found for the current selections."
//                 )}

//                 <div className="asd-contents-section-fields-field"></div>
//               </div>
//             </div>

//             <hr className="asd-contents-seperator"></hr>

//             {/* {is_senior && <hr className="asd-contents-seperator"></hr>} */}

//             {error && <div className="asd-error">{error}</div>}
//           </div>

//           {permissions?.can_update && (
//             <div className="asd-button">
//               <Button text={`Submit`} onClick={handleSubmit} />
//             </div>
//           )}
//         </>
//       ) : (
//         ""
//       )}
//     </div>
//   );
// };

// export default AdmissionForm;
