import React, { useState, useEffect } from "react";
import axios from "axios";
import { jwtDecode } from "jwt-decode";
import "./AssignedInventory.scss";

const BE_URL = "https://server.classerp.in";

const AssignedInventory = ({ type, admin = false, id = null }) => {
  const [assignments, setAssignments] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  // Function to refresh the access token
  const refreshToken = async () => {
    try {
      const tokens = JSON.parse(localStorage.getItem("ERPTokens"));
      const response = await axios.post(`${BE_URL}/refreshToken`, {
        refreshToken: tokens.refreshToken,
      });
      localStorage.setItem("ERPTokens", JSON.stringify(response.data));
      return response.data.accessToken;
    } catch (error) {
      console.error("Error refreshing token:", error);
      setError("Session expired. Please login again.");
      return null;
    }
  };

  const fetchAssignedProducts = async () => {
    setIsLoading(true);
    setError(null);

    try {
      const tokens = JSON.parse(localStorage.getItem("ERPTokens"));
      let userId;

      if (!admin) {
        const decodedToken = jwtDecode(tokens.accessToken);
        userId = decodedToken.user_id;
      } else {
        userId = id;
      }

      const [productsResponse, devicesResponse] = await Promise.all([
        axios.get(`${BE_URL}/api/general/assignedProducts/${type}/${userId}`, {
          headers: {
            Authorization: `Bearer ${tokens.accessToken}`,
          },
        }),
        axios.get(
          `${BE_URL}/api/general/assignedProductDevices/${type}/${userId}`,
          {
            headers: {
              Authorization: `Bearer ${tokens.accessToken}`,
            },
          }
        ),
      ]);

      // Combining the two arrays of data and sort by assign date (newest first)
      const totalData = [
        ...productsResponse.data,
        ...devicesResponse.data,
      ].sort((a, b) => new Date(b.assign_date) - new Date(a.assign_date));

      setAssignments(totalData);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        const newAccessToken = await refreshToken();
        if (newAccessToken) {
          fetchAssignedProducts();
        }
      } else {
        console.error("Error fetching assigned products:", error);
        setError("Failed to load inventory items. Please try again.");
      }
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchAssignedProducts();
  }, [type, admin, id]);

  // Format date in a more readable way
  const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    return new Intl.DateTimeFormat("en-US", {
      year: "numeric",
      month: "short",
      day: "numeric",
    }).format(date);
  };

  // Calculate how long the item has been assigned
  const getTimeAssigned = (assignDate) => {
    const assignedDate = new Date(assignDate);
    const currentDate = new Date();

    // Calculate difference in days
    const diffTime = Math.abs(currentDate - assignedDate);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

    if (diffDays === 1) return "1 day";
    if (diffDays < 30) return `${diffDays} days`;

    const months = Math.floor(diffDays / 30);
    if (months === 1) return "1 month";
    if (months < 12) return `${months} months`;

    const years = Math.floor(months / 12);
    const remainingMonths = months % 12;

    if (remainingMonths === 0) {
      return years === 1 ? "1 year" : `${years} years`;
    }

    return years === 1
      ? `1 year, ${remainingMonths} ${
          remainingMonths === 1 ? "month" : "months"
        }`
      : `${years} years, ${remainingMonths} ${
          remainingMonths === 1 ? "month" : "months"
        }`;
  };

  // Get appropriate icon class based on item name
  const getItemIconClass = (itemName) => {
    const lowerName = itemName.toLowerCase();

    if (lowerName.includes("laptop") || lowerName.includes("computer"))
      return "icon-laptop";
    if (lowerName.includes("phone") || lowerName.includes("mobile"))
      return "icon-phone";
    if (lowerName.includes("tablet")) return "icon-tablet";
    if (lowerName.includes("printer")) return "icon-printer";
    if (lowerName.includes("projector")) return "icon-projector";
    if (lowerName.includes("camera")) return "icon-camera";
    if (lowerName.includes("headphone") || lowerName.includes("headset"))
      return "icon-headphones";
    if (lowerName.includes("keyboard")) return "icon-keyboard";
    if (lowerName.includes("mouse")) return "icon-mouse";
    if (lowerName.includes("monitor") || lowerName.includes("display"))
      return "icon-monitor";

    return "icon-device";
  };

  if (isLoading) {
    return (
      <div className="inventory-loading">
        <div className="loading-spinner"></div>
        <p>Loading inventory items...</p>
      </div>
    );
  }

  if (error) {
    return (
      <div className="inventory-error">
        <div className="error-icon"></div>
        <p>{error}</p>
        <button onClick={fetchAssignedProducts} className="retry-button">
          Retry
        </button>
      </div>
    );
  }

  if (!assignments.length) {
    return (
      <div className="inventory-empty">
        <div className="empty-icon"></div>
        <p>No inventory items assigned.</p>
      </div>
    );
  }

  return (
    <div className="assigned-inventory">
      {assignments.map((assignment) => (
        <div key={assignment.id} className="inventory-card">
          <div className="inventory-icon">
            <div className={getItemIconClass(assignment.name)}></div>
          </div>

          <div className="inventory-details">
            <div className="inventory-header">
              <h3 className="inventory-name">{assignment.name}</h3>
              <span className="time-assigned">
                {getTimeAssigned(assignment.assign_date)}
              </span>
            </div>

            {assignment.serial_no && (
              <div className="inventory-serial">
                <span className="serial-label">Serial No:</span>
                <span className="serial-value">{assignment.serial_no}</span>
              </div>
            )}

            <div className="inventory-meta">
              <div className="assign-date">
                <span className="date-label">Assigned on:</span>
                <span className="date-value">
                  {formatDate(assignment.assign_date)}
                </span>
              </div>
            </div>

            {assignment.remarks && (
              <div className="inventory-remarks">
                <p>{assignment.remarks}</p>
              </div>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

export default AssignedInventory;
