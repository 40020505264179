import "./AdminScholarshipDetail.scss";

import user from "../../assets/images/User.png";
import print from "../../assets/images/Print.png";
import deleteicon from "../../assets/images/Delete.png";

import data from "../../assets/data/districtData";

import React, { useEffect, useRef, useState } from "react";
import TextField from "../../components/TextField/TextField";
import DropDown from "../../components/DropDown/DropDown";
import DatePicker from "../../components/DatePicker/DatePicker";
import Button from "../../components/Button/Button";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import Loader from "../../components/Loader/Loader";
import { jwtDecode } from "jwt-decode";

import { NumberInput, Text } from "@mantine/core";
import { numberToWords } from "number-to-words";
import CustomNumberInput from "../../components/CustomNumberInput/CustomNumberInput";
import CustomTextInput from "../../components/CustomTextInput/CustomTextInput";
import { useSelector } from "react-redux";
import Modal from "../../components/Modal/Modal";
import ResponseModal from "../../components/ResponseModal/ResponseModal";
import CustomDropDown from "../../components/CustomDropDown/CustomDropDown";

// const BE_URL = "http://localhost:8000";
const BE_URL = "https://server.classerp.in";

const AdminScholarshipDetail = () => {
  //RBAC code block
  const allPermissions = useSelector((state) => state.permissions);
  const permissions = allPermissions
    .find((p) => p.name === "Admissions")
    ?.dropdowns.find((dd) => dd.name === "Scholarship List")?.permissions;

  //Additional Scholarship Permissions
  const addsch = allPermissions
    .find((p) => p.name === "Admissions")
    ?.dropdowns.find((dd) => dd.name === "Additional Scholarship")?.permissions;

  const schcomments = allPermissions
    .find((p) => p.name === "Admissions")
    ?.dropdowns.find(
      (dd) => dd.name === "Scholarship Councellor Comments"
    )?.permissions;

  const admCreate = allPermissions
    .find((p) => p.name === "Students")
    ?.dropdowns.find((dd) => dd.name === "Senior Students")?.permissions;

  //RBAC Code block ends.

  const navigate = useNavigate();
  const printDivRef = useRef();
  const { roll_no } = useParams();

  const tokens = JSON.parse(localStorage.getItem("ERPTokens"));
  const decodedToken = tokens ? jwtDecode(tokens.accessToken) : null;
  const roleId = decodedToken ? decodedToken.role_id : null;

  const canView = (allowedRoles) => {
    return allowedRoles.includes(roleId);
  };

  const [districts, setdistricts] = useState([]);
  const [talukas, settalukas] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  const [submitted, setSubmitted] = useState(false);
  const [rollnumber, setrollnumber] = useState(null);

  const [session, setSession] = useState(null);
  const [parentOccupation, setparentOccupation] = useState(null);
  const [reference, setReference] = useState(null);
  const [id, setId] = useState(null);
  const [firstname, setFirstname] = useState(null);
  const [middlename, setmiddlename] = useState(null);
  const [lastname, setlastname] = useState(null);
  const [dob, setdob] = useState(null);
  const [fathername, setfathername] = useState(null);
  const [mothername, setmothername] = useState(null);
  const [email, setemail] = useState(null);
  const [whatsapp, setwhatsapp] = useState(null);
  const [alternateNo, setAlternateNo] = useState(null);
  const [aadhar, setaadhar] = useState(null);
  const [gender, setgender] = useState(null);
  const [stream, setstream] = useState(null);
  const [bloodgroup, setbloodgroup] = useState(null);

  const [admclass, setadmclass] = useState(null);
  const [hostel, sethostel] = useState(false);

  const [school, setschool] = useState(null);
  const [board, setboard] = useState(null);
  const [medium, setmedium] = useState(null);
  const [prevmaths, setprevmaths] = useState(null);
  const [prevscience, setprevscience] = useState(null);

  const [error, setError] = useState(null);
  const [errors, setErrors] = useState({});

  //Other Exams
  const [otherExams, setOtherExams] = useState(null);
  const [exam1, setexam1] = useState(null);
  const [exam2, setexam2] = useState(null);
  const [exam3, setexam3] = useState(null);
  //   const [exam4, setexam4] = useState("Select Exam");
  const [exam1Marks, setexam1Marks] = useState(null);
  const [exam2Marks, setexam2Marks] = useState(null);
  const [exam3Marks, setexam3Marks] = useState(null);
  //   const [exam4Marks, setexam4Marks] = useState(null);

  const [address, setaddress] = useState(null);
  const [district, setdistrict] = useState(null);
  const [taluka, settaluka] = useState(null);
  const [pin, setpin] = useState(null);
  const [admitted, setadmitted] = useState(null);
  const [adm_roll_no, setadm_roll_no] = useState(null);
  const [photo_url, setphoto_url] = useState(null);
  const [file, setFile] = useState(null);

  const [examDate, setexamDate] = useState(null);
  const [testID, settestID] = useState(null);
  const [part1, setpart1] = useState(null);
  const [part2, setpart2] = useState(null);
  const [part3, setpart3] = useState(null);
  const [total, settotal] = useState(null);
  const [percent, setpercent] = useState(null);
  const [amount, setamount] = useState(null);
  const [additionalAmount, setAdditionalAmount] = useState("");
  const [additionalAmountReason, setAdditionalAmountReason] = useState("");
  const [newAdditionalAmount, setNewAdditionalAmount] = useState("");
  const [newAdditionalAmountReason, setNewAdditionalAmountReason] =
    useState("");
  const [additionalAmountUser, setAdditionalAmountUser] = useState("");

  const [comments, setComments] = useState([]);
  const [newComment, setNewComment] = useState("");

  const [msg, setmsg] = useState(null);
  const [responseType, setresponseType] = useState(null);
  const [isResponseOpen, setisResponseOpen] = useState(false);

  const [approvalDetails, setApprovalDetails] = useState(null);
  const [isImageModalOpen, setIsImageModalOpen] = useState(false);
  const [branch, setBranch] = useState(null);
  const [caste, setcaste] = useState(null);

  const casteOptions = ["General/Open", "OBC", "SC", "ST", "EWS", "Others"];

  const refOptions = ["Facebook", "Newspaper", "Friend", "Website", "Others"];
  const branches = ["Baramati", "Pune", "Nagar", "Ranchi"];
  const bloodgroups = ["A+", "A-", "B+", "B-", "AB+", "AB-", "O+", "O-"];
  const streams = ["Engineering", "Medical"];
  const genders = ["Male", "Female"];
  const boards = ["ICSE", "CBSE", "SSC"];
  const mediums = ["English", "Marathi", "Semi-English"];
  const examOptions = [
    "MHCET",
    "NTSE",
    "8th Std Scholarship exam",
    "5th Std Scholarship exam",
    "Olympiad",
    "Other",
  ];

  const bool = ["Yes", "No"];

  const classOptions = [
    "Class 6th",
    "Class 7th",
    "Class 8th",
    "Class 9th",
    "Class 10th",
    "Class 11th",
    "Class 12th",
    "Neet Repeater",
    "JEE Repeater",
  ];

  const biharJharkhandDistricts = [
    // Bihar Districts
    "Araria",
    "Arwal",
    "Aurangabad",
    "Banka",
    "Begusarai",
    "Bhagalpur",
    "Bhojpur",
    "Buxar",
    "Darbhanga",
    "East Champaran",
    "Gaya",
    "Gopalganj",
    "Jamui",
    "Jehanabad",
    "Kaimur",
    "Katihar",
    "Khagaria",
    "Kishanganj",
    "Lakhisarai",
    "Madhepura",
    "Madhubani",
    "Munger",
    "Muzaffarpur",
    "Nalanda",
    "Nawada",
    "Patna",
    "Purnia",
    "Rohtas",
    "Saharsa",
    "Samastipur",
    "Saran",
    "Sheikhpura",
    "Sheohar",
    "Sitamarhi",
    "Siwan",
    "Supaul",
    "Vaishali",
    "West Champaran",

    // Jharkhand Districts
    "Bokaro",
    "Chatra",
    "Deoghar",
    "Dhanbad",
    "Dumka",
    "East Singhbhum",
    "Garhwa",
    "Giridih",
    "Godda",
    "Gumla",
    "Hazaribagh",
    "Jamtara",
    "Khunti",
    "Koderma",
    "Latehar",
    "Lohardaga",
    "Pakur",
    "Palamu",
    "Ramgarh",
    "Ranchi",
    "Sahebganj",
    "Seraikela-Kharsawan",
    "Simdega",
    "West Singhbhum",
  ];

  const sessionOptions = ["2024-2025", "2025-2026", "2026-2027"];

  useEffect(() => {
    if (isLoading) return;
    setIsLoading(true);

    let cities = [];
    data.map((city) => cities.push(city.name));
    setdistricts(cities);

    if (roll_no) {
      fetchScholarshipDetail();
      fetchComments();
      fetchApprovalDetails();
    }
  }, []);

  const fetchScholarshipDetail = async () => {
    try {
      const tokens = JSON.parse(localStorage.getItem("ERPTokens"));
      const response = await axios.get(`${BE_URL}/getScholarship/${roll_no}`, {
        headers: {
          Authorization: `Bearer ${tokens.accessToken}`,
        },
      });

      if (response.data) {
        const scholarship = response.data;

        // Parse the date and extract only the date part
        const dob = new Date(scholarship.date_of_birth);
        const formattedDob = dob.toISOString().split("T")[0];

        setId(scholarship.id);
        setBranch(scholarship.branch);
        setSession(scholarship.session);
        setReference(scholarship.reference);
        setrollnumber(scholarship.roll_no);
        setFirstname(scholarship.first_name);
        setmiddlename(scholarship.middle_name);
        setlastname(scholarship.last_name);
        setdob(formattedDob);
        setfathername(scholarship.fathers_name);
        setmothername(scholarship.mothers_name);
        setemail(scholarship.email);
        setwhatsapp(scholarship.whatsapp);
        setAlternateNo(scholarship.alternate_no);
        setaadhar(scholarship.aadhar);
        setgender(scholarship.gender);
        setstream(scholarship.stream);
        setcaste(scholarship.caste);
        setparentOccupation(scholarship.parent_occupation);
        setbloodgroup(scholarship.blood_group);
        setadmclass(scholarship.adm_class);
        sethostel(scholarship.hostel);
        setschool(scholarship.school);
        setboard(scholarship.board);
        setmedium(scholarship.medium);
        setprevmaths(scholarship.prev_math);
        setprevscience(scholarship.prev_sci);
        setOtherExams(scholarship.other_exam);
        setexam1(scholarship.exam1);
        setexam1Marks(scholarship.exam1_marks);
        setexam2(scholarship.exam2);
        setexam2Marks(scholarship.exam2_marks);
        setexam3(scholarship.exam3);
        setexam3Marks(scholarship.exam3_marks);
        setaddress(scholarship.address);
        setdistrict(scholarship.district);
        settaluka(scholarship.taluka);
        setpin(scholarship.pin);
        setadmitted(scholarship.is_admitted);
        setadm_roll_no(scholarship.adm_roll_no);
        setphoto_url(scholarship.photo_url);

        setexamDate(scholarship.exam_date);
        settestID(scholarship.test_id);
        setpart1(scholarship.part1);
        setpart2(scholarship.part2);
        setpart3(scholarship.part3);
        settotal(scholarship.total_marks);
        setamount(scholarship.scholarship_amt);
        setpercent(scholarship.percent);
        setAdditionalAmount(scholarship.additial_amount);
        setAdditionalAmountReason(scholarship.additial_amount_reason);
        setAdditionalAmountUser(scholarship.additial_amount_user);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // Handle token expiry
        const newTokens = await refreshToken();
        if (newTokens) {
          fetchScholarshipDetail(); // Retry fetching details with new token
        } else {
          // Redirect to login or show an error
          alert("User logged out.");
          // navigate("/login");
        }
      } else {
        console.error("Error fetching scholarship details:", error);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const validateForm = () => {
    const newErrors = {};

    const requiredFields = [
      "branch",
      "session",
      "firstname",
      "middlename",
      "lastname",
      "fathername",
      "dob",
      "mothername",
      "email",
      "whatsapp",
      "alternateNo",
      "aadhar",
      "gender",
      "caste",
      "bloodgroup",
      "admclass",
      "hostel",
      "school",
      "board",
      "medium",
      "prevmaths",
      "prevscience",
      "address",
      "district",
      "pin",
    ];

    if (branch === "Baramati") {
      requiredFields.push("taluka");
    }

    for (let field of requiredFields) {
      if (!eval(field)) {
        newErrors[field] = "This field is required";
      }
    }

    // New validations
    if (whatsapp && whatsapp.length !== 10) {
      newErrors.whatsapp = "WhatsApp number must be 10 digits";
    }

    if (alternateNo && alternateNo.length !== 10) {
      newErrors.alternateNo = "Alternate number must be 10 digits";
    }

    if (aadhar && aadhar.length !== 12) {
      newErrors.aadhar = "Aadhar number must be 12 digits";
    }

    if (pin && pin.length !== 6) {
      newErrors.pin = "Pin must be 6 digits";
    }

    setErrors(newErrors);
    if (Object.keys(newErrors).length === 0) {
      setError(null);
      return true;
    } else {
      setError("Please recheck the fields marked in red.");
      setIsLoading(false);
      return false;
    }
  };

  const handleEdit = async () => {
    if (!window.confirm("Are you sure you want to save the changes?")) {
      return;
    }
    if (isLoading) return;
    setIsLoading(true);
    if (validateForm()) {
      // Prepare data to be sent to the server
      const data = {
        branch,
        session,
        stream,
        reference,
        rollnumber,
        firstname,
        middlename,
        lastname,
        dob,
        fathername,
        mothername,
        email,
        whatsapp,
        alternateNo,
        aadhar,
        gender,
        caste,
        bloodgroup,
        parentOccupation,
        admclass,
        hostel,
        school,
        board,
        medium,
        prevmaths,
        prevscience,
        otherExams,
        exam1,
        exam2,
        exam3,
        exam1Marks,
        exam2Marks,
        exam3Marks,
        address,
        district,
        taluka,
        pin,
      };

      try {
        const tokens = JSON.parse(localStorage.getItem("ERPTokens"));
        await axios.put(`${BE_URL}/updateScholarship/${rollnumber}`, data, {
          headers: {
            Authorization: `Bearer ${tokens.accessToken}`,
          },
        });
        // Handle success (navigate back or show success message)
      } catch (error) {
        if (error.response && error.response.status === 401) {
          // Token expired, refresh token
          const newTokens = await refreshToken();
          if (newTokens) {
            return handleEdit(); // Retry submission with new token
          }
        } else {
          console.error("Error updating scholarship:", error);
          setError("Failed to update scholarship. Please try again.");
        }
      } finally {
        setIsLoading(false);
      }
    } else {
      setIsLoading(false);
    }
  };

  const moveToEntrance = async () => {
    if (
      !window.confirm(
        "Are you sure you want to move the student data to Entrance Category?"
      )
    ) {
      return;
    }
    if (isLoading) return;
    setIsLoading(true);
    if (validateForm()) {
      // Prepare data to be sent to the server
      const data = {
        branch,
        session,
        reference,
        rollnumber,
        firstname,
        middlename,
        lastname,
        dob,
        fathername,
        mothername,
        email,
        whatsapp,
        alternateNo,
        aadhar,
        gender,
        stream,
        caste,
        bloodgroup,
        parentOccupation,
        admclass,
        hostel,
        school,
        board,
        medium,
        prevmaths,
        prevscience,
        otherExams,
        exam1,
        exam2,
        exam3,
        exam1Marks,
        exam2Marks,
        exam3Marks,
        address,
        district,
        taluka,
        photo_url,
        pin,
      };

      try {
        const tokens = JSON.parse(localStorage.getItem("ERPTokens"));
        await axios.post(`${BE_URL}/moveToEntrance/${rollnumber}`, data, {
          headers: {
            Authorization: `Bearer ${tokens.accessToken}`,
          },
        });
        navigate("/admin/entrances");
        // Handle success (navigate back or show success message)
      } catch (error) {
        if (error.response && error.response.status === 401) {
          // Token expired, refresh token
          const newTokens = await refreshToken();
          if (newTokens) {
            return moveToEntrance(); // Retry submission with new token
          }
        } else {
          console.error("Error updating scholarship:", error);
          setError("Failed to update scholarship. Please try again.");
        }
      } finally {
        setIsLoading(false);
      }
    } else {
      setIsLoading(false);
    }
  };

  const refreshToken = async () => {
    try {
      const tokens = JSON.parse(localStorage.getItem("ERPTokens"));
      const response = await fetch(`${BE_URL}/refreshToken`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ token: tokens.refreshToken }),
      });

      if (!response.ok) {
        throw new Error("Failed to refresh token");
      }

      const newTokens = await response.json();
      localStorage.setItem("ERPTokens", JSON.stringify(newTokens));
      return newTokens;
    } catch (error) {
      console.error("Error refreshing token:", error);
    }
  };

  const handleDelete = async () => {
    if (isLoading) return;
    if (
      !window.confirm(
        "Are you sure you want to delete this scholarship entry and all its related comments?"
      )
    ) {
      return;
    }

    setIsLoading(true);

    try {
      const tokens = JSON.parse(localStorage.getItem("ERPTokens"));
      await axios.delete(`${BE_URL}/deleteScholarship/${rollnumber}`, {
        headers: {
          Authorization: `Bearer ${tokens.accessToken}`,
        },
      });

      // Navigate back or refresh the page after deletion
      alert("Scholarship form successfully deleted.");
      navigate("/admin/scholarships");
    } catch (error) {
      if (error.response && error.response.status === 401) {
        const newTokens = await refreshToken();
        if (newTokens) {
          handleDelete(); // Retry with new token
        }
      } else {
        console.error("Error deleting scholarship:", error);
        setError("Failed to delete scholarship. Please try again.");
      }
    } finally {
      setIsLoading(false);
    }
  };

  const fetchComments = async () => {
    if (isLoading) return;
    setIsLoading(true);
    try {
      const tokens = JSON.parse(localStorage.getItem("ERPTokens"));
      const response = await axios.get(`${BE_URL}/getComments/${roll_no}`, {
        headers: {
          Authorization: `Bearer ${tokens.accessToken}`,
        },
      });
      setComments(response.data);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        const newTokens = await refreshToken();
        if (newTokens) {
          fetchComments(); // Retry with new token
        }
      } else {
        alert("Error fetching the comments.");
        console.error("Error fetching comments:", error);
      }
    }
    setIsLoading(false);
  };

  const handleAddComment = async () => {
    if (!window.confirm("Are you sure you want to post a comment?")) {
      return;
    }
    if (isLoading) return;
    setIsLoading(true);
    try {
      const tokens = JSON.parse(localStorage.getItem("ERPTokens"));

      const obj = jwtDecode(tokens.accessToken);
      const { user_id } = obj;

      await axios.post(
        `${BE_URL}/addComment`,
        { roll_no, text: newComment, user_id: user_id },
        {
          headers: {
            Authorization: `Bearer ${tokens.accessToken}`,
          },
        }
      );
      setNewComment("");
      fetchComments(); // Refresh comments
    } catch (error) {
      if (error.response && error.response.status === 401) {
        const newTokens = await refreshToken();
        if (newTokens) {
          handleAddComment(); // Retry with new token
        }
      } else {
        console.error("Error posting comment:", error);
      }
    }
    setIsLoading(false);
  };

  const handleExtraScholarshipSubmit = async () => {
    if (!newAdditionalAmount || !newAdditionalAmountReason) {
      alert("Please fill in all fields.");
      return;
    }

    if (
      !window.confirm("Are you sure you want to give additional scholarship?")
    ) {
      return;
    }
    setIsLoading(true);

    try {
      const tokens = JSON.parse(localStorage.getItem("ERPTokens"));
      const response = await axios.post(
        `${BE_URL}/updateExtraScholarship/${rollnumber}`,
        {
          name: `${firstname} ${middlename} ${lastname}`,
          newAdditionalAmount,
          newAdditionalAmountReason,
          additionalAmountUser: jwtDecode(tokens.accessToken).user_id,
        },
        {
          headers: {
            Authorization: `Bearer ${tokens.accessToken}`,
          },
        }
      );
      if (response.status === 201) {
        window.scrollTo(0, 0);
        setresponseType("Error");
        setmsg(
          "The amount is higher than the limit. OPTs are sent for approval."
        );
        setisResponseOpen(true);

        fetchApprovalDetails();
      } else if (response.status === 200) {
        window.scrollTo(0, 0);
        setresponseType("Success");
        setmsg("Extra scholarship updated successfully.");
        setisResponseOpen(true);

        fetchScholarshipDetail();
      }
      setIsLoading(false);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        const newTokens = await refreshToken();
        if (newTokens) {
          handleExtraScholarshipSubmit(); // Retry submission with new token
        } else {
          alert("Session expired. Please login again.");
        }
      } else {
        console.error("Error updating extra scholarship:", error);
        alert("Failed to update extra scholarship. Please try again.");
      }
      setIsLoading(false);
    }
  };

  const fetchApprovalDetails = async () => {
    const tokens = JSON.parse(localStorage.getItem("ERPTokens"));
    try {
      const response = await axios.get(
        `${BE_URL}/extrascholarshipapproval/${roll_no}`,
        {
          headers: { Authorization: `Bearer ${tokens.accessToken}` },
        }
      );
      setApprovalDetails(response.data);
      console.log(response.data);
    } catch (error) {
      setApprovalDetails(null);
      console.error("Failed to fetch approval details:", error);
    }
  };

  const selectDistrict = (val) => {
    setdistrict(val);

    // Only set talukas for Baramati branch
    if (branch !== "Ranchi") {
      const obj = data.find((city) => city.name === val);
      if (obj && obj.tahasil) {
        settalukas(obj.tahasil);
      } else {
        settalukas([]);
      }
    }
  };

  // Handle file change
  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  // Handle file upload
  const handleFileUpload = async () => {
    if (!file) {
      alert("Please select a file first.");
      return;
    }

    const formData = new FormData();
    formData.append("photo", file);
    formData.append("type", "scholarships");

    try {
      const tokens = JSON.parse(localStorage.getItem("ERPTokens")); // Ensure you have tokens stored in localStorage
      const response = await axios.post(
        `${BE_URL}/updateStudentPhoto/${id}/`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${tokens.accessToken}`, // Authorization header
            "Content-Type": "multipart/form-data", // Required for file uploads
          },
        }
      );

      setphoto_url(response.data.photoUrl); // Update photo with new URL
      alert("Photo updated successfully!");
    } catch (error) {
      console.error("Error uploading photo:", error);
      alert("Failed to upload photo. Please try again.");
    }
  };

  const handleBranchChange = (selectedBranch) => {
    setBranch(selectedBranch);

    // Reset district and taluka when branch changes
    setdistrict(null);
    settaluka(null);

    if (selectedBranch === "Ranchi") {
      // Use Bihar and Jharkhand districts for Ranchi branch
      setdistricts(biharJharkhandDistricts);
    } else {
      // Use existing district data from the data import
      let cities = [];
      data.map((city) => cities.push(city.name));
      setdistricts(cities);
    }
  };

  const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    const formattedDate = date.toLocaleDateString(); // e.g., "12/24/2023"
    // const formattedTime = date.toLocaleTimeString(); // e.g., "12:32:35 AM"
    return `${formattedDate}`;
  };

  const handlePrint = () => {
    if (isLoading) return;
    setIsLoading(true);
    console.log("printing");
    const printContents = printDivRef.current.innerHTML;
    const originalContents = document.body.innerHTML;

    document.body.innerHTML = printContents;
    window.print();
    document.body.innerHTML = originalContents;
    setIsLoading(false);
  };

  // Handle download photo
  const handleDownloadPhoto = () => {
    const link = document.createElement("a");
    link.href = photo_url; // Use the photo URL to download
    link.download = `${rollnumber}_${firstname}_${lastname}_photo.jpg`; // File name
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className="asd">
      {permissions?.can_read ? (
        <>
          {isLoading && <Loader />}
          <Modal
            isOpen={isResponseOpen}
            onClose={() => setisResponseOpen(false)}
          >
            <ResponseModal
              type={responseType}
              msg={msg}
              onClick={() => setisResponseOpen(false)}
            />
          </Modal>
          <Modal
            isOpen={isImageModalOpen}
            onClose={() => setIsImageModalOpen(false)}
          >
            <div className="asd-imgChange">
              <input type="file" onChange={handleFileChange} accept="image/*" />
              <br />
              <Button
                type={`primary`}
                text={`Change Photo`}
                onClick={handleFileUpload}
              ></Button>
            </div>
          </Modal>
          {admitted && (
            <div className="asd-success">
              <span className="asd-success-text">
                This student is admitted.{" "}
              </span>
              <span
                className="asd-success-view"
                onClick={() => navigate(`/admin/seniorStudent/${adm_roll_no}`)}
              >
                {" "}
                View &rarr;
              </span>
            </div>
          )}

          <div className="asd-meta">
            <div className="asd-meta-img">
              <img
                src={
                  photo_url
                    ? photo_url
                    : "https://erpresources.s3.ap-south-1.amazonaws.com/profile.png"
                }
                alt="Student Image"
              />
              <div className="img-buttons">
                <button onClick={() => setIsImageModalOpen(true)}>
                  Change
                </button>
                {photo_url && (
                  <button onClick={handleDownloadPhoto}>Download</button>
                )}
              </div>
            </div>

            <div className="asd-meta-details">
              <div className="asd-meta-details-name">{`${firstname} ${middlename} ${lastname}`}</div>
              <div className="asd-meta-details-email">{email}</div>
              <div className="asd-meta-details-whatsapp">
                Whatsapp. {whatsapp}
              </div>
              <div className="asd-meta-details-whatsapp">
                Roll No. {rollnumber}
              </div>
            </div>

            <div className="asd-meta-cta">
              <Button type={`primary`} onClick={handlePrint} text={`Print`} />
              {permissions?.can_delete && (
                <Button
                  type={`primary`}
                  onClick={handleDelete}
                  text={`Delete`}
                />
              )}
              {/* <img src={print} onClick={handlePrint} alt="Print" />

              {permissions?.can_delete && (
                <img src={deleteicon} onClick={handleDelete} alt="Delete" />
              )} */}
            </div>
          </div>

          <div className="asd-header"></div>
          <div ref={printDivRef} className="asd-contents">
            <div className="asd-contents-title">
              Senior Admission Test Form of {rollnumber}
            </div>
            <div className="asd-contents-section">
              <div className="asd-contents-section-title">
                Basic Information
              </div>
              <div className="asd-contents-section-fields">
                <div className="scholarship-contents-section-fields-field">
                  <DropDown
                    invalid={errors.branch}
                    options={branches}
                    label={`Branch`}
                    value={branch}
                    onChange={handleBranchChange}
                  />
                </div>
                <div className="scholarship-contents-section-fields-field">
                  <DropDown
                    options={streams}
                    label={`stream`}
                    value={stream}
                    onChange={setstream}
                  />
                </div>
                <div
                  className={`asd-contents-section-fields-field ${
                    errors.session ? "invalid" : ""
                  }`}
                >
                  <DropDown
                    invalid={errors.session}
                    options={sessionOptions}
                    label={`Session`}
                    value={session}
                    onChange={setSession}
                  />
                </div>
                <div className="scholarship-contents-section-fields-field">
                  <DropDown
                    invalid={errors.reference}
                    options={refOptions}
                    label={`Reference`}
                    value={reference}
                    onChange={setReference}
                  />
                </div>
                <div
                  className={`asd-contents-section-fields-field ${
                    errors.firstname ? "invalid" : ""
                  }`}
                >
                  <TextField
                    placeholder={`First Name`}
                    value={firstname}
                    onChange={setFirstname}
                  />
                </div>
                <div
                  className={`asd-contents-section-fields-field ${
                    errors.middlename ? "invalid" : ""
                  }`}
                >
                  <TextField
                    placeholder={`Middle Name`}
                    value={middlename}
                    onChange={setmiddlename}
                  />
                </div>
                <div
                  className={`asd-contents-section-fields-field ${
                    errors.lastname ? "invalid" : ""
                  }`}
                >
                  <TextField
                    className={errors.lastname ? "input-error" : ""}
                    placeholder={`Last Name`}
                    value={lastname}
                    onChange={setlastname}
                  />
                </div>
                <div className="asd-contents-section-fields-field">
                  <DatePicker
                    invalid={errors.dob}
                    label={`Date of Birth`}
                    value={dob}
                    onChange={setdob}
                  />
                </div>
                <div
                  className={`asd-contents-section-fields-field ${
                    errors.fathername ? "invalid" : ""
                  }`}
                >
                  <TextField
                    placeholder={`Father's Name`}
                    value={fathername}
                    onChange={setfathername}
                  />
                </div>
                <div
                  className={`asd-contents-section-fields-field ${
                    errors.mothername ? "invalid" : ""
                  }`}
                >
                  <TextField
                    placeholder={`Mother's Name`}
                    value={mothername}
                    onChange={setmothername}
                  />
                </div>
                <div
                  className={`asd-contents-section-fields-field ${
                    errors.email ? "invalid" : ""
                  }`}
                >
                  <TextField
                    placeholder={`Email`}
                    value={email}
                    onChange={setemail}
                  />
                </div>
                <div
                  className={`asd-contents-section-fields-field ${
                    errors.whatsapp ? "invalid" : ""
                  }`}
                >
                  <TextField
                    placeholder={`WhatsApp No.`}
                    value={whatsapp}
                    onChange={setwhatsapp}
                  />
                </div>
                <div
                  className={`asd-contents-section-fields-field ${
                    errors.alternateNo ? "invalid" : ""
                  }`}
                >
                  <TextField
                    placeholder={`Alternate No.`}
                    value={alternateNo}
                    onChange={setAlternateNo}
                  />
                </div>
                <div
                  className={`asd-contents-section-fields-field ${
                    errors.aadhar ? "invalid" : ""
                  }`}
                >
                  <TextField
                    placeholder={`Aadhar No.`}
                    value={aadhar}
                    onChange={setaadhar}
                  />
                </div>
                <div className="asd-contents-section-fields-field">
                  <DropDown
                    invalid={errors.gender}
                    options={genders}
                    label={`Gender`}
                    value={gender}
                    onChange={setgender}
                  />
                </div>
                <div className="scholarship-contents-section-fields-field">
                  <DropDown
                    invalid={errors.caste}
                    options={casteOptions}
                    label={`Caste`}
                    value={caste}
                    onChange={setcaste}
                  />
                </div>
                <div className="asd-contents-section-fields-field">
                  <DropDown
                    invalid={errors.bloodgroup}
                    options={bloodgroups}
                    label={`Blood Group`}
                    value={bloodgroup}
                    onChange={setbloodgroup}
                  />
                </div>
                <div className={`asd-contents-section-fields-field`}>
                  <TextField
                    placeholder={`Parent's Occupation`}
                    value={parentOccupation}
                    onChange={setparentOccupation}
                  />
                </div>
                <div className="asd-contents-section-fields-field"></div>
              </div>
            </div>

            <hr className="asd-contents-seperator"></hr>

            {/* Admission Related Information */}
            <div className="asd-contents-section">
              <div className="asd-contents-section-title">
                Admission Related Information
              </div>
              <div className="asd-contents-section-fields">
                <div className="asd-contents-section-fields-field">
                  <DropDown
                    invalid={errors.admclass}
                    options={classOptions}
                    label={`Want Admission for Class`}
                    value={admclass}
                    onChange={setadmclass}
                  />
                </div>
                <div className="asd-contents-section-fields-field">
                  <DropDown
                    invalid={errors.hostel}
                    options={bool}
                    label={`Opting for Hostel`}
                    value={hostel}
                    onChange={sethostel}
                  />
                </div>
                <div className="asd-contents-section-fields-field"></div>
              </div>
            </div>

            <hr className="asd-contents-seperator"></hr>

            {/* Previous Academic Information */}
            <div className="asd-contents-section">
              <div className="asd-contents-section-title">
                Previous Academic Information
              </div>
              <div className="asd-contents-section-fields">
                <div
                  className={`asd-contents-section-fields-field ${
                    errors.school ? "invalid" : ""
                  }`}
                >
                  <TextField
                    placeholder={`Name of School`}
                    value={school}
                    onChange={setschool}
                  />
                </div>
                <div className="asd-contents-section-fields-field">
                  <DropDown
                    invalid={errors.board}
                    options={boards}
                    label={`Board`}
                    value={board}
                    onChange={setboard}
                  />
                </div>
                <div className="asd-contents-section-fields-field">
                  <DropDown
                    invalid={errors.medium}
                    options={mediums}
                    label={`Medium`}
                    value={medium}
                    onChange={setmedium}
                  />
                </div>
                <div
                  className={`asd-contents-section-fields-field ${
                    errors.prevmaths ? "invalid" : ""
                  }`}
                >
                  <TextField
                    placeholder={`Previous Year Maths Score`}
                    value={prevmaths}
                    onChange={setprevmaths}
                  />
                </div>
                <div
                  className={`asd-contents-section-fields-field ${
                    errors.prevscience ? "invalid" : ""
                  }`}
                >
                  <TextField
                    placeholder={`Previous Year Science Score`}
                    value={prevscience}
                    onChange={setprevscience}
                  />
                </div>
                <div className="asd-contents-section-fields-field">
                  <DropDown
                    options={bool}
                    value={otherExams}
                    label={`Other Competitive Exams`}
                    onChange={setOtherExams}
                  />
                </div>
                {otherExams === "Yes" ? (
                  <>
                    <div className="asd-contents-section-fields-field-other">
                      <DropDown
                        options={examOptions}
                        label={`Select Exam`}
                        value={exam1}
                        onChange={setexam1}
                      />
                    </div>
                    <div className="asd-contents-section-fields-field-other">
                      <TextField
                        placeholder={`Exam Marks`}
                        value={exam1Marks}
                        onChange={setexam1Marks}
                      />
                    </div>

                    <div className="asd-contents-section-fields-field-other">
                      <DropDown
                        options={examOptions}
                        label={`Select Exam`}
                        value={exam2}
                        onChange={setexam2}
                      />
                    </div>
                    <div className="asd-contents-section-fields-field-other">
                      <TextField
                        placeholder={`Exam Marks`}
                        value={exam2Marks}
                        onChange={setexam2Marks}
                      />
                    </div>

                    <div className="asd-contents-section-fields-field-other">
                      <DropDown
                        options={examOptions}
                        label={`Select Exam`}
                        value={exam3}
                        onChange={setexam3}
                      />
                    </div>
                    <div className="asd-contents-section-fields-field-other">
                      <TextField
                        placeholder={`Exam Marks`}
                        value={exam3Marks}
                        onChange={setexam3Marks}
                      />
                    </div>
                  </>
                ) : (
                  ""
                )}
              </div>
            </div>

            <hr className="asd-contents-seperator"></hr>

            {/* Address */}
            <div className="asd-contents-section">
              <div className="asd-contents-section-title">Address</div>
              <div className="asd-contents-section-fields">
                <div
                  className={`asd-contents-section-fields-field ${
                    errors.address ? "invalid" : ""
                  }`}
                >
                  <TextField
                    placeholder={`Address`}
                    value={address}
                    onChange={setaddress}
                  />
                </div>
                <div className="asd-contents-section-fields-field">
                  {/* <DropDown
                    invalid={errors.district}
                    options={districts}
                    label={`District`}
                    value={district}
                    onChange={selectDistrict}
                  /> */}

                  <CustomDropDown
                    data={districts.map((city) => ({
                      id: city,
                      name: city,
                    }))}
                    searchable={true}
                    label="Select District"
                    placeholder="Select District"
                    onChange={selectDistrict}
                    value={district}
                  />
                </div>

                {branch !== "Ranchi" ? (
                  <div className="asd-contents-section-fields-field">
                    <DropDown
                      invalid={errors.taluka}
                      options={talukas}
                      label={`Taluka`}
                      value={taluka}
                      onChange={settaluka}
                    />
                  </div>
                ) : null}

                <div
                  className={`asd-contents-section-fields-field ${
                    errors.pin ? "invalid" : ""
                  }`}
                >
                  <TextField
                    placeholder={`Pin`}
                    value={pin}
                    onChange={setpin}
                  />
                </div>
                <div className="asd-contents-section-fields-field"></div>
              </div>
            </div>
            {error && <div className="asd-error">{error}</div>}
          </div>

          {permissions?.can_update && (
            <div className="asd-button">
              <div className="asd-button-left">
                {!admitted && admCreate.can_create && (
                  <Button
                    text={`Give Admission`}
                    onClick={() => {
                      navigate(`/admin/admission-form/${roll_no}`, {
                        state: { is_senior: true, promotion: false }, // or false depending on your condition
                      });
                    }}
                  />
                )}
                <Button text={`Move to Entrance`} onClick={moveToEntrance} />
              </div>
              <div className="asd-button-right">
                <Button text={`Save Changes`} onClick={handleEdit} />
              </div>
            </div>
          )}

          {amount && (
            <>
              <div className="asd-result">Exam and Result</div>
              <div className="asd-exam">
                <div className="asd-exam-card">
                  <div className="asd-exam-card-title">Exam Date</div>
                  <div className="asd-exam-card-value">
                    {formatDate(examDate)}
                  </div>
                </div>
                <div className="asd-exam-card">
                  <div className="asd-exam-card-title">Test ID</div>
                  <div className="asd-exam-card-value">{testID}</div>
                </div>
                <div className="asd-exam-card">
                  <div className="asd-exam-card-title">Percentage</div>
                  <div className="asd-exam-card-value">{percent}</div>
                </div>
                <div className="asd-exam-card">
                  <div className="asd-exam-card-title">Scholarship</div>
                  <div className="asd-exam-card-value">Rs {amount}</div>
                </div>
                <div className="asd-exam-card">
                  <div className="asd-exam-card-title">Part 1</div>
                  <div className="asd-exam-card-value">{part1}</div>
                </div>
                <div className="asd-exam-card">
                  <div className="asd-exam-card-title">Part 2</div>
                  <div className="asd-exam-card-value">{part2}</div>
                </div>
                <div className="asd-exam-card">
                  <div className="asd-exam-card-title">Part 3</div>
                  <div className="asd-exam-card-value">{part3}</div>
                </div>
                <div className="asd-exam-card">
                  <div className="asd-exam-card-title">Total</div>
                  <div className="asd-exam-card-value">{total}</div>
                </div>
              </div>
            </>
          )}

          <div className="asd-addCoun">
            {addsch?.can_read && (
              <>
                {additionalAmount ? (
                  <div className="asd-additional">
                    <div className="asd-additional-header">
                      Additional Scholarship Allocated
                    </div>
                    <div className="asd-additional-amount">
                      Amount - Rs {additionalAmount}
                    </div>
                    <div className="asd-additional-user">
                      Reason - {additionalAmountReason}
                    </div>
                    <div className="asd-additional-user">
                      Given By - {additionalAmountUser}
                    </div>
                  </div>
                ) : (
                  ""
                )}
                <>
                  {addsch?.can_create && (
                    <div className="asd-notadditional">
                      {approvalDetails ? (
                        <ScholarshipApproval
                          roll_no={rollnumber}
                          approvalDetails={approvalDetails}
                          fetchApprovalDetails={fetchApprovalDetails}
                          fetchScholarshipDetail={fetchScholarshipDetail}
                        />
                      ) : (
                        <>
                          <div className="asd-notadditional-header">
                            Additional Scholarship
                          </div>

                          <CustomNumberInput
                            value={newAdditionalAmount}
                            onChange={setNewAdditionalAmount}
                            placeholder="Enter amount"
                            label="Extra Scholarship Amount"
                          />
                          {newAdditionalAmount > 0 && (
                            <div className="asd-notadditional-amtText">
                              {numberToWords.toWords(newAdditionalAmount)}.
                            </div>
                          )}
                          <CustomTextInput
                            placeholder="Reason for Extra Scholarship"
                            value={newAdditionalAmountReason}
                            onChange={(e) =>
                              setNewAdditionalAmountReason(e.target.value)
                            }
                            label="Extra Scholarship Reason"
                          />
                          <Button
                            text="Submit"
                            onClick={handleExtraScholarshipSubmit}
                          />
                        </>
                      )}
                    </div>
                  )}
                </>
              </>
            )}

            {schcomments?.can_read && (
              <div className="asd-comments">
                <div className="asd-comments-heading">Counsellor Comments</div>
                {comments.length ? (
                  ""
                ) : (
                  <div className="asd-comments-none">No Comments yet.</div>
                )}
                {comments.map((comment, index) => (
                  <div key={index} className="asd-comments-card">
                    <div className="asd-comments-card-data">
                      <span className="asd-comments-card-data-user">
                        {comment.user_name}
                      </span>{" "}
                      <span className="asd-comments-card-data-date">
                        {comment.timestamp}
                        {/* {formatDateAndTime(comment.timestamp)} */}
                        {/* {new Date(comment.timestamp).toLocaleDateString()} */}
                      </span>
                    </div>
                    <div className="asd-comments-card-comment">
                      {comment.text}
                    </div>
                  </div>
                ))}
                {schcomments?.can_create && (
                  <div className="asd-comments-add">
                    <textarea
                      value={newComment}
                      onChange={(e) => setNewComment(e.target.value)}
                      placeholder="Add a comment"
                    />
                    <Button text={"Post Comment"} onClick={handleAddComment} />
                  </div>
                )}
              </div>
            )}
          </div>
        </>
      ) : (
        ""
      )}
    </div>
  );
};

export default AdminScholarshipDetail;

const ScholarshipApproval = ({
  roll_no,
  approvalDetails,
  fetchApprovalDetails,
  fetchScholarshipDetail,
}) => {
  // const [approvalDetails, setApprovalDetails] = useState(null);
  const [otps, setOtps] = useState({ otp1: "", otp2: "", otp3: "" });

  const [msg, setmsg] = useState(null);
  const [responseType, setresponseType] = useState(null);
  const [isResponseOpen, setisResponseOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    fetchApprovalDetails();
  }, [roll_no]);

  const handleOTPVerification = async (otp, otp_type, index) => {
    setIsLoading(true);
    const tokens = JSON.parse(localStorage.getItem("ERPTokens"));
    try {
      const response = await axios.post(
        `${BE_URL}/scholarship/verify-otp`,
        {
          roll_no,
          otp,
          otp_type,
        },
        {
          headers: { Authorization: `Bearer ${tokens.accessToken}` },
        }
      );

      if (response.status === 201) {
        // alert("OTP verified successfully");
        window.scrollTo(0, 0);
        setresponseType("Success");
        setmsg("Additional scholarship approved!");
        setisResponseOpen(true);

        fetchScholarshipDetail();
        fetchApprovalDetails();

        // setApprovalDetails(null);
      }

      if (response.status === 200) {
        // alert("OTP verified successfully");

        window.scrollTo(0, 0);
        setresponseType("Success");
        setmsg("OTP verified successfully");
        setisResponseOpen(true);
        fetchApprovalDetails();
        // setApprovalDetails(null);
      } else {
        setOtps((prev) => ({ ...prev, [index]: "" }));
      }
      setIsLoading(false);
    } catch (error) {
      if (error.response.status === 403) {
        window.scrollTo(0, 0);
        setresponseType("Error");
        setmsg("Invalid OTP!");
        setisResponseOpen(true);
      } else {
        window.scrollTo(0, 0);
        setresponseType("Error");
        setmsg("OTP verification failed.");
        setisResponseOpen(true);
      }
      console.error("Error:", error);
      setIsLoading(false);
    }
  };

  const handleRevoke = async () => {
    if (!window.confirm("Are you sure you want to revoke the Approvals?")) {
      return;
    }
    setIsLoading(true);
    const tokens = JSON.parse(localStorage.getItem("ERPTokens"));

    try {
      const response = await axios.post(
        `${BE_URL}/scholarship/revoke-approval`,
        { roll_no },
        { headers: { Authorization: `Bearer ${tokens.accessToken}` } }
      );

      window.scrollTo(0, 0);
      setresponseType("Success");
      setmsg("Scholarship approval has been revoked.");
      setisResponseOpen(true);
      fetchApprovalDetails();
      setIsLoading(false);
    } catch (error) {
      window.scrollTo(0, 0);
      setresponseType("Error");
      setmsg("Failed to revoke scholarship approval.");
      setisResponseOpen(true);
      console.error("Error revoking scholarship:", error);
      setIsLoading(false);
    }
  };

  if (!approvalDetails) return <div>No Pending scholarship approvals.</div>;

  return (
    <div className="scholarship-approval">
      <Modal isOpen={isResponseOpen} onClose={() => setisResponseOpen(false)}>
        <ResponseModal
          type={responseType}
          msg={msg}
          onClick={() => setisResponseOpen(false)}
        />
      </Modal>
      <h1>Additional Scholarship Approval</h1>
      <p>
        <strong>Amount: &#8377; {approvalDetails.amount}</strong>
      </p>
      <p>Reason: {approvalDetails.reason}</p>
      <p>Requested by: {approvalDetails.requested_by}</p>
      {["otp1", "otp2", "otp3"].map((otpKey, idx) => (
        <div key={idx} className="otp-input">
          <label>{`OTP for ${
            approvalDetails["approver" + (idx + 1) + "_email"]
          }:`}</label>
          {approvalDetails[otpKey + "_verified"] ? (
            <>
              <div className="otp-input-verified">Verified</div>
            </>
          ) : (
            <div className="otp-input-box">
              <input
                type="text"
                value={otps[otpKey]}
                onChange={(e) => setOtps({ ...otps, [otpKey]: e.target.value })}
                placeholder="Enter OTP"
              />
              <Button
                text={`Verify`}
                type={"minigreen"}
                onClick={() =>
                  handleOTPVerification(otps[otpKey], "otp" + (idx + 1), otpKey)
                }
              />
            </div>
          )}
        </div>
      ))}

      <div className="scholarship-approval-revoke">
        <Button type={"red"} text={"Revoke"} onClick={handleRevoke} />
      </div>
    </div>
  );
};
