import React, { useState, useEffect } from "react";
import "./AnnouncementsDisplay.scss";
import axios from "axios";

const BE_URL = "https://server.classerp.in";

const AnnouncementsDisplay = () => {
  const [announcements, setAnnouncements] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchAnnouncements = async () => {
      setIsLoading(true);
      try {
        const tokens = JSON.parse(localStorage.getItem("ERPTokens"));
        const response = await axios.get(
          `${BE_URL}/api/general/announcements`,
          {
            headers: { Authorization: `Bearer ${tokens.accessToken}` },
          }
        );

        // Sort announcements by date (newest first)
        const sortedAnnouncements = response.data.sort(
          (a, b) => new Date(b.created_at) - new Date(a.created_at)
        );

        setAnnouncements(sortedAnnouncements);
        setError(null);
      } catch (error) {
        console.error("Failed to fetch announcements:", error);
        setError("Unable to load announcements. Please try again later.");
      } finally {
        setIsLoading(false);
      }
    };

    fetchAnnouncements();

    // Optional: Set up polling to refresh announcements periodically
    const intervalId = setInterval(fetchAnnouncements, 5 * 60 * 1000); // Refresh every 5 minutes

    return () => clearInterval(intervalId); // Cleanup on unmount
  }, []);

  const formatDateAndTime = (timestamp) => {
    const date = new Date(timestamp);

    // Format date in a more readable way
    const formattedDate = date.toLocaleDateString(undefined, {
      year: "numeric",
      month: "short",
      day: "numeric",
    });

    // Format time without seconds for cleaner display
    const formattedTime = date.toLocaleTimeString(undefined, {
      hour: "2-digit",
      minute: "2-digit",
    });

    return `${formattedDate} at ${formattedTime}`;
  };

  const getTimeAgo = (timestamp) => {
    const now = new Date();
    const date = new Date(timestamp);
    const diffInSeconds = Math.floor((now - date) / 1000);

    if (diffInSeconds < 60) {
      return "Just now";
    }

    const diffInMinutes = Math.floor(diffInSeconds / 60);
    if (diffInMinutes < 60) {
      return `${diffInMinutes} ${
        diffInMinutes === 1 ? "minute" : "minutes"
      } ago`;
    }

    const diffInHours = Math.floor(diffInMinutes / 60);
    if (diffInHours < 24) {
      return `${diffInHours} ${diffInHours === 1 ? "hour" : "hours"} ago`;
    }

    const diffInDays = Math.floor(diffInHours / 24);
    if (diffInDays < 7) {
      return `${diffInDays} ${diffInDays === 1 ? "day" : "days"} ago`;
    }

    // For older announcements, return the formatted date
    return formatDateAndTime(timestamp);
  };

  // Determine which CSS class to use based on audience
  const getAnnouncementClass = (announcement) => {
    if (!announcement.audience) return "";

    const audience = announcement.audience.toLowerCase();

    if (audience === "everyone") return "everyone";
    if (audience === "students") return "students";
    if (audience === "staff") return "staff";

    return "";
  };

  // Check if an announcement is recent (less than 24 hours old)
  const isRecentAnnouncement = (timestamp) => {
    const now = new Date();
    const date = new Date(timestamp);
    const diffInHours = Math.floor((now - date) / (1000 * 60 * 60));

    return diffInHours < 24;
  };

  if (isLoading) {
    return (
      <div className="announcements-loading">
        <div className="loading-spinner"></div>
        <p>Loading announcements...</p>
      </div>
    );
  }

  if (error) {
    return (
      <div className="announcements-error">
        <div className="error-icon"></div>
        <p>{error}</p>
        <button onClick={() => window.location.reload()}>Try Again</button>
      </div>
    );
  }

  return (
    <div className="announcements-display">
      {announcements.length > 0 ? (
        announcements.map((announcement) => (
          <div
            key={announcement.id}
            className={`announcement-card ${getAnnouncementClass(
              announcement
            )} ${
              isRecentAnnouncement(announcement.created_at)
                ? "new-announcement"
                : ""
            }`}
          >
            <div className="announcement-header">
              <h2 className="announcement-title">{announcement.subject}</h2>
              <span
                className="announcement-date"
                title={formatDateAndTime(announcement.created_at)}
              >
                {getTimeAgo(announcement.created_at)}
              </span>
            </div>
            <p className="announcement-content">{announcement.body}</p>
            {/* {announcement.audience && (
              <div className="announcement-footer">
                <span className="announcement-audience">
                  For: {announcement.audience}
                </span>
              </div>
            )} */}
          </div>
        ))
      ) : (
        <div className="no-announcements">No announcements available.</div>
      )}
    </div>
  );
};

export default AnnouncementsDisplay;
