import React, { useState, useEffect } from "react";

import "./Incomes.scss";
import { utils, writeFile } from "xlsx";
import HalfList from "../../components/HalfList/HalfList";
import axios from "axios";
import Loader from "../../components/Loader/Loader";
import HalfDetails from "../../components/HalfDetails/HalfDetails";
import Button from "../../components/Button/Button";
import { useSelector } from "react-redux";
import { DateRangePicker, Dropdown } from "rsuite";
import DropDown from "../../components/DropDown/DropDown";
import Modal from "../../components/Modal/Modal";
import ResponseModal from "../../components/ResponseModal/ResponseModal";

import { BsCalculator as CalculatorIcon } from "react-icons/bs";

import {
  FiArrowUp,
  FiFileText,
  FiAlertTriangle,
  FiDownload,
} from "react-icons/fi";

// const BE_URL = "http://localhost:8000";
const BE_URL = "https://server.classerp.in";

const Incomes = () => {
  //RBAC code block
  const allPermissions = useSelector((state) => state.permissions);
  const permissions = allPermissions
    .find((p) => p.name === "Finance")
    ?.dropdowns.find((dd) => dd.name === "Incomes")?.permissions;
  //RBAC Code block ends.

  const [selectedItem, setSelectedItem] = useState(null);
  const [date, setDate] = useState("");
  const [category, setCategory] = useState("");
  const [amount, setAmount] = useState("");
  const [description, setDescription] = useState("");
  const [paymentMethod, setPaymentMethod] = useState("");
  const [totalExpense, settotalExpense] = useState("");
  const [totalTax, settotalTax] = useState(0);
  const [receiptUrl, setReceiptUrl] = useState("");
  const [incomes, setincomes] = useState([]);
  const [accounts, setaccounts] = useState([]);
  const [allincomes, setallincomes] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [categoryFilter, setcategoryFilter] = useState("All");
  const [branchFilter, setbranchFilter] = useState("All");
  const [securityFilter, setsecurityFilter] = useState("Yes");
  const [departmentFilter, setDepartmentFilter] = useState("All");
  const [accountFilter, setAccountFilter] = useState("All");

  const [msg, setmsg] = useState(null);
  const [responseType, setresponseType] = useState(null);
  const [isResponseOpen, setisResponseOpen] = useState(false);
  const [isAddModalOpen, setisAddModalOpen] = useState(false);

  // Initialize the date range state
  const [dateRange, setDateRange] = useState(() => {
    const today = new Date();
    const lastMonth = new Date(new Date().setMonth(today.getMonth() - 1));
    return [lastMonth, today];
  });

  const handleDateRangeChange = (range) => {
    setDateRange(range);
  };

  useEffect(() => {
    setSelectedItem(null);

    if (dateRange[0] && dateRange[1]) {
      fetchincomes(dateRange[0], dateRange[1]);
    }
  }, [dateRange]);

  useEffect(() => {
    if (selectedItem) {
      setDate(selectedItem.date);
      setCategory(selectedItem.category);
      setAmount(selectedItem.amount);
      setDescription(selectedItem.description);
      setPaymentMethod(selectedItem.paymentMethod);
      setReceiptUrl(selectedItem.receiptUrl);
    }
  }, [selectedItem]);

  useEffect(() => {
    setSelectedItem(null);
    applyFilters();
  }, [
    categoryFilter,
    departmentFilter,
    accountFilter,
    securityFilter,
    branchFilter,
  ]);

  const applyFilters = () => {
    let filteredincomes = [...allincomes];

    // Apply category filter
    if (categoryFilter && categoryFilter !== "All") {
      filteredincomes = filteredincomes.filter(
        (item) => item.payment_method === categoryFilter
      );
    }
    // Apply branch filter
    if (branchFilter && branchFilter !== "All") {
      filteredincomes = filteredincomes.filter(
        (item) => item.branch === branchFilter
      );
    }

    // Apply account filter
    if (accountFilter && accountFilter !== "All") {
      filteredincomes = filteredincomes.filter(
        (item) => item.account === accountFilter
      );
    }

    // Apply security filter
    if (securityFilter === "No") {
      filteredincomes = filteredincomes.filter(
        (item) => !item.installment_name.toLowerCase().includes("security")
      );
    }
    // No need for an "else" condition since if securityFilter is "Yes", we show all items

    // Calculate the total incomes after filtering
    const totalincomes = filteredincomes.reduce(
      (acc, expense) => acc + Number(expense.amount),
      0
    );
    settotalExpense(totalincomes);
    setincomes(filteredincomes); // Update the displayed items based on filters
  };

  const refreshToken = async () => {
    try {
      const tokens = JSON.parse(localStorage.getItem("ERPTokens"));
      const response = await axios.post(
        "https://server.classerp.in/refreshToken",
        { token: tokens.refreshToken }
      );

      if (response.data) {
        localStorage.setItem("ERPTokens", JSON.stringify(response.data));
        return response.data.accessToken;
      }
    } catch (error) {
      console.error("Error refreshing token:", error);
      alert("Session expired. Please login again.");
      // Navigate to login page if needed
      return null;
    }
  };

  const fetchincomes = async (startDate, endDate) => {
    setIsLoading(true);
    try {
      const tokens = JSON.parse(localStorage.getItem("ERPTokens"));
      let url = `${BE_URL}/api/incomes?startDate=${startDate}&endDate=${endDate}`;
      const response = await axios.get(url, {
        headers: { Authorization: `Bearer ${tokens.accessToken}` },
      });

      // Process the data to add tax information for KECPL expenses
      let counter = 0;
      const processedData = response.data.map((expense) => {
        console.log(expense.account);

        if (expense.account === "KCEPL") {
          counter += 1;
          // Original amount
          const originalAmount = Number(expense.amount);

          // Calculate taxable value (amount excluding tax)
          const taxableValue = Number((originalAmount / 1.18).toFixed(2));

          // Calculate tax amounts (9% each for CGST and SGST on the taxable value)
          const cgst = Number((taxableValue * 0.09).toFixed(2));
          const sgst = Number((taxableValue * 0.09).toFixed(2));
          const tax = Number((cgst + sgst).toFixed(2));

          // Reduced amount is the taxable value
          const reducedAmount = taxableValue;

          // Return modified expense with tax fields and reduced amount
          return {
            ...expense,
            originalAmount: originalAmount.toFixed(2),
            taxableValue: taxableValue.toFixed(2),
            cgst: cgst.toFixed(2),
            sgst: sgst.toFixed(2),
            tax: tax,
            amount: reducedAmount.toFixed(2),
          };
        }
        // Return unmodified expense for non-KECPL accounts
        return {
          ...expense,
          // amountDisplay: Number(expense.amount).toFixed(2),
        };
      });
      console.log("Total KCEPL accounts ", counter);

      // Update state with processed data
      setincomes(processedData);
      setallincomes(processedData);

      // Calculate totals
      const totalAmount = processedData.reduce(
        (acc, expense) => acc + Number(expense.amount),
        0
      );

      const totalTax = processedData.reduce(
        (acc, expense) => acc + (expense.tax || 0),
        0
      );

      // Update state with totals
      settotalExpense(totalAmount);
      settotalTax(totalTax);

      // Add display versions with 2 decimal places
      // settotalExpenseDisplay(totalAmount.toFixed(2));
      // settotalTaxDisplay(totalTax.toFixed(2));
    } catch (error) {
      console.error("Error fetching incomes:", error);
      setError("Failed to fetch incomes.");
    } finally {
      setIsLoading(false);
    }
  };

  const fetchcategories = async () => {
    setIsLoading(true);
    try {
      const tokens = JSON.parse(localStorage.getItem("ERPTokens")); // Ensure you have tokens stored in localStorage
      const response = await axios.get(
        `${BE_URL}/api/general/categories/Accounts`,
        {
          headers: {
            Authorization: `Bearer ${tokens.accessToken}`,
          },
        }
      );
      setaccounts(response.data);
    } catch (error) {
      console.error("Error fetching categories:", error);
      // Handle specific errors (e.g., unauthorized, server issues)
      if (error.response && error.response.status === 401) {
        // Handle unauthorized error, e.g., redirect to login or refresh token
        alert("Session expired. Please login again.");
      } else {
        alert("Failed to fetch categories. Please try again later.");
      }
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchcategories();
  }, []);

  // const handleAdd = async (formData) => {
  //   setIsLoading(true);
  //   setisAddModalOpen(true);
  //   try {
  //     const tokens = JSON.parse(localStorage.getItem("ERPTokens"));
  //     await axios.post(`${BE_URL}/api/incomes`, formData, {
  //       headers: {
  //         Authorization: `Bearer ${tokens.accessToken}`,
  //         "Content-Type": "multipart/form-data", // Important for sending files
  //       },
  //     });
  //     setresponseType("Success");
  //     setmsg("Added Successfully");
  //     setisResponseOpen(true);
  //     fetchincomes(dateRange[0], dateRange[1]);
  //   } catch (error) {
  //     setresponseType("Error");
  //     setmsg("Failed to Add Expense");
  //     setisResponseOpen(true);
  //   } finally {
  //     setIsLoading(false);
  //     setisAddModalOpen(false);
  //   }
  // };

  // const handleDelete = async (expenseId) => {
  //   setIsLoading(true);
  //   try {
  //     const tokens = JSON.parse(localStorage.getItem("ERPTokens"));
  //     await axios.delete(`${BE_URL}/api/incomes/${expenseId}`, {
  //       headers: { Authorization: `Bearer ${tokens.accessToken}` },
  //     });

  //     setSelectedItem(null);

  //     setresponseType("Success");
  //     setmsg("Deleted Successfully");
  //     setisResponseOpen(true);

  //     fetchincomes(); // Refresh list after deleting
  //   } catch (error) {
  //     setresponseType("Error");
  //     setmsg("Failed to delete. Please try again later.");
  //     setisResponseOpen(true);
  //   } finally {
  //     setIsLoading(false);
  //   }
  // };

  const downloadExcel = () => {
    setIsLoading(true);
    const ws = utils.json_to_sheet(incomes);
    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws, "incomes");

    // Generate Excel file and download
    writeFile(wb, "Incomes.xlsx");
    setIsLoading(false);
  };

  return (
    <div className="income-dashboard">
      {isLoading && <Loader />}
      {permissions?.can_read ? (
        <>
          <Modal
            isOpen={isResponseOpen}
            onClose={() => setisResponseOpen(false)}
          >
            <ResponseModal
              type={responseType}
              msg={msg}
              onClick={() => setisResponseOpen(false)}
            />
          </Modal>

          <div className="dashboard-header">
            <h1 className="dashboard-title">Incomes Management</h1>

            <div className="dashboard-metrics-container">
              {/* Compact metrics displayed in header */}
              <div className="compact-metric income">
                <div className="metric-label">
                  <FiArrowUp className="metric-icon" />
                  <span>Total Income</span>
                </div>
                <div className="metric-value">
                  ₹{" "}
                  {totalExpense.toLocaleString("en-IN", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </div>
              </div>

              <div className="compact-metric tax">
                <div className="metric-label">
                  <CalculatorIcon className="metric-icon" />
                  <span>Total Tax</span>
                </div>
                <div className="metric-value">
                  ₹{" "}
                  {totalTax.toLocaleString("en-IN", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </div>
              </div>

              <button className="export-button" onClick={downloadExcel}>
                <FiDownload className="button-icon" />
                <span>EXPORT</span>
              </button>
              {/* <button className="export-button" onClick={downloadExcel}>
                <span>EXPORT TO EXCEL</span>
              </button> */}
            </div>
          </div>

          <div className="filters-container">
            <div className="filter-group">
              <label className="filter-label">Date Range</label>
              <DateRangePicker
                format="yyyy-MM-dd"
                onChange={handleDateRangeChange}
                cleanable={false}
                value={dateRange}
                className="date-picker"
              />
            </div>

            <div className="filter-group">
              <label className="filter-label">Payment Method</label>
              <DropDown
                options={[
                  "All",
                  "Razorpay",
                  "Cash",
                  "Bank Transfer",
                  "Demand Draft",
                ]}
                value={categoryFilter}
                onChange={(value) => setcategoryFilter(value)}
                className="filter-dropdown"
              />
            </div>
            <div className="filter-group">
              <label className="filter-label">Branch</label>
              <DropDown
                options={["All", "Baramati", "Ranchi", "Nagar", "Pune"]}
                value={branchFilter}
                onChange={(value) => setbranchFilter(value)}
                className="filter-dropdown"
              />
            </div>
            <div className="filter-group">
              <label className="filter-label">Security Included?</label>
              <DropDown
                options={["Yes", "No"]}
                value={securityFilter}
                onChange={(value) => setsecurityFilter(value)}
                className="filter-dropdown"
              />
            </div>

            <div className="filter-group">
              <label className="filter-label">Account</label>
              <DropDown
                options={["All", ...accounts.map((item) => item.name)]}
                value={accountFilter}
                onChange={(value) => setAccountFilter(value)}
                className="filter-dropdown"
              />
            </div>
          </div>

          <div className="content-container">
            <div className="income-list-container">
              {/* <div className="section-header">
                <h2 className="section-title">INCOME TRANSACTIONS</h2>
                <div className="search-container">
                  <FiSearch className="search-icon" />
                  <input
                    type="text"
                    placeholder="Search..."
                    className="search-input"
                  />
                </div>
              </div> */}
              <div className="income-list">
                <HalfList
                  data={incomes}
                  fields={["name", "order_id", "amount"]}
                  setSelectedItem={setSelectedItem}
                  can_create={false}
                />
                {error && <p className="error-message">{error}</p>}
              </div>
            </div>

            <div className="income-details-container">
              {/* <h2 className="section-title">DETAILS</h2> */}
              {selectedItem ? (
                <div className="details-card">
                  <HalfDetails
                    id={selectedItem?.id}
                    name={selectedItem?.name}
                    can_delete={permissions?.can_delete}
                    can_update={false}
                    children={
                      <DetailsChild
                        selectedItem={selectedItem}
                        setSelectedItem={setSelectedItem}
                      />
                    }
                  />
                </div>
              ) : (
                <div className="no-selection-message">
                  <div className="empty-state-icon">
                    <FiFileText />
                  </div>
                  <p>Select a transaction to view details</p>
                </div>
              )}
            </div>
          </div>
        </>
      ) : (
        <div className="permission-denied">
          <FiAlertTriangle />
          <p>You don't have permission to view this content</p>
        </div>
      )}
    </div>
  );

  // return (
  //   <div className="income-dashboard">
  //     {isLoading && <Loader />}
  //     {permissions?.can_read ? (
  //       <>
  //         <Modal
  //           isOpen={isResponseOpen}
  //           onClose={() => setisResponseOpen(false)}
  //         >
  //           <ResponseModal
  //             type={responseType}
  //             msg={msg}
  //             onClick={() => setisResponseOpen(false)}
  //           />
  //         </Modal>

  //         <div className="dashboard-header">
  //           <h1 className="dashboard-title">Income Management</h1>

  //           <div className="dashboard-actions">
  //             <Button
  //               type="primary"
  //               icon={<DownloadIcon />}
  //               text="Export to Excel"
  //               onClick={downloadExcel}
  //             />
  //           </div>
  //         </div>

  //         <div className="filters-container">
  //           <div className="filter-group">
  //             <label className="filter-label">Date Range</label>
  //             <DateRangePicker
  //               format="yyyy-MM-dd"
  //               onChange={handleDateRangeChange}
  //               cleanable={false}
  //               value={dateRange}
  //               className="date-picker"
  //             />
  //           </div>

  //           <div className="filter-group">
  //             <label className="filter-label">Payment Method</label>
  //             <DropDown
  //               options={[
  //                 "All",
  //                 "Razorpay",
  //                 "Cash",
  //                 "Bank Transfer",
  //                 "Demand Draft",
  //               ]}
  //               value={categoryFilter}
  //               onChange={(value) => setcategoryFilter(value)}
  //               className="filter-dropdown"
  //             />
  //           </div>

  //           <div className="filter-group">
  //             <label className="filter-label">Account</label>
  //             <DropDown
  //               options={["All", ...accounts.map((item) => item.name)]}
  //               value={accountFilter}
  //               onChange={(value) => setAccountFilter(value)}
  //               className="filter-dropdown"
  //             />
  //           </div>
  //         </div>

  //         <div className="metrics-container">
  //           <div className="metric-card income">
  //             <div className="metric-icon">
  //               <ArrowUpIcon />
  //             </div>
  //             <div className="metric-content">
  //               <h3 className="metric-title">Total Income</h3>
  //               <div className="metric-value">
  //                 ₹ {totalExpense.toLocaleString()}
  //               </div>
  //             </div>
  //           </div>

  //           <div className="metric-card tax">
  //             <div className="metric-icon">
  //               <CalculatorIcon />
  //             </div>
  //             <div className="metric-content">
  //               <h3 className="metric-title">Total Tax</h3>
  //               <div className="metric-value">
  //                 ₹ {totalTax.toLocaleString()}
  //               </div>
  //             </div>
  //           </div>
  //         </div>

  //         <div className="content-container">
  //           <div className="income-list-container">
  //             <h2 className="section-title">Income Transactions</h2>
  //             <div className="income-list">
  //               <HalfList
  //                 data={incomes}
  //                 fields={["name", "order_id", "amount"]}
  //                 setSelectedItem={setSelectedItem}
  //                 can_create={false}
  //               />
  //               {error && <p className="error-message">{error}</p>}
  //             </div>
  //           </div>

  //           <div className="income-details-container">
  //             <h2 className="section-title">Details</h2>
  //             {selectedItem ? (
  //               <div className="details-card">
  //                 <HalfDetails
  //                   id={selectedItem?.id}
  //                   name={selectedItem?.name}
  //                   can_delete={permissions?.can_delete}
  //                   can_update={false}
  //                   children={
  //                     <DetailsChild
  //                       selectedItem={selectedItem}
  //                       setSelectedItem={setSelectedItem}
  //                     />
  //                   }
  //                 />
  //               </div>
  //             ) : (
  //               <div className="no-selection-message">
  //                 <div className="empty-state-icon">
  //                   <DocumentIcon />
  //                 </div>
  //                 <p>Select a transaction to view details</p>
  //               </div>
  //             )}
  //           </div>
  //         </div>
  //       </>
  //     ) : (
  //       <div className="permission-denied">
  //         <AlertIcon />
  //         <p>You don't have permission to view this content</p>
  //       </div>
  //     )}
  //   </div>
  // );
  // return (
  //   <div className="halfpage">
  //     {isLoading && <Loader />}
  //     {permissions?.can_read ? (
  //       <>
  //         <Modal
  //           isOpen={isResponseOpen}
  //           onClose={() => setisResponseOpen(false)}
  //         >
  //           <ResponseModal
  //             type={responseType}
  //             msg={msg}
  //             onClick={() => setisResponseOpen(false)}
  //           />
  //         </Modal>
  //         <div className="halfpage-header">
  //           <div className="halfpage-header-name">Incomes</div>

  //           <div className="halfpage-header-filters">
  //             <div className="buttton">
  //               <div className="space">&nbsp; </div>
  //               <Button
  //                 type={"curved"}
  //                 text={"Excel"}
  //                 onClick={downloadExcel}
  //               />
  //             </div>
  //             <div className="halfpage-header-filters-filter">
  //               <div className="halfpage-header-filters-label">Date Range</div>
  //               <DateRangePicker
  //                 format="yyyy-MM-dd"
  //                 onChange={handleDateRangeChange}
  //                 cleanable={false}
  //                 value={dateRange}
  //               />
  //             </div>
  //             <div className="halfpage-header-filters-filter">
  //               <div className="halfpage-header-filters-label">
  //                 Payment Method
  //               </div>
  //               <DropDown
  //                 options={[
  //                   "All",
  //                   "Razorpay",
  //                   "Cash",
  //                   "Bank Transfer",
  //                   "Demand Draft",
  //                 ]}
  //                 value={categoryFilter}
  //                 onChange={(value) => setcategoryFilter(value)}
  //               />
  //             </div>
  //             <div className="halfpage-header-filters-filter">
  //               <div className="halfpage-header-filters-label">Account</div>
  //               <DropDown
  //                 options={["All", ...accounts.map((item) => item.name)]}
  //                 value={accountFilter}
  //                 onChange={(value) => setAccountFilter(value)}
  //               />
  //             </div>
  //           </div>
  //         </div>
  //         <div className="halfpage-subheader">
  //           <div className="halfpage-header-card">
  //             <div className="halfpage-header-card-title">Total Income </div>
  //             <div className="halfpage-header-card-number">
  //               <span>&#8377; {totalExpense}</span>
  //             </div>
  //           </div>
  //           <div className="halfpage-header-card">
  //             <div className="halfpage-header-card-title">Total Tax </div>
  //             <div className="halfpage-header-card-number">
  //               <span>&#8377; {totalTax}</span>
  //             </div>
  //           </div>
  //         </div>
  //         <div className="halfpage-contents">
  //           <div className="halfpage-contents-list">
  //             <HalfList
  //               data={incomes}
  //               fields={["name", "order_id", "amount"]}
  //               setSelectedItem={setSelectedItem}
  //               can_create={false}
  //             />
  //             {error && <p className="error">{error}</p>}
  //           </div>
  //           <div className="halfpage-contents-details">
  //             {selectedItem ? (
  //               <div>
  //                 <HalfDetails
  //                   id={selectedItem?.id}
  //                   name={selectedItem?.name}
  //                   can_delete={permissions?.can_delete}
  //                   can_update={false}
  //                   children={
  //                     <DetailsChild
  //                       selectedItem={selectedItem}
  //                       setSelectedItem={setSelectedItem}
  //                     />
  //                   } // Define this component for showing expense details and editing
  //                 />
  //               </div>
  //             ) : (
  //               <div className="halfpage-contents-details-noselection">
  //                 Select an item to see details
  //               </div>
  //             )}
  //           </div>
  //         </div>
  //       </>
  //     ) : (
  //       ""
  //     )}
  //   </div>
  // );
};

export default Incomes;

const DetailsChild = ({ selectedItem, setSelectedItem }) => {
  // const [file, setFile] = useState(null);
  // const [uploading, setUploading] = useState(false);
  // const [message, setMessage] = useState("");

  const formatDateAndTime = (timestamp) => {
    const date = new Date(timestamp);
    const formattedDate = date.toLocaleDateString(); // e.g., "12/24/2023"
    const formattedTime = date.toLocaleTimeString(); // e.g., "12:32:35 AM"
    return `${formattedDate} ${formattedTime}`;
  };

  return (
    <div className="dc">
      <div className="dc-text">
        <div className="dc-text-label">Name</div>
        <div className="dc-text-data">{selectedItem.name}</div>
      </div>
      <div className="dc-text">
        <div className="dc-text-label">Whatsapp</div>
        <div className="dc-text-data">{selectedItem.whatsapp}</div>
      </div>

      <div className="dc-text">
        <div className="dc-text-label">Email</div>
        <div classEmail="dc-text-data">{selectedItem.email}</div>
      </div>
      <div className="dc-text">
        <div className="dc-text-label">Roll No.</div>
        <div classEmail="dc-text-data">{selectedItem.roll_no}</div>
      </div>
      <div className="dc-text">
        <div className="dc-text-label">Account</div>
        <div className="dc-text-data">{selectedItem.account}</div>
      </div>

      {selectedItem.account === "KCEPL" ? (
        <>
          <div className="dc-text">
            <div className="dc-text-label">Original Amount</div>
            <div className="dc-text-data">
              <strong> &#8377; {selectedItem.originalAmount} </strong>
            </div>
          </div>
          <div className="dc-text">
            <div className="dc-text-label">CGST</div>
            <div className="dc-text-data">&#8377; {selectedItem.cgst}</div>
          </div>
          <div className="dc-text">
            <div className="dc-text-label">SGST</div>
            <div className="dc-text-data">&#8377; {selectedItem.sgst}</div>
          </div>
          <div className="dc-text">
            <div className="dc-text-label">Total Tax</div>
            <div className="dc-text-data"> &#8377; {selectedItem.tax}</div>
          </div>
          <div className="dc-text">
            <div className="dc-text-label">Reduced Amount</div>
            <div className="dc-text-data">
              <strong> &#8377; {selectedItem.amount} </strong>
            </div>
          </div>
        </>
      ) : (
        <div className="dc-text">
          <div className="dc-text-label">Amount</div>
          <div className="dc-text-data">
            <strong> &#8377; {selectedItem.amount} </strong>
          </div>
        </div>
      )}

      <div className="dc-text">
        <div className="dc-text-label">Installment No</div>
        <div className="dc-text-data"> {selectedItem.installment_number}</div>
      </div>
      <div className="dc-text">
        <div className="dc-text-label">Installment Name</div>
        <div className="dc-text-data">{selectedItem.installment_name}</div>
      </div>

      <div className="dc-text">
        <div className="dc-text-label">Order ID</div>
        <div className="dc-text-data">{selectedItem.order_id}</div>
      </div>
      <div className="dc-text">
        <div className="dc-text-label">Payment Id</div>
        <div className="dc-text-data">{selectedItem.payment_id}</div>
      </div>
      <div className="dc-text">
        <div className="dc-text-label">Pyament Method</div>
        <div className="dc-text-data">{selectedItem.payment_method}</div>
      </div>
      <div className="dc-text">
        <div className="dc-text-label">Payment Date & Time</div>
        <div className="dc-text-data">
          {formatDateAndTime(selectedItem.created_at)}
        </div>
      </div>

      {selectedItem.year && <div className="dc-text"></div>}
      {selectedItem.leaves && (
        <div className="dc-text">
          <div className="dc-text-label">Order Id / Transaction No</div>
          <div className="dc-text-data">&#8377; {selectedItem.order_id}</div>
        </div>
      )}

      <div className="dc-text">
        <div className="dc-text-label">Payment Accepted By</div>
        <div className="dc-text-data">{selectedItem.processor_name}</div>
      </div>
      {/* <div className="dc-text">
        <div className="dc-text-label">Payment Method</div>
        <div className="dc-text-data">{selectedItem.payment_method}</div>
      </div> */}

      {/* <div className="dc-text">
        <div className="dc-text-label">Created At</div>
        <div className="dc-text-data">
          {formatDateAndTime(selectedItem.created_at)}
        </div>
      </div> */}
      {selectedItem.receipt_url ? (
        <div className="dc-text">
          <div className="dc-text-label">Receipt</div>
          <div className="dc-text-data">
            <a target="_blank" href={selectedItem.receipt_url}>
              {selectedItem.receipt_no}
            </a>
          </div>
        </div>
      ) : null}
    </div>
  );
};
