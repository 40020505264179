import React, { useEffect, useState } from "react";
import "./HalfList.scss";
import Button from "../Button/Button";
import Modal from "../Modal/Modal";

const HalfList = ({
  data,
  fields,
  setSelectedItem,
  isAddModalOpen,
  elem,
  can_create,
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [isAddOpen, setIsAddOpen] = useState(false);
  const [selectedRowIndex, setSelectedRowIndex] = useState(null);
  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: "ascending",
  });

  useEffect(() => {
    if (!isAddModalOpen) {
      setIsAddOpen(false);
    }
  }, [isAddModalOpen]);

  // Handle search input changes
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  // Clear search input
  const clearSearch = () => {
    setSearchTerm("");
  };

  // Handle sorting when clicking on column headers
  const requestSort = (key) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  };

  // Format field names for display
  const formatFieldName = (field) => {
    return field
      .replace(/_/g, " ")
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  // Sort and filter data
  const sortedAndFilteredData = React.useMemo(() => {
    let filteredItems = searchTerm
      ? data.filter((item) =>
          fields.some(
            (field) =>
              item[field] &&
              item[field]
                .toString()
                .toLowerCase()
                .includes(searchTerm.toLowerCase())
          )
        )
      : data;

    if (sortConfig.key) {
      filteredItems.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === "ascending" ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === "ascending" ? 1 : -1;
        }
        return 0;
      });
    }

    return filteredItems;
  }, [data, fields, searchTerm, sortConfig]);

  // Handle row selection
  const handleRowClick = (item, index) => {
    setSelectedItem(item);
    setSelectedRowIndex(index);
  };

  return (
    <div className="half-list">
      <Modal isOpen={isAddOpen} onClose={() => setIsAddOpen(false)}>
        {elem}
      </Modal>

      <div className="half-list-header">
        <div className="search-container">
          <div className="search-input-wrapper">
            <input
              type="text"
              placeholder="Search..."
              value={searchTerm}
              onChange={handleSearchChange}
              className="search-input"
            />
            {searchTerm && (
              <button className="clear-search" onClick={clearSearch}>
                ×
              </button>
            )}
            <span className="search-icon"></span>
          </div>
        </div>

        {can_create && (
          <div className="add-button-container">
            <Button
              type="curvedSmall"
              text="Add New"
              onClick={() => {
                window.scrollTo(0, 0);
                setIsAddOpen(true);
              }}
            />
          </div>
        )}
      </div>

      <div className="list-container">
        {sortedAndFilteredData.length > 0 ? (
          <table className="data-table">
            <thead>
              <tr>
                {fields.map((field) => (
                  <th
                    key={field}
                    onClick={() => requestSort(field)}
                    className={
                      sortConfig.key === field
                        ? `sorted-${sortConfig.direction}`
                        : ""
                    }
                  >
                    {formatFieldName(field)}
                    {sortConfig.key === field && (
                      <span className="sort-indicator">
                        {sortConfig.direction === "ascending" ? " ↑" : " ↓"}
                      </span>
                    )}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {sortedAndFilteredData.map((item, index) => (
                <tr
                  key={item.id || index}
                  onClick={() => handleRowClick(item, index)}
                  className={selectedRowIndex === index ? "selected-row" : ""}
                >
                  {fields.map((field) => (
                    <td key={field}>
                      {item[field] !== undefined && item[field] !== null
                        ? item[field].toString()
                        : "-"}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <div className="no-data">
            <div className="no-data-icon"></div>
            <p>No records found</p>
            {searchTerm && (
              <button className="reset-search" onClick={clearSearch}>
                Clear search
              </button>
            )}
          </div>
        )}
      </div>

      <div className="half-list-footer">
        <span className="records-count">
          {sortedAndFilteredData.length}{" "}
          {sortedAndFilteredData.length === 1 ? "record" : "records"} found
        </span>
        {searchTerm && (
          <span className="search-info">Filtered by: "{searchTerm}"</span>
        )}
      </div>
    </div>
  );
};

export default HalfList;

// import "./HalfList.scss";

// import React, { useEffect, useState } from "react";
// import "./HalfList.scss";
// import Button from "../Button/Button";
// import Modal from "../Modal/Modal";

// const HalfList = ({
//   data,
//   fields,
//   setSelectedItem,
//   isAddModalOpen,
//   elem,
//   can_create,
// }) => {
//   const [searchTerm, setSearchTerm] = useState("");
//   const [isAddOpen, setIsAddOpen] = useState(false);

//   useEffect(() => {
//     if (!isAddModalOpen) {
//       setIsAddOpen(false);
//     }

//     console.log("Effectiog");
//   }, [isAddModalOpen]);

//   const handleSearchChange = (e) => {
//     setSearchTerm(e.target.value);
//   };

//   const filteredData = searchTerm
//     ? data.filter((item) =>
//         fields.some(
//           (field) =>
//             item[field] &&
//             item[field]
//               .toString()
//               .toLowerCase()
//               .includes(searchTerm.toLowerCase())
//         )
//       )
//     : data;

//   return (
//     <div className="halflist">
//       <Modal isOpen={isAddOpen} onClose={() => setIsAddOpen(false)}>
//         {elem}
//       </Modal>

//       <div className="halflist-header">
//         {/* <div className="halflist-header-count">
//           {filteredData.length} Records.
//         </div> */}
//         <div className="halflist-header-search">
//           <input
//             type="text"
//             placeholder="Search..."
//             value={searchTerm}
//             onChange={handleSearchChange}
//           />
//         </div>

//         {can_create && (
//           <div className="halflist-header-add">
//             <Button
//               type={`curvedSmall`}
//               text={`Add`}
//               onClick={() => {
//                 window.scrollTo(0, 0);
//                 setIsAddOpen(true);
//               }}
//             />
//           </div>
//         )}
//       </div>

//       <div className="list-section">
//         <table>
//           <thead>
//             <tr className="list-section-rowHead">
//               {fields.map((field) => (
//                 <th key={field}>{field.replace(/_/g, " ").toUpperCase()}</th>
//               ))}
//             </tr>
//           </thead>
//           <tbody>
//             {filteredData.map((item) => (
//               <tr key={item.id} onClick={() => setSelectedItem(item)}>
//                 {fields.map((field) => (
//                   <td key={field}>{item[field]}</td>
//                 ))}
//               </tr>
//             ))}
//           </tbody>
//         </table>
//       </div>
//       <div className="halflist-footer">
//         {filteredData.length} records found.
//       </div>
//     </div>
//   );
// };

// export default HalfList;
